import { Delete, Grid4x4, ListAlt } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProduct, service } from "api/product";
import { getResponseExceptionMessage } from "api/apiSettings";
import { toast } from "react-toastify";
import { formatAmount } from "helpers/tools";
import { produce } from "immer";
import linq from "linq";
import { IMerchProduct } from "api/merch";
import MuiReactTable from "components/MuiReactTable";
import useHelper from "hooks/useHelper";

const ProductsSelector = ({
  onChange = undefined,
  productList = undefined,
  selectedProductList = [],
  useRetailPrice = false,
  limitedProducts = false,
  disabled = false,
}: {
  onChange?: (items: IMerchProduct[]) => any;
  productList?: IProduct[];
  selectedProductList?: IMerchProduct[];
  useRetailPrice?: boolean;
  limitedProducts?: boolean;
  disabled?: boolean;
}) => {
  const maxNumberOfProducts = 10;
  const { t } = useTranslation(["common"]);
  const { getProductTotal, getProductTotalWeight, getTotal } = useHelper();
  let [products, setProducts] = useState<IProduct[] | undefined>(productList);
  let [selectedProducts, setSelectedProducts] = useState<IMerchProduct[]>(
    selectedProductList || []
  );
  let [mode, setMode] = useState<"grid" | "list">("list");

  useEffect(() => setProducts(productList), [productList]);

  // initialize products if productList param is empty or not provided
  const isInitialized = useRef<boolean>(false);
  useEffect(() => {
    if (isInitialized.current === false && !productList) {
      isInitialized.current = true;
      (async function () {
        const apiResult = await toast.promise(
          service.getAll({ isActive: true }),
          {
            pending: {
              render() {
                return t("common|Loading");
              },
            },
            // success: t("common|loading.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          }
        );
        if (apiResult?.success && apiResult.result) {
          setProducts(apiResult.result.items);
        }
      })();
    }
  }, [productList, t]);

  const qtySelectItems = useMemo(() => {
    let items = [];
    for (let i = 1; i < 100; i++) {
      items.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return items;
  }, []);

  const handleChangeQuantity = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      merchProduct: IMerchProduct
    ) => {
      setSelectedProducts(
        produce((draft: IMerchProduct[]) => {
          const valueParsed = parseInt(e.target.value);
          let otherQuantity = linq
            .from(draft)
            .where((o) => o.product.id != merchProduct.product.id)
            .sum((o) => o.quantity);
          let totalQuantity = linq.from(draft).sum((o) => o.quantity);

          let selectedProduct = linq
            .from(draft)
            .firstOrDefault((o) => o.product.id === merchProduct.product.id);
          if (selectedProduct && isNaN(valueParsed) === false) {
            if (limitedProducts && valueParsed > maxNumberOfProducts) {
              toast.warn(
                "Vous ne pouvez ajouter que " +
                  maxNumberOfProducts +
                  " quantité maximum"
              );
            }
            if (limitedProducts) {
              selectedProduct.quantity =
                valueParsed < 1
                  ? 1
                  : valueParsed > maxNumberOfProducts
                  ? maxNumberOfProducts
                  : valueParsed;
            } else {
              selectedProduct.quantity = valueParsed;
            }
          }
        })
      );
    },
    []
  );

  const total = useMemo(() => {
    return getTotal(selectedProducts, useRetailPrice);
  }, [getTotal, selectedProducts, useRetailPrice]);

  useEffect(() => {
    onChange?.(selectedProducts);
  }, [onChange, selectedProducts]);

  const handleChangeAutocomplete = useCallback(
    (e, value) => {
      if (
        value &&
        selectedProducts.findIndex((o) => o.product.id === value.id) === -1
      ) {
        // let totalQuantity = linq.from(selectedProducts).sum((o) => o.quantity);
        // if (!limitedProducts || totalQuantity < maxNumberOfProducts) {
        setSelectedProducts((old: IMerchProduct[]) => [
          ...old,
          { product: value, quantity: 1 },
        ]);
        // } else {
        //   toast.warn(
        //     "Vous ne pouvez ajouter que " +
        //       maxNumberOfProducts +
        //       " produits maximum"
        //   );
        // }
      }
    },
    [selectedProducts]
  );

  const handleRemoveItem = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    merchProduct: IMerchProduct
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedProducts(
      produce((draft: IMerchProduct[]) => {
        return draft.filter((o) => o.product.id !== merchProduct.product.id);
      })
    );
  };

  const selectedProductsMemo = useMemo(() => {
    let result = selectedProducts ? [...selectedProducts].reverse() : [];
    return result;
  }, [selectedProducts]);

  const productsMemo = useMemo(() => {
    let result = products?.filter((product) => {
      return (
        selectedProductsMemo.findIndex((o) => o.product.id === product.id) ===
        -1
      );
    });
    return result || [];
  }, [products, selectedProductsMemo]);

  const columns = useMemo(
    () => [
      {
        Header: t("Label"),
        accessor: "product.label",
        disableFilters: true,
      },
      {
        Header: t("Reference"),
        accessor: "product.reference",
        disableFilters: true,
      },
      {
        Header: t("Quantity"),
        accessor: "quantity",
        canFilter: false,
        disableFilters: true,
        Cell: ({ row: { original } }: { row: { original: IMerchProduct } }) => {
          return (
            <TextField
              key={original.id} // REQUIRED for React to get the relation Input <-> Product
              disabled={disabled}
              type="number"
              value={original.quantity}
              sx={{ width: "100px", height: "55px" }}
              onChange={(e) => handleChangeQuantity(e, original)} // use onBlur to avoid bug on re-render table
              inputProps={{ min: 1 }}
            />
          );
        },
      },
      {
        Header: t("Weight"),
        id: "weight",
        disableFilters: true,
        Cell: ({ row: { original } }: { row: { original: IMerchProduct } }) => {
          return getProductTotalWeight(original);
        },
      },
      {
        Header: t("Price"),
        id: "price",
        disableFilters: true,
        Cell: ({ row: { original } }: { row: { original: IMerchProduct } }) => {
          return getProductTotal(original, useRetailPrice);
        },
      },
      {
        Header: t("Actions"),
        accessor: "actions",
        disableFilters: true,
        Cell: ({ row: { original } }: { row: { original: IMerchProduct } }) => {
          if (!disabled) {
            return <Delete onClick={(e) => handleRemoveItem(e, original)} />;
          } else {
            return <></>;
          }
        },
      },
    ],
    [
      getProductTotal,
      getProductTotalWeight,
      handleChangeQuantity,
      t,
      useRetailPrice,
    ]
  );

  return (
    <Grid
      container
      sx={{ height: "100%", flexWrap: "nowrap" }}
      flexDirection="column"
    >
      {!disabled && (
        <Grid item sx={{ width: "100%", marginTop: "20px" }}>
          <Autocomplete
            key={`autocompleteKey-${selectedProducts.length}`} // will empty the input value on each change
            disablePortal
            id="productList"
            options={productsMemo}
            getOptionLabel={(option: IProduct) => {
              return `${option.label} (ref: ${option.reference})`;
            }}
            onChange={handleChangeAutocomplete}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("Search product...")}
                variant="filled"
              />
            )}
          />
        </Grid>
      )}
      <Grid
        item
        sx={{
          width: "100%",
          flex: 1,
          overflow: "hidden",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <Grid container spacing={2} sx={{ paddingBottom: "20px" }}>
          <Grid item>
            <Button
              variant="contained"
              color={mode === "list" ? "primary" : "secondary"}
              onClick={() => setMode("list")}
            >
              <ListAlt />
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={mode === "grid" ? "primary" : "secondary"}
              onClick={() => setMode("grid")}
            >
              <Grid4x4 />
            </Button>
          </Grid>
        </Grid>
        {mode === "list" && (
          <Box
            sx={{
              overflow: "auto",
              // paddingBottom: "2px",
            }}
          >
            <MuiReactTable
              columns={columns}
              data={selectedProductsMemo}
              // onAddClick={handleAddClick}
              // onRowClick={handleRowClick}
              // title={t("Products selector")}
              labels={{
                exportButton: t("components/muireacttable|exportButton"),
                resultCount: t("components/muireacttable|resultCount"),
                rowCount: t("components/muireacttable|rowCount"),
                reinitiliazeFilters: t(
                  "components/muireacttable|reinitiliazeFilters"
                ),
              }}
            />
          </Box>
        )}
        {mode === "grid" && (
          <Grid
            container
            flexDirection={"column"}
            sx={{
              height: "400px",
              flexWrap: { xs: "nowrap", lg: "wrap" },
              alignContent: "flex-start",
              overflow: "auto",
              padding: "2px", // needed to see item paper elevation
            }}
            spacing={2}
          >
            {selectedProductsMemo.map(
              (merchProduct: IMerchProduct, index: any) => {
                return (
                  <Grid
                    item
                    key={merchProduct.product.id} // REQUIRED for React to get the relation Input <-> Product
                    sx={{
                      width: { xs: "100%", lg: "25%" },
                      minWidth: { xs: "unset", lg: "400px" },
                    }}
                  >
                    <Paper
                      elevation={2}
                      sx={{
                        padding: "10px",
                      }}
                    >
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item sx={{ flex: 1 }}>
                          <Typography>{merchProduct.product.label}</Typography>
                          <small>
                            ref: {merchProduct.product.reference}
                            <br />
                            p.u.
                            {formatAmount(merchProduct.product.price)}
                          </small>
                        </Grid>
                        <Grid item>
                          {!disabled && (
                            <Delete
                              onClick={(e) => handleRemoveItem(e, merchProduct)}
                            />
                          )}
                        </Grid>
                        <Grid item sx={{ marginLeft: "10px" }}>
                          <TextField
                            disabled={disabled}
                            type="number"
                            value={merchProduct.quantity}
                            sx={{ width: "65px" }}
                            onChange={(e) =>
                              handleChangeQuantity(e, merchProduct)
                            }
                          >
                            {qtySelectItems}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              }
            )}
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ textAlign: "right" }}>
        <Typography variant="h5">
          Total des produits: {formatAmount(total)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductsSelector;
