import React, { useCallback, useState } from "react";
import { ModalContext } from "contexts/ModalContext";
import { IModal } from "contexts/ModalContext";
import { v4 as uuidv4 } from "uuid";

function ModalProvider(props: React.PropsWithChildren<any>) {
  const [items, setItems] = useState<IModal[]>([]);

  const addModal = useCallback((modal: IModal) => {
    if (!modal.props.id) {
      modal.props.id = uuidv4();
    }
    if (!modal.props.dialogProps) {
      modal.props.dialogProps = {};
    }

    setItems((prev) => [...prev, modal]);
    return modal.props.id;
  }, []);

  const dismissModal = useCallback((id: string | undefined) => {
    setItems((prev) => {
      const index = prev.findIndex((item) => item.props.id === id);
      if (index !== -1) {
        let newValue = [...prev];
        newValue.splice(index, 1);
        return newValue;
      }
      return prev;
    });
  }, []);

  const dismissAll = useCallback(() => {
    setItems([]);
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, dismissModal, dismissAll }}>
      {items.map((modal, index) => {
        let dialogProps = {
          open: true,
          // style: { zIndex: 9999 },
          ...modal.props?.dialogProps,
        };

        dialogProps.onClose = () => {
          modal.props?.dialogProps?.onClose?.();
          const newItems: IModal[] = [...items];
          const index = newItems.findIndex(
            (item) => item.props.id === modal.props.id
          );
          if (index !== -1) {
            newItems[index].props.dialogProps.open = false;
            setItems(newItems);
          }
        };

        dialogProps.TransitionProps = dialogProps.TransitionProps
          ? dialogProps.TransitionProps
          : {};
        dialogProps.TransitionProps.onExited = () => {
          modal.props?.dialogProps?.TransitionProps?.onExited?.();
          dismissModal(modal.props.id);
        };

        return (
          <div key={index}>
            <modal.component
              {...modal.props}
              dialogProps={dialogProps}
            ></modal.component>
          </div>
        );
      })}
      {props.children}
    </ModalContext.Provider>
  );
}
export default ModalProvider;
