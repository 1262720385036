import { Button, Grid, TextField, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { update } from "api/account";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Account = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const { session } = useSelector((state: RootState) => state.auth);

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, t("Minimum {{count}} characters", { count: 8 }))
      .required(t("{{field_name}} required", { field_name: t("password") })),
    confirmPassword: yup
      .string()
      .required(
        t("{{field_name}} required", { field_name: t("confirmPassword") })
      )
      .oneOf(
        [yup.ref("password"), null],
        t("{{field_name}} incorrect", { field_name: t("confirmPassword") })
      ),
  });

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        await toast.promise(
          update(session.user.id, { password: values.password }),
          {
            pending: t("common|processing"),
            success: t("common|processing.success"),
            error: t("common|processing.error"),
          }
        );
      } catch (error) {}
    },
  });

  return (
    <div className={classes.container}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          ></Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("account.title")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              type="submit"
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("password")}
              name="password"
              type="password"
              inputProps={{
                autoComplete: "new-password",
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("confirmPassword")}
              name="confirmPassword"
              type="password"
              inputProps={{
                autoComplete: "new-password",
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              variant="filled"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "20px",
    },
    submitButtonContainer: {
      marginTop: 20,
    },
  })
);

export default Account;
