import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadSessionAsync, loginAsync } from "redux/actions/auth";
import { ILoadingType } from "types/ILoadingType";

export interface IAuthState {
  loadStatus: ILoadingType;
  loadSessionStatus: ILoadingType;
  saveStatus: ILoadingType;
  error?: string;
  session: ISession;
  isSessionOpened: boolean;
  isReset: boolean;
}

export const initialState: IAuthState = {
  loadStatus: ILoadingType.idle,
  loadSessionStatus: ILoadingType.idle,
  saveStatus: ILoadingType.idle,
  error: "",
  session: {} as ISession,
  isSessionOpened: false,
  isReset: false,
};

interface ISession {
  application: any;
  user: IUserSession;
  tenant: any;
}

export interface IUserSession {
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  id: number;
  roles: string[];
  permissions: string[];
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      newState.isReset = true;
      return newState;
    },
  },
  extraReducers: (builder) => {
    //loginAsync
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.loadStatus = ILoadingType.succeeded;
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.loadStatus = ILoadingType.failed;
      state.error = action.error?.message;
    });
    builder.addCase(loginAsync.pending, (state, action: PayloadAction<any>) => {
      state.loadStatus = ILoadingType.loading;
      state.isReset = false;
    });

    //load session
    builder.addCase(loadSessionAsync.fulfilled, (state, action) => {
      state.loadSessionStatus = ILoadingType.succeeded;
      state.isSessionOpened = action.payload?.user ? true : false;
      state.session = action.payload as any;
    });
    builder.addCase(loadSessionAsync.rejected, (state, action) => {
      state.loadSessionStatus = ILoadingType.failed;
      state.error = action.error?.message;
      state.isSessionOpened = false;
      state.session = {} as ISession;
    });
    builder.addCase(loadSessionAsync.pending, (state, action) => {
      state.loadSessionStatus = ILoadingType.loading;
      state.isReset = false;
    });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
