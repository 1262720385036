import AbpAppService from "api/AbpAppService";
import { ISimpleEntity } from "types/ISimpleEntity";

export interface ICompany extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
}

export interface ICreateCompany extends Omit<ICompany, "id"> {}

export const defaultEntity: ICreateCompany = {
  reference: "",
  label: "",
  isActive: true,
};

export const service = new AbpAppService<ICompany, ICompany>("Company");
