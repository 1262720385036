import {
  Button,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IStore, service } from "api/store";
import MuiReactTable from "components/MuiReactTable";
import { toast } from "react-toastify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  createAsync,
  updateAsync,
  updateState,
} from "redux/reducers/retailOrder";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";

const Store = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const retailOrderStore = useSelector((state: RootState) => state.retailOrder);
  const { id } = useSelector((state: RootState) => state.retailOrder);
  let [stores, setStores] = useState<IStore[]>([]);
  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(
        service.getAll({ isActive: true }),
        {
          pending: {
            render() {
              return t("common|Loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        }
      );
      if (apiResult?.success && apiResult.result) {
        setStores(apiResult.result.items);
      }
    })();
  }, [t]);

  useEffect(() => {
    if (id) {
      navigate(`/retailOrders/${id}`);
    }
  }, [navigate, id]);

  const handleValidate = useCallback(async () => {
    if (!id) {
      await appDispatch(createAsync(retailOrderStore));
    } else {
      await appDispatch(updateAsync(retailOrderStore));
    }
  }, [appDispatch, id, retailOrderStore]);

  const handleRowClick = (row: any, data: IStore, event: any) => {
    appDispatch(
      updateState({
        store: data,
        contactEmail: data.contactEmail,
        contactName: data.contactName,
        contactPhoneNumber: data.contactPhoneNumber,
      })
    );
  };

  const handleInputChange = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    appDispatch(updateState({ [event.target.name]: event.target.value }));
  };

  const isFormInvalid = useMemo(() => {
    return (
      !retailOrderStore.store ||
      retailOrderStore.contactEmail === "" ||
      retailOrderStore.contactName === "" ||
      retailOrderStore.contactPhoneNumber === "" ||
      retailOrderStore.busy
    );
  }, [
    retailOrderStore.busy,
    retailOrderStore.contactEmail,
    retailOrderStore.contactName,
    retailOrderStore.contactPhoneNumber,
    retailOrderStore.store,
  ]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Store selection")}
              </Typography>
              <Typography variant="caption" className={classes.caption}>
                {retailOrderStore.store?.address ||
                retailOrderStore.store?.city ? (
                  <span>
                    <strong>{retailOrderStore.store?.label}</strong>{" "}
                    {retailOrderStore.store?.address}{" "}
                    {retailOrderStore.store?.city}
                  </span>
                ) : (
                  t("Please, select a store")
                )}
              </Typography>
            </Paper>
          </Grid>
          <Grid item sx={{ marginTop: "30px" }}>
            <Grid container>
              <Grid item md={12} lg={7}>
                <MuiReactTable
                  // autoResetPage={autoResetPage}
                  forcePageSize={5}
                  onRowClick={handleRowClick}
                  // onFiltersChange={handleFiltersChange}
                  // header={(setFilter: any, setGlobalFilter: any, globalFilter: any) =>
                  //   tableHeader(setFilter, setGlobalFilter, globalFilter)
                  // }
                  columns={[
                    { Header: t("Internal id"), accessor: "reference" },
                    { Header: t("Label"), accessor: "label" },
                    { Header: t("City"), accessor: "city" },
                    { Header: t("Postal code"), accessor: "postalCode" },
                  ]}
                  data={stores}
                  // title={"Logs"}
                  // csvExport={true}
                  // fileName={"export-logs"}
                  labels={{
                    exportButton: t("components/muireacttable|exportButton"),
                    resultCount: t("components/muireacttable|resultCount"),
                    rowCount: t("components/muireacttable|rowCount"),
                    reinitiliazeFilters: t(
                      "components/muireacttable|reinitiliazeFilters"
                    ),
                  }}
                  defaultState={
                    retailOrderStore.store?.id
                      ? {
                          filters: [
                            {
                              id: "reference",
                              value: retailOrderStore.store?.reference,
                            },
                          ],
                        }
                      : {}
                  }
                />
              </Grid>
              <Grid item md={12} lg={5} sx={{ paddingLeft: "20px" }}>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  {/* <fieldset className={classes.fieldset}> */}
                  {/* <legend>
                      <Typography color={"primary"}>Contact</Typography>
                    </legend> */}
                  <TextField
                    autoComplete="off"
                    className={classes.formControl}
                    fullWidth
                    id="contactName"
                    label={t("Name")}
                    name="contactName"
                    onChange={handleInputChange}
                    placeholder="Dupont Jean"
                    required={true}
                    size={"medium"}
                    value={retailOrderStore.contactName || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 135 }}
                  />
                  <TextField
                    autoComplete="off"
                    className={classes.formControl}
                    // error={
                    //   ticket?.contactEmail != null &&
                    //   ticket.contactEmail?.length > 0 &&
                    //   !isValidEmail(ticket.contactEmail)
                    // }
                    fullWidth
                    id="contactEmail"
                    label={t("Email")}
                    name="contactEmail"
                    onChange={handleInputChange}
                    placeholder="pilotes@plv.fr"
                    required={true}
                    size={"medium"}
                    value={retailOrderStore.contactEmail || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 135 }}
                  />
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="contactPhone"
                    label={t("Phone number")}
                    name="contactPhoneNumber"
                    onChange={handleInputChange}
                    placeholder="01 02 04 05 06"
                    required={true}
                    size={"medium"}
                    value={retailOrderStore.contactPhoneNumber || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 35 }}
                  />
                  {/* </fieldset> */}
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Grid item></Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={isFormInvalid}
              onClick={handleValidate}
              fullWidth
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    caption: {
      "& span": {
        "& span": {
          fontWeight: "bold",
        },
      },
    },
    fieldset: {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
      borderWidth: "4px",
      borderStyle: "double",
    },
    formContainer: {},
    bottomFormContainer: {
      marginTop: 10,
    },
    formControl: {
      textAlign: "left",
      "&.MuiTextField-root": {
        marginBottom: "10px",
      },
    },
  })
);
export default Store;
