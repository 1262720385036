import React, { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren<any> {
  color?: string;
  text?: string;
}

export default function ColorLegend(props: React.PropsWithChildren<IProps>) {
  const colorContainerStyle = {
    width: 40,
    height: 20,
    border: "1px solid lightgrey",
    borderRadius: 10,
    overflow: "hidden",
  };

  const colorStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: props.color ?? "white",
  };

  const textContainerStyle = {
    marginLeft: 5,
  };

  const textStyle = {
    color: "black",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <div style={colorContainerStyle}>
        <div style={colorStyle}></div>
      </div>
      {props.text && (
        <div style={textContainerStyle}>
          <span style={textStyle}>{props.text}</span>
        </div>
      )}
    </div>
  );
}
