import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { deleteEntityAsync, getAsync, updateAsync } from "api/user";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { IUserDetail } from "types/IUser";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { Delete } from "@mui/icons-material";
import RoleSelector from "./RoleSelector";

const Edit = () => {
  const { t } = useTranslation(["common"]);
  let navigate = useNavigate();
  let { userId } = useParams();
  const classes = useStyles();
  const [user, setUser] = useState<IUserDetail>({
    name: "",
    surname: "",
    userName: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    isActive: false,
  } as IUserDetail);

  useEffect(() => {
    if (userId !== undefined) {
      (async function () {
        try {
          const apiResult = await toast.promise(
            getAsync(Number.parseInt(userId)),
            {
              pending: {
                render() {
                  return t("common|Loading");
                },
              },
              // success: t("common|loading.success"),
              error: {
                render({ data }: any) {
                  return getResponseExceptionMessage(data);
                },
                autoClose: false,
              },
            }
          );
          setUser({ ...apiResult.result, password: "", confirmPassword: "" });
        } catch (error) {}
      })();
    }
  }, [t, userId]);

  const handleDelete = useCallback(async () => {
    if (userId !== undefined) {
      (async function () {
        let apiResult = null;
        try {
          apiResult = await toast.promise(deleteEntityAsync(userId), {
            pending: t("common|processing"),
            success: t("common|processing.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          });
        } catch (error) {}

        if (apiResult?.success) {
          navigate("/users");
        }
      })();
    }
  }, [navigate, t, userId]);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(
        t("common|field_name.required", { field_name: t("firstname") })
      ),
    surname: yup
      .string()
      .required(t("common|field_name.required", { field_name: t("lastname") })),
    userName: yup
      .string()
      .min(5, t("common|count.characters", { count: 8 }))
      .required(t("common|field_name.required", { field_name: t("username") })),
    emailAddress: yup
      .string()
      .email(t("common|field_name.incorrect", { field_name: t("Email") }))
      .required(t("common|field_name.required", { field_name: t("Email") })),
    password: yup.string().min(8, t("common|count.characters", { count: 8 })),
    confirmPassword: yup.string().when("password", {
      is: (password: string) => password?.length > 0,
      then: yup
        .string()
        .required(
          t("common|field_name.required", {
            field_name: t("confirmPassword"),
          })
        )
        .oneOf(
          [yup.ref("password"), null],
          t("common|field_name.incorrect", {
            field_name: t("confirmPassword"),
          })
        ),
    }),
  });

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      // try {
      await toast.promise(updateAsync(values), {
        pending: t("common|processing"),
        success: t("common|processing.success"),
        error: t("common|processing.error"),
      });
      // } catch (error) {}
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
  } = formik;

  const handleRoleChange = useCallback(
    (roleNames: string[]) => {
      setFieldValue("roleNames", roleNames, false);
    },
    [setFieldValue]
  );

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="isActive"
                  onChange={handleChange}
                  checked={values.isActive}
                  color="primary"
                />
              }
              label={t("Enabled")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("username")} "{user?.userName}"
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: { xs: "center", md: "right" },
              }}
              spacing={2}
            >
              <Grid item>
                {user?.id && (
                  <Delete onClick={handleDelete} sx={{ cursor: "pointer" }}>
                    {t("common|button.delete")}
                  </Delete>
                )}
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  {t("common|button.validate")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("firstname")}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("lastname")}
              name="surname"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.surname}
              error={touched.surname && Boolean(errors.surname)}
              helperText={touched.surname && errors.surname}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("Email")}
              name="emailAddress"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.emailAddress}
              error={touched.emailAddress && Boolean(errors.emailAddress)}
              helperText={touched.emailAddress && errors.emailAddress}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("username")}
              name="userName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.userName}
              error={touched.userName && Boolean(errors.userName)}
              helperText={touched.userName && errors.userName}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RoleSelector
              key="roleSelector"
              onChange={handleRoleChange}
              initialValues={values.roleNames}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("password")}
              name="password"
              type="password"
              inputProps={{
                autoComplete: "new-password",
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("confirmPassword")}
              name="confirmPassword"
              type="password"
              inputProps={{
                autoComplete: "new-password",
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmPassword}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              variant="filled"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default Edit;
