import { Home } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Account from "routes/account/Account";
import HomeContent from "routes/app/HomeContent";
import Catalog from "routes/catalog/Catalog";

import CreateCompany from "routes/catalog/companies/Create";
import CreateConfiguration from "routes/catalog/configurations/Create";
import CreateDepth from "routes/catalog/depths/Create";
import CreateRetailOrder from "routes/retailOrders/Create";
import CreateFurniture from "routes/catalog/furnitures/Create";
import CreateMerch from "routes/catalog/merchs/Create";
import CreateOrder from "routes/orders/Create";
import CreateProduct from "routes/catalog/products/Create";
import CreateRole from "routes/roles/Create";
import CreateStore from "routes/stores/Create";
import CreateUser from "routes/users/Create";
import CreateFactory from "routes/factories/Create";
import CreateDeliverer from "routes/deliverers/Create";
import CreateAfterSaleRequest from "routes/afterSaleRequests/Create";
import CreateWidth from "routes/catalog/widths/Create";
import CreateUserToUser from "routes/userToUsers/Create";

import EditCompany from "routes/catalog/companies/Edit";
import EditConfiguration from "routes/catalog/configurations/Edit";
import EditDepth from "routes/catalog/depths/Edit";
import EditFurniture from "routes/catalog/furnitures/Edit";
import EditMerch from "routes/catalog/merchs/Edit";
import EditProduct from "routes/catalog/products/Edit";
import EditStore from "routes/stores/Edit";
import EditFactory from "routes/factories/Edit";
import EditDeliverer from "routes/deliverers/Edit";
import EditRole from "routes/roles/Edit";
import EditUser from "routes/users/Edit";
import EditFactoryRequest from "routes/factoryRequests/Edit";
import EditTransport from "routes/transportRequests/Edit";
import EditOrder from "routes/orders/Edit";
import EditRetailOrder from "routes/retailOrders/Edit";
import EditAfterSaleRequest from "routes/afterSaleRequests/Edit";
import EditWidth from "routes/catalog/widths/Edit";
import EditUserToUser from "routes/userToUsers/Edit";
import EditUserToUserHierarchy from "routes/userToUsers/EditHierarchy";

import ListOfCompanies from "routes/catalog/companies/List";
import ListOfConfigurations from "routes/catalog/configurations/List";
import ListOfDepths from "routes/catalog/depths/List";
import ListOfFurnitures from "routes/catalog/furnitures/List";
import ListOfMerchs from "routes/catalog/merchs/List";
import ListOfProducts from "routes/catalog/products/List";
import ListOfStores from "routes/stores/List";
import ListOfFactories from "routes/factories/List";
import ListOfDeliverers from "routes/deliverers/List";
import ListOfRoles from "routes/roles/List";
import ListOfUsers from "routes/users/List";
import ListOfFactoryRequests from "routes/factoryRequests/List";
import ListOfTransports from "routes/transportRequests/List";
import ListOfOrders from "routes/orders/List";
import ListOfRetailOrders from "routes/retailOrders/List";
import ListOfAfterSaleRequest from "routes/afterSaleRequests/List";
import ListOfWidths from "routes/catalog/widths/List";
import ListOfUserToUsers from "routes/userToUsers/List";

import Logs from "routes/logs/Logs";
import RolePermissions from "routes/permissions/Permissions";
import Planning from "routes/planning/Planning";
import StocksV2 from "routes/stocks/StocksV2";
import Billing from "routes/billing/Billing";

export interface IRoute {
  path: string;
  name: string | JSX.Element;
  exact: boolean;
  component: JSX.Element;
  children?: IRoute[];
  displayParamValue?: boolean;
}

export default function useRoutes() {
  const { t } = useTranslation(["common"]);

  const routes: IRoute[] = useMemo(
    () =>
      [
        {
          path: "/",
          name: <Home />,
          exact: true,
          component: <HomeContent />,
          children: [
            {
              path: "/account",
              name: t("breadcrumb.account"),
              exact: true,
              component: <Account />,
            },
            {
              path: "/permissions",
              name: t("breadcrumb.permissions"),
              exact: true,
              component: <RolePermissions />,
            },
            {
              path: "/roles",
              name: t("breadcrumb.roles"),
              exact: true,
              component: <ListOfRoles />,
              children: [
                {
                  path: "/roles/create",
                  name: t("breadcrumb.roles.create"),
                  exact: true,
                  component: <CreateRole />,
                },
                {
                  path: "/roles/:roleId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditRole />,
                },
              ],
            },
            {
              path: "/users",
              name: t("breadcrumb.users"),
              exact: true,
              component: <ListOfUsers />,
              children: [
                {
                  path: "/users/create",
                  name: t("breadcrumb.users.create"),
                  exact: true,
                  component: <CreateUser />,
                },
                {
                  path: "/users/:userId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditUser />,
                },
              ],
            },
            {
              path: "/retailOrders",
              name: t("breadcrumb.retailOrders"),
              exact: true,
              component: <ListOfRetailOrders />,
              children: [
                {
                  path: "/retailOrders/create",
                  name: t("breadcrumb.retailOrders.create"),
                  exact: true,
                  component: <CreateRetailOrder />,
                },
                {
                  path: "/retailOrders/:retailOrderId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditRetailOrder />,
                },
              ],
            },
            {
              path: "/orders",
              name: t("breadcrumb.orders"),
              exact: true,
              component: <ListOfOrders />,
              children: [
                {
                  path: "/orders/create",
                  name: t("breadcrumb.orders.create"),
                  exact: true,
                  component: <CreateOrder />,
                },
                {
                  path: "/orders/:orderId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditOrder />,
                },
              ],
            },
            {
              path: "/logs",
              name: t("breadcrumb.logs"),
              exact: true,
              component: <Logs />,
            },
            {
              path: "/catalog",
              name: t("breadcrumb.catalog"),
              exact: true,
              component: <Catalog />,
              children: [
                {
                  path: "/catalog/companies",
                  name: t("breadcrumb.catalog.companies"),
                  exact: true,
                  component: <ListOfCompanies />,
                  children: [
                    {
                      path: "/catalog/companies/create",
                      name: t("breadcrumb.catalog.companies.create"),
                      exact: true,
                      component: <CreateCompany />,
                    },
                    {
                      path: "/catalog/companies/:companyId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditCompany />,
                    },
                  ],
                },
                {
                  path: "/catalog/furnitures",
                  name: t("breadcrumb.catalog.furnitures"),
                  exact: true,
                  component: <ListOfFurnitures />,
                  children: [
                    {
                      path: "/catalog/furnitures/create",
                      name: t("breadcrumb.catalog.furnitures.create"),
                      exact: true,
                      component: <CreateFurniture />,
                    },
                    {
                      path: "/catalog/furnitures/:furnitureId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditFurniture />,
                    },
                  ],
                },
                {
                  path: "/catalog/depths",
                  name: t("breadcrumb.catalog.depths"),
                  exact: true,
                  component: <ListOfDepths />,
                  children: [
                    {
                      path: "/catalog/depths/create",
                      name: t("breadcrumb.catalog.depths.create"),
                      exact: true,
                      component: <CreateDepth />,
                    },
                    {
                      path: "/catalog/depths/:depthId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditDepth />,
                    },
                  ],
                },
                {
                  path: "/catalog/widths",
                  name: t("breadcrumb.catalog.widths"),
                  exact: true,
                  component: <ListOfWidths />,
                  children: [
                    {
                      path: "/catalog/widths/create",
                      name: t("breadcrumb.catalog.widths.create"),
                      exact: true,
                      component: <CreateWidth />,
                    },
                    {
                      path: "/catalog/widths/:widthId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditWidth />,
                    },
                  ],
                },
                {
                  path: "/catalog/merchs",
                  name: t("breadcrumb.catalog.merchs"),
                  exact: true,
                  component: <ListOfMerchs />,
                  children: [
                    {
                      path: "/catalog/merchs/create",
                      name: t("breadcrumb.catalog.merchs.create"),
                      exact: true,
                      component: <CreateMerch />,
                    },
                    {
                      path: "/catalog/merchs/:merchId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditMerch />,
                    },
                  ],
                },
                {
                  path: "/catalog/products",
                  name: t("breadcrumb.catalog.products"),
                  exact: true,
                  component: <ListOfProducts />,
                  children: [
                    {
                      path: "/catalog/products/create",
                      name: t("breadcrumb.catalog.products.create"),
                      exact: true,
                      component: <CreateProduct />,
                    },
                    {
                      path: "/catalog/products/:productId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditProduct />,
                    },
                  ],
                },
                {
                  path: "/catalog/configurations",
                  name: t("breadcrumb.catalog.configurations"),
                  exact: true,
                  component: <ListOfConfigurations />,
                  children: [
                    {
                      path: "/catalog/configurations/create",
                      name: t("breadcrumb.catalog.configurations.create"),
                      exact: true,
                      component: <CreateConfiguration />,
                    },
                    {
                      path: "/catalog/configurations/:configurationId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: <EditConfiguration />,
                    },
                  ],
                },
              ],
            },
            {
              path: "/stores",
              name: t("breadcrumb.stores"),
              exact: true,
              component: <ListOfStores />,
              children: [
                {
                  path: "/stores/create",
                  name: t("breadcrumb.stores.create"),
                  exact: true,
                  component: <CreateStore />,
                },
                {
                  path: "/stores/:storeId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditStore />,
                },
              ],
            },
            {
              path: "/stocks",
              name: t("breadcrumb.stocks"),
              exact: true,
              component: <StocksV2 />,
              children: [],
            },
            {
              path: "/deliverers",
              name: t("breadcrumb.deliverers"),
              exact: true,
              component: <ListOfDeliverers />,
              children: [
                {
                  path: "/deliverers/create",
                  name: t("breadcrumb.deliverers.create"),
                  exact: true,
                  component: <CreateDeliverer />,
                },
                {
                  path: "/deliverers/:delivererId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditDeliverer />,
                },
              ],
            },
            {
              path: "/factories",
              name: t("breadcrumb.factories"),
              exact: true,
              component: <ListOfFactories />,
              children: [
                {
                  path: "/factories/create",
                  name: t("breadcrumb.factories.create"),
                  exact: true,
                  component: <CreateFactory />,
                },
                {
                  path: "/factories/:factoryId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditFactory />,
                },
              ],
            },
            {
              path: "/planning",
              name: t("breadcrumb.planning"),
              exact: true,
              component: <Planning />,
            },
            {
              path: "/factoryRequests",
              name: t("breadcrumb.factoryRequests"),
              exact: true,
              component: <ListOfFactoryRequests />,
              children: [
                {
                  path: "/factoryRequests/:factoryRequestId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditFactoryRequest />,
                },
              ],
            },
            {
              path: "/transportRequests",
              name: t("breadcrumb.transportRequests"),
              exact: true,
              component: <ListOfTransports />,
              children: [
                {
                  path: "/transportRequests/:transportRequestId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditTransport />,
                },
              ],
            },
            {
              path: "/afterSaleRequests",
              name: t("breadcrumb.afterSaleRequests"),
              exact: true,
              component: <ListOfAfterSaleRequest />,
              children: [
                {
                  path: "/afterSaleRequests/create",
                  name: t("breadcrumb.afterSaleRequests.create"),
                  exact: true,
                  component: <CreateAfterSaleRequest />,
                },
                {
                  path: "/afterSaleRequests/:afterSaleRequestId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditAfterSaleRequest />,
                },
              ],
            },
            {
              path: "/billing",
              name: t("breadcrumb.billing"),
              exact: true,
              component: <Billing />,
            },
            {
              path: "/userToUsers",
              name: t("breadcrumb.userToUsers"),
              exact: true,
              component: <ListOfUserToUsers />,
              children: [
                {
                  path: "/userToUsers/create",
                  name: t("breadcrumb.create"),
                  exact: true,
                  component: <CreateUserToUser />,
                },
                {
                  path: "/userToUsers/:userToUserId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditUserToUser />,
                },
                {
                  path: "/userToUsers/hierarchy/:userToUserId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: <EditUserToUserHierarchy />,
                },
              ],
            },
          ],
        },
      ] as IRoute[],
    [t]
  );

  const getChildrenRoutes = useCallback((route: IRoute) => {
    let result: IRoute[] = [];
    if (route.children?.length) {
      route.children.forEach((routeChild) => {
        result.push(routeChild);
        let childrenRoutes = getChildrenRoutes(routeChild);
        result = [...result, ...childrenRoutes];
      });
    }
    return result;
  }, []);

  const routesFlattened: IRoute[] = useMemo(() => {
    let result: IRoute[] = [];
    routes.forEach((route) => {
      result.push(route);
      let childrenRoutes = getChildrenRoutes(route);
      result = [...result, ...childrenRoutes];
    });
    return result;
  }, [routes, getChildrenRoutes]);

  return { routes, routesFlattened };
}
