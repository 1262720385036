import { Download } from "@mui/icons-material";
import { Chip, Grid, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IRetailOrder } from "api/retailOrder";
import { getPdfViewerUrl, getImageViewerUrl } from "helpers/files";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  order: IRetailOrder;
}

export default function RetailOrderSummary(props: IProps) {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const { order } = props;

  const orderFormUrl = useMemo(() => {
    return getPdfViewerUrl(order.orderFormId);
  }, [order.orderFormId]);

  const orderFormUrlSigned = useMemo(() => {
    if (order.orderFormSignedId) {
      return order.signature && order.signature !== ""
        ? getPdfViewerUrl(order.orderFormSignedId)
        : getImageViewerUrl(order.orderFormSignedId ?? "");
    }
    return undefined;
  }, [order.orderFormSignedId, order.signature]);

  const deliveryFormUrl = useMemo(() => {
    return getPdfViewerUrl(order.deliveryFormId);
  }, [order.deliveryFormId]);

  const markupUrl = useMemo(() => {
    return getPdfViewerUrl(order.markupFileId);
  }, [order.markupFileId]);

  const handleOpenFile = useCallback((url?: string) => {
    if (!url) {
      return;
    }
    window.open(url, "blank");
  }, []);

  return (
    <Grid container flexDirection="column" className={classes.container}>
      <Grid item>
        <Typography variant="h4">
          {t("Order reference")}: {order.reference}
        </Typography>
      </Grid>
      <Grid item sx={{ marginTop: "10px" }}>
        <Typography variant="h6">
          {order.storeReference}
          <br />
          {order.storeLabel}
          <br />
          {order.address} {order.postalCode}
          <br />
          {order.city}
        </Typography>
      </Grid>
      <Grid item sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item>
            <Chip
              icon={<Download />}
              onClick={() => handleOpenFile(orderFormUrl)}
              label={t("See order form")}
              variant="outlined"
              disabled={orderFormUrl ? false : true}
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<Download />}
              onClick={() => handleOpenFile(orderFormUrlSigned)}
              label={t("See signed order form")}
              variant="outlined"
              disabled={orderFormUrlSigned ? false : true}
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<Download />}
              onClick={() => handleOpenFile(deliveryFormUrl)}
              label={t("See delivery form")}
              variant="outlined"
              disabled={deliveryFormUrl ? false : true}
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<Download />}
              onClick={() => handleOpenFile(markupUrl)}
              label={t("See markup")}
              variant="outlined"
              disabled={markupUrl ? false : true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);
