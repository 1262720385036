import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import AbpAppService from "api/AbpAppService";
import moment from "moment";
import { isNullOrUndefined } from "helpers/tools";
import linq from "linq";
import { IProduct, service } from "api/product";
import DataGrid from "react-data-grid";
import classNames from "classnames";
import { ModalContext } from "contexts/ModalContext";
import ReplenishmentModal from "./ReplenishmentModal";
import { IReplenishmentModal } from "./ReplenishmentModal";
import { unparse } from "papaparse";
import { saveAs } from "file-saver";
import {
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  TextField,
  Link,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Theme } from "react-toastify";
import { Edit, Today } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";

const stockAppService = new AbpAppService("stock");

export default function StocksV2() {
  const { addModal } = useContext(ModalContext);

  const [minDate, setMinDate] = useState<any>(moment());
  const [maxDate, setMaxDate] = useState<any>();
  const [products, setProducts] = useState<any>();
  const [stocks, setStocks] = useState<any>();

  const loadStocks = useCallback(async () => {
    try {
      const getStocksOutput = await stockAppService.invoke("get", "getStocks", {
        minDate: minDate.clone().startOf("week").toISOString(true),
        maxDate: maxDate?.clone().endOf("week").toISOString(true),
      });

      // console.log(getStocksOutput);
      setStocks(getStocksOutput.result);
    } catch (ex) {
      console.error(ex);
    }
  }, [maxDate, minDate]);

  const editStockMove = useCallback(
    (stockMoveId?: any) => {
      addModal({
        component: ReplenishmentModal,
        props: {
          products,
          stockMoveId,
          dialogProps: {
            onClose: async () => {
              await loadStocks();
            },
          },
        },
      } as IReplenishmentModal);
    },
    [addModal, loadStocks, products]
  );

  const columns = useMemo(
    () =>
      products
        ? [
            {
              name: "Semaine",
              key: "week",
              frozen: true,
            },
            {
              name: "Référence",
              key: "orderReference",
              frozen: true,
              width: 180,
              formatter: ({ row }: { row: any }) => {
                if (row.type === "order") {
                  const url = `/#/orders/${row.orderId}`;
                  return (
                    <Link href={url} target="blank">
                      {row.orderReference}
                    </Link>
                  );
                } else if (row.type === "stockMove") {
                  return (
                    <>
                      {row.orderReference}
                      {moment().isBefore(moment(row.date)) && (
                        <Edit
                          onClick={() => editStockMove(row.stockMoveId)}
                          style={{ fontSize: "14px", cursor: "pointer" }}
                        />
                      )}
                    </>
                  );
                }
                return row.orderReference;
              },
            },
            {
              name: "Ville",
              key: "city",
              frozen: true,
              width: 200,
              formatter: ({ row }: { row: any }) => {
                if (row.type === "order") {
                  return `${row.city}`;
                }
                return "";
              },
            },
            {
              name: "Date chargement",
              key: "loadingDate",
              frozen: true,
              width: 150,
              formatter: ({ row }: { row: any }) => {
                if (row.type === "order") {
                  return `${moment(row.date).format("DD/MM/yyyy HH:mm")}`;
                }
                return "";
              },
            },
            ...products.map((o: IProduct) => ({
              key: o.id,
              headerRenderer: () => (
                <div
                  style={{
                    lineHeight: "normal",
                    whiteSpace: "normal",
                  }}
                >
                  {o.reference}
                  <br />
                  <span style={{ fontSize: "11px", fontWeight: "normal" }}>
                    {o.label}
                  </span>
                </div>
              ),
              name: o.reference,
              formatter: ({ row, column }: { row: any; column: any }) =>
                !isNullOrUndefined(row.products?.[column.key])
                  ? `${
                      row.type === "stockMove" && row.products?.[column.key] > 0
                        ? "+"
                        : ""
                    }${row.products?.[column.key]}`
                  : null,
            })),
          ]
        : null,
    [products, editStockMove]
  );

  const weeks = useMemo(() => {
    if ((stocks?.years && Object.keys(stocks.years).length > 0) || maxDate) {
      const weeks: any = [];

      const maxDateDisplay = stocks.years
        ? linq
            .from(Object.values(stocks.years))
            .selectMany((o: any) => Object.values(o))
            .selectMany((o: any) => o)
            .where((o: any) => o.date)
            .max((o: any) => o.date)
        : null;

      const maxDateMoment = (maxDate ? maxDate : moment(maxDateDisplay))
        .clone()
        .endOf("week");

      const currentDate = minDate.clone().startOf("week");

      while (currentDate.isSameOrBefore(maxDateMoment)) {
        // console.log(
        //   currentDate.format("DD/MM/YYYY"),
        //   currentDate.isoWeek(),
        //   currentDate.isoWeekYear()
        // );

        weeks.push({
          date: currentDate.clone(),
          week: currentDate.isoWeek(),
          year: currentDate.isoWeekYear(),
        });

        currentDate.add(1, "week");
      }

      return weeks;
    }
  }, [minDate, maxDate, stocks]);

  const rows = useMemo(() => {
    if (stocks?.years && weeks) {
      const result: any = [];

      let lastBalance = null;

      for (const week of weeks) {
        if (stocks.years[week.year]?.[week.week]) {
          let index = 0;

          for (const stock of stocks.years[week.year]?.[week?.week]) {
            const weekNum = week.week < 10 ? "0" + week.week : week.week;
            const row = {
              week: index === 0 ? `${week.year}/${weekNum}` : null,
              ...stock,
            };

            result.push(row);

            if (stock.type === "balance") {
              lastBalance = stock;
            }

            index++;
          }
        } else {
          const leftQuantityRow = {
            week: `${week.week}/${week.year}`,
            orderReference: "Reste",
            products: lastBalance?.products,
            type: "balance",
          };

          result.push(leftQuantityRow);
        }
      }

      return result;
    }
  }, [stocks, weeks]);

  const csv = useMemo(() => {
    if (rows && products) {
      const result = unparse(
        rows.map((o: any) => ({
          semaine: o.week,
          reference:
            o.type === "order" ? `${o.orderReference}` : o.orderReference,
          ville: o.type === "order" ? `${o.city}` : "",
          chargement:
            o.type === "order"
              ? `${moment(o.date).format("DD/MM/yyyy HH:mm")}`
              : "",
          ...linq.from(products).toObject(
            (p: any) => `${p.reference} - ${p.name}`,
            (p: any) =>
              !isNullOrUndefined(o.products?.[p.id])
                ? `${
                    o.type === "stockMove" && o.products?.[p.id] > 0 ? "+" : ""
                  }${o.products?.[p.id]}`
                : null
          ),
        })),
        {
          delimiter: ";",
        }
      );

      return result;
    }

    return "";
  }, [rows, products]);

  const cssClasses = useStyles();

  useEffect(() => {
    (async function () {
      try {
        const getAllProductsOutput = await service.getAll();
        if (getAllProductsOutput.result.items) {
          // prettier-ignore
          const customProductOrder: string[] = [
          // "ITM201", "ITM202", "ITM203", "ITM204", "ITM256", "ITM257",
          // "ITM205", "ITM206", "ITM207", "ITM208", "ITM209", "ITM210",
          // "ITM211", "ITM212", "ITM213", "ITM214", "ITM250", "ITM251",
          // "ITM215", "ITM216", "ITM217", "ITM218", "ITM219", "ITM220",
          // "ITM221", "ITM222", "ITM223", "ITM224", "ITM225", "ITM248",
          // "ITM254", "ITM255", "ITM226", "ITM227", "ITM258", "ITM259",
          // "ITM228", "ITM229", "ITM230", "ITM235", "ITM236", "ITM237",
          // "ITM238", "ITM260", "ITM261", "ITM231", "ITM265", "ITM233",
          // "ITM234", "ITM239", "ITM249", "ITM264", "ITM262", "ITM263",
          // "ITM232"
        ];

          const products = linq
            .from(getAllProductsOutput.result.items)
            .where((o) => o.reference !== "ITM258" && o.reference !== "ITM259") // confirmation par mail de Joël le 26/04/22, voir https://trello.com/c/Iyn0uEKj/82-1171-retours
            .orderBy((o) =>
              customProductOrder.indexOf(o.reference) !== -1
                ? customProductOrder.indexOf(o.reference)
                : Number.MAX_VALUE
            )
            .toArray();

          setProducts(products);
        }
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, []);

  useEffect(() => {
    loadStocks();
  }, [minDate, maxDate, loadStocks]);

  return (
    <div className={cssClasses.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ flex: "1" }}>Stocks</h1>

        <Button
          onClick={() => editStockMove()}
          variant="contained"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Réapprovisionnement
        </Button>

        <Button
          onClick={() =>
            saveAs(
              new Blob([`\uFEFF${csv}`], { type: "text/csv;charset=utf-8" }),
              "export.csv"
            )
          }
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Export
        </Button>

        <DatePicker
          renderInput={(params) => <TextField {...params} />}
          label="Date de début"
          maxDate={maxDate?.toDate()}
          value={minDate.toDate()}
          onChange={(e) => setMinDate(moment(e))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Today />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Checkbox
          checked={!isNullOrUndefined(maxDate)}
          onChange={(e) =>
            setMaxDate(e.target.checked ? minDate.clone().endOf("year") : null)
          }
          style={{ marginLeft: "30px" }}
        />

        {!maxDate && <div>Avec date de fin</div>}

        {maxDate && (
          <DatePicker
            renderInput={(params) => <TextField {...params} />}
            label="Date de fin"
            minDate={minDate.toDate()}
            value={maxDate.toDate()}
            onChange={(e) => setMaxDate(moment(e))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Today />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>

      <DataGrid
        columns={columns ?? []}
        rows={rows ?? []}
        headerRowHeight={130}
        rowClass={(row: any): any =>
          classNames({
            [cssClasses.leftQuantityRow]: row.type === "balance",
          })
        }
        style={{ flex: "1" }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      height: "100%",
      overflow: "hidden",
    },
    leftQuantityRow: {
      "&.rdg-row": {
        backgroundColor: "#F0F0F0",
        borderBottom: "2px solid #AAA",
      },
    },
  })
);
