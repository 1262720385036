import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Theme,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import theme from "theme";
import { useCallback, useEffect, useState } from "react";
import { IRole } from "types/IRole";
import { getAllAsync } from "api/role";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, roleNames: readonly string[], theme: Theme) {
  return {
    fontWeight:
      roleNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const RoleSelector = (props: {
  onChange: Function;
  initialValues?: string[];
}) => {
  const { onChange, initialValues = props.initialValues ?? [] } = props;

  const { t } = useTranslation(["common"]);

  const [roles, setRoles] = useState<IRole[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const apiResult = await toast.promise(getAllAsync(), {
          pending: {
            render() {
              return t("common|Loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.success && apiResult.result) {
          setRoles(apiResult.result.items);
        }
      } catch (error) {}
    })();
  }, [t]);

  const [roleNames, setRoleNames] = useState<string[]>([]);

  // fill with initial values
  useEffect(() => {
    if (roleNames.length === 0 && initialValues.length > 0) {
      setRoleNames(initialValues);
    }
  }, [initialValues, roleNames.length]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof roleNames>, child: React.ReactNode) => {
      const {
        target: { value },
      } = event;

      // On autofill we get a stringified value.
      const result = typeof value === "string" ? value.split(",") : value;
      setRoleNames(result);
      onChange(result);
    },
    [onChange]
  );

  return (
    <FormControl fullWidth>
      <InputLabel>Roles</InputLabel>
      <Select
        multiple
        value={roleNames}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {roles.map((role) => (
          <MenuItem
            key={role.id}
            value={role.normalizedName}
            style={getStyles(role.normalizedName, roleNames, theme)}
          >
            {role.normalizedName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleSelector;
