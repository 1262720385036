import { Box, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IUserToUser, service } from "api/userToUser";
import MuiReactTable, { IFilter } from "components/MuiReactTable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { selectFormById, setForm } from "redux/reducers/forms";

const FORM_ID = "userToUsers"; // used to save filter values

export default function List() {
  const classes = useStyles();
  let navigate = useNavigate();
  const { t } = useTranslation(["common", "components/muireacttable"]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);
  let [userToUsers, setUserToUsers] = useState<IUserToUser[]>([]);

  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(service.getAll(), {
        pending: {
          render() {
            return t("common|Loading");
          },
        },
        // success: t("common|loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.success && apiResult.result) {
        setUserToUsers(apiResult.result.items);
      }
    })();
  }, [t]);

  const columns = useMemo(
    () => [
      { Header: "From user", accessor: "fromEmail" },
      { Header: "From role", accessor: "fromRole" },
      { Header: "To user", accessor: "toEmail" },
      { Header: "To role", accessor: "toRole" },
    ],
    []
  );

  const handleAddClick = useCallback(
    (row: any, data: IUserToUser, event: any) => {
      // navigate(`/userToUsers/${data.id}`);
      navigate(`/userToUsers/create`);
    },
    [navigate]
  );

  const handleRowClick = useCallback(
    (row: any, data: IUserToUser, event: any) => {
      // navigate(`/userToUsers/${data.id}`);
      navigate(`/userToUsers/hierarchy/${data.id}`);
    },
    [navigate]
  );

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  return (
    <Box className={classes.container}>
      <MuiReactTable
        columns={columns}
        data={userToUsers}
        title={t("User hierarchy")}
        onAddClick={handleAddClick}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        csvExport={true}
        fileName={t("userHierarchy")}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
    },
  })
);
