import { Grid, TextField, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { hasPermission } from "helpers/user";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
// import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import background from "./background.jpg";
import Orders from "./charts/Orders";
import { useTranslation } from "react-i18next";
import FactoryRequests from "./charts/FactoryRequests";
import TransportRequests from "./charts/TransportRequests";
import RetailOrders from "./charts/RetailOrders";
import AfterSaleRequests from "./charts/AfterSaleRequests";

const HomeContent = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const { session } = useSelector((state: RootState) => state.auth);
  const [dateFilterStart, setDateFilterStart] = useState<Moment>(
    moment().startOf("month")
  );
  const [dateFilterEnd, setDateFilterEnd] = useState<Moment>(
    moment().add(3, "months").endOf("month")
  );

  const canSeeOrdersChart = useMemo(() => {
    return hasPermission(session.user, "Order.GetAll");
  }, [session.user]);

  const canSeeFactoryRequestsChart = useMemo(() => {
    return hasPermission(session.user, "FactoryRequest.GetAll");
  }, [session.user]);

  const canSeeTransportRequestsChart = useMemo(() => {
    return hasPermission(session.user, "TransportRequest.GetAll");
  }, [session.user]);

  const canSeeRetailOrderChart = useMemo(() => {
    return hasPermission(session.user, "RetailOrder.GetAll");
  }, [session.user]);

  const canSeeAfterSaleRequestChart = useMemo(() => {
    return hasPermission(session.user, "AfterSaleRequest.GetAll");
  }, [session.user]);

  const canSeeFilters = useMemo(() => {
    return (
      canSeeOrdersChart ||
      canSeeFactoryRequestsChart ||
      canSeeTransportRequestsChart ||
      canSeeRetailOrderChart ||
      canSeeAfterSaleRequestChart
    );
  }, [
    canSeeOrdersChart,
    canSeeFactoryRequestsChart,
    canSeeTransportRequestsChart,
    canSeeRetailOrderChart,
    canSeeAfterSaleRequestChart,
  ]);

  return (
    <div className={classes.container}>
      <Typography variant="h2">
        {t("Mc Cormick")} - {t("Furniture order")}
      </Typography>
      {/* <Typography variant="h2">{t("Furniture order")}</Typography> */}
      {canSeeFilters && (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "flex-start",
              marginTop: "40px",
              marginBottom: "20px",
            }}
          >
            <Grid item>
              <DatePicker
                renderInput={(params) => <TextField {...params} />}
                label="Du"
                value={dateFilterStart}
                onChange={(value: any) => {
                  const momentDate = value as Moment;
                  if (momentDate && momentDate.isValid()) {
                    const dateFormatted = momentDate
                      .set("hour", 0)
                      .set("minute", 0);
                    setDateFilterStart(dateFormatted);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <DatePicker
                renderInput={(params) => <TextField {...params} />}
                label="au"
                value={dateFilterEnd}
                onChange={(value: any) => {
                  const momentDate = value as Moment;
                  if (momentDate && momentDate.isValid()) {
                    const dateFormatted = momentDate
                      .set("hour", 22)
                      .set("minute", 59)
                      .set("second", 59);
                    setDateFilterEnd(dateFormatted);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            {canSeeOrdersChart && (
              <Grid item sx={{ width: "50%" }}>
                <Orders startDate={dateFilterStart} endDate={dateFilterEnd} />
              </Grid>
            )}
            {canSeeFactoryRequestsChart && (
              <Grid item sx={{ width: "50%" }}>
                <FactoryRequests
                  startDate={dateFilterStart}
                  endDate={dateFilterEnd}
                />
              </Grid>
            )}
            {canSeeTransportRequestsChart && (
              <Grid item sx={{ width: "50%" }}>
                <TransportRequests
                  startDate={dateFilterStart}
                  endDate={dateFilterEnd}
                />
              </Grid>
            )}
            {canSeeRetailOrderChart && (
              <Grid item sx={{ width: "50%" }}>
                <RetailOrders
                  startDate={dateFilterStart}
                  endDate={dateFilterEnd}
                />
              </Grid>
            )}
            {canSeeAfterSaleRequestChart && (
              <Grid item sx={{ width: "50%" }}>
                <AfterSaleRequests
                  startDate={dateFilterStart}
                  endDate={dateFilterEnd}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      padding: "40px",
      background: `url(${background})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto",
    },
    itemContainer: {
      marginTop: "50px",
    },
    item: {
      marginTop: "20px",
    },
    itemTitle: {
      textAlign: "center",
    },
  })
);

export default HomeContent;
