import moment, { Moment } from "moment";

export function isValidEmail(email: string): boolean {
  //https://stackoverflow.com/a/1373724
  //https://tools.ietf.org/html/rfc2822#section-3.4.1
  var mailformat = /^\S+@\S+$/;
  if (email.match(mailformat)) {
    return true;
  }
  return false;
}

export function formatDate(date: string = "", format: string = "DD/MM/YYYY") {
  return moment(date).format(format);
}

export function checkDate(
  filterValue: string,
  date?: string,
  dateFormat: string = "DD/MM/YYYY HH:mm"
) {
  if (filterValue === "" && !date) {
    return true;
  } else {
    return formatDate(date, dateFormat).indexOf(filterValue) !== -1;
  }
}

export function momentDateToJson(moment: Moment) {
  return moment.toDate().toJSON();
}

export function merge(
  obj1: any,
  obj2: any,
  withoutNullOrUndefined: boolean = true
) {
  let result = obj1;
  for (const key in obj2) {
    if (
      withoutNullOrUndefined &&
      obj2[key] !== undefined &&
      obj2[key] !== null
    ) {
      result[key] = obj2[key];
    }
  }
  return result;
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function formatAmount(
  amount: number,
  currency: boolean = true,
  currencyString: string = "€"
) {
  let result = amount.toFixed(2);
  if (currency && currencyString) {
    result += currencyString;
  }
  return result.replace(".", ",");
}

export function formatWeight(
  weight: number,
  symbol: boolean = true,
  symbolString: string = " Kg"
) {
  let result = weight.toFixed(3);
  if (symbol && symbolString) {
    result += symbolString;
  }
  return result.replace(".", ",");
}

export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getDayLabelByDayOfTheWeek(
  dayOfTheWeek: number,
  capitalized: boolean = false
) {
  const dayLabel = moment().day(dayOfTheWeek).format("dddd");
  if (capitalized) {
    return capitalize(dayLabel);
  }
  return dayLabel;
}

export function parseIntOrFalse(value: string | number) {
  const intValue = parseInt(value.toString(), 10);
  if (!isNaN(intValue)) {
    return intValue;
  }
  return false;
}

export function getConfigValue(configName: string) {
  return (window as any)?.config?.[configName];
}

export function testPromise() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("resolve");
    }, 2000);
  });
}

export function generateKey(name: string) {
  return `${name}-${Date.now().toString()}`;
}
