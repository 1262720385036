import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IAbpResponse } from "types/IApi";
import { ISimpleEntity } from "types/ISimpleEntity";
import * as ApiSettings from "./apiSettings";
import { getDefaultHeaders } from "./apiSettings";
import { ICompany } from "./company";

export interface IStore extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
  address: string;
  city: string;
  postalCode: string;
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
}

export interface ICreateStore extends Omit<IStore, "id"> {}

export const defaultEntity: ICreateStore = {
  reference: "",
  label: "",
  isActive: false,
  address: "",
  city: "",
  postalCode: "",
  contactEmail: "",
  contactName: "",
  contactPhoneNumber: "",
};

export const service = new AbpAppService<IStore, IStore>("Store");

export interface IImportExcelFileResult {
  identicalStores: IStore[];
  invalidStores: IStore[];
  addedStores: IStore[];
  deletedStores: IStore[];
  updatedStores: IStore[];
  addedCompanies: ICompany[];
}

export const importStores = (params: {
  filePath: string;
  isPreview?: boolean;
  isDebug?: boolean;
  maxRowsToHandle?: number;
}): Promise<AxiosResponse<IAbpResponse<IImportExcelFileResult>>> => {
  return axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Import`,
    params,
    {
      headers: {
        ...getDefaultHeaders(),
      },
    }
  );
};
