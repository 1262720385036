import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  Theme,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IMerch } from "api/merch";
import ChipFileList from "components/ChipFileList";
import { IModal, IModalProps } from "contexts/ModalContext";
import { formatAmount, formatWeight } from "helpers/tools";
import linq from "linq";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

interface IViewModalProps extends IModalProps {
  merch: IMerch;
}

export interface IViewModal extends IModal {
  props: IViewModalProps;
}

const ViewModal = (props: IViewModalProps) => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { merch } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    props.dialogProps?.onClose?.();
  };

  return (
    <div className={classes.container}>
      <Dialog
        {...props.dialogProps}
        fullScreen={fullScreen}
        onClose={handleClose}
        transitionDuration={500}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} flexDirection="column">
            <Grid item>
              <Typography variant="h6">{t("General")}</Typography>
              <Grid container flexDirection={"column"}>
                {[
                  { label: t("Reference"), value: merch.reference },
                  { label: t("CIP"), value: merch.cip },
                  { label: t("Description"), value: merch.description },
                  { label: t("Company"), value: merch.company.label },
                  { label: t("Furniture"), value: merch.furniture.label },
                  { label: t("Depth"), value: merch.depth.label },
                  { label: t("Width"), value: merch.width.label },
                  {
                    label: t("Configuration"),
                    value: merch.configuration.label,
                  },
                  // { label: t("Price"), value: formatAmount(merch.price) },
                ].map((item, index) => {
                  return (
                    <Grid item key={`prop-${index}`}>
                      <Grid container>
                        <Grid item sx={{ width: "175px" }}>
                          <Typography sx={{ fontStyle: "italic" }}>
                            {item.label}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ flex: 1 }}>
                          <Typography> {item.value}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            {merch.files && merch.files.length > 0 && (
              <Grid item>
                <ChipFileList
                  files={linq
                    .from(merch.files)
                    .select((o) => o.file)
                    .toArray()}
                />
              </Grid>
            )}
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant="h6">{t("Products")}</Typography>
              <Grid container flexDirection={"column"}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item sx={{ width: "400px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("Label")}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ width: "100px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("Weigth")}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ flex: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("Quantity")}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ flex: 1, textAlign: "right" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("Price")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {merch.merchProducts.map((merchProduct, index) => {
                  return (
                    <Grid item key={`merchProduct-${index}`}>
                      <Grid container spacing={2}>
                        <Grid item sx={{ width: "400px" }}>
                          <Typography sx={{ fontStyle: "italic" }}>
                            {merchProduct.product.label}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ width: "100px", textAlign: "right" }}>
                          <Typography>
                            {formatWeight(merchProduct.product.weight)}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ flex: 1 }}>
                          <Typography> {merchProduct.quantity}</Typography>
                        </Grid>
                        <Grid item sx={{ flex: 1, textAlign: "right" }}>
                          <Typography>
                            {formatAmount(merchProduct.product.price)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item sx={{ width: "100%", textAlign: "right" }}>
              <Typography variant={"h5"}>
                {t("Total")}:{" "}
                {formatAmount(
                  linq
                    .from(merch.merchProducts)
                    .sum((o) => o.product.price * o.quantity)
                )}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);

export default ViewModal;
