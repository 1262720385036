import { Button, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IRolePermission } from "types/IRole";
import { getRolePermissions, updateRolePermissions } from "api/role";
import DataGrid from "react-data-grid";
import linq from "linq";
import { useTranslation } from "react-i18next";
import { getResponseExceptionMessage } from "api/apiSettings";
import { toast } from "react-toastify";

const RolePermissions = () => {
  const classes = useStyles();
  const [rolePermissions, setRolePermissions] = useState<IRolePermission[]>([]);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    (async function () {
      try {
        const apiResult = await toast.promise(getRolePermissions(), {
          pending: {
            render() {
              return t("common|Loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.success && apiResult.result) {
          setRolePermissions(apiResult.result);
        }
      } catch (error) {}
    })();
  }, [t]);

  const roleNames = useMemo(
    () =>
      linq
        .from(rolePermissions)
        .select((o) => o.roleName)
        .distinct()
        .toArray(),
    [rolePermissions]
  );

  const permissionNames = useMemo(
    () =>
      linq
        .from(rolePermissions)
        .select((o) => o.permissionName)
        .distinct()
        .toArray(),
    [rolePermissions]
  );

  const updateRolePermission = useCallback(
    (permissionName: string, roleName: string) => {
      const newRolePermissions = [...rolePermissions];
      const rolePermission = newRolePermissions.find(
        (o) => o.permissionName === permissionName && o.roleName === roleName
      );
      if (rolePermission) {
        rolePermission.isGranted = !rolePermission.isGranted;
        setRolePermissions(newRolePermissions);
      } else {
        alert("Erreur");
      }
    },
    [rolePermissions]
  );

  const rows: any[] = useMemo(() => {
    let result: any[] = [];
    permissionNames.forEach((permissionName, permissionIndex) => {
      let row = {
        id: permissionIndex,
        permissionName: permissionName,
      } as any;
      roleNames.forEach((roleName) => {
        row[roleName] = linq
          .from(rolePermissions)
          .where(
            (o) =>
              o.permissionName === permissionName && o.roleName === roleName
          )
          .firstOrDefault()?.isGranted;
      });
      result.push(row);
    });
    return linq
      .from(result)
      .orderBy((o) => o.permissionName)
      .toArray();
  }, [permissionNames, roleNames, rolePermissions]);

  const columns: any[] = useMemo(() => {
    let result = [];
    result.push({
      key: "permissionName",
      name: "Permission",
      width: 300,
    });
    linq.from(roleNames).forEach((roleName) => {
      result.push({
        key: roleName,
        name: roleName,
        width: 100,
        formatter: ({ row }: { row: any }) => {
          return (
            <input
              type="checkbox"
              checked={row[roleName]}
              onChange={() =>
                updateRolePermission(row.permissionName, roleName)
              }
            />
          );
        },
      } as any);
    });
    return result;
  }, [roleNames, updateRolePermission]);

  const handleSaveRolePermissions = useCallback(async () => {
    try {
      const apiResult = await toast.promise(
        updateRolePermissions(rolePermissions),
        {
          pending: t("common|processing"),
          success: t("common|processing.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        }
      );
      if (apiResult?.success && apiResult.result) {
        setRolePermissions(apiResult.result);
      }
    } catch (error) {}
  }, [rolePermissions, t]);

  return (
    <div className={classes.container}>
      <div className={classes.dataGridContainer}>
        <DataGrid rows={rows} columns={columns} />
      </div>
      <div className={classes.submitButtonContainer}>
        <Button
          color="primary"
          // fullWidth
          size="large"
          variant="contained"
          onClick={handleSaveRolePermissions}
        >
          {t("common|Valider")}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
    },
    dataGridContainer: {
      flex: 1,
      "& > div": {
        height: "100%",
      },
    },
    submitButtonContainer: {
      padding: "20px 0px",
      textAlign: "right",
    },
  })
);

export default RolePermissions;
// RolePermissions.whyDidYouRender = {
//   logOnDifferentValues: false,
//   customName: "RolePermissions",
// };
