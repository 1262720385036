import { Download } from "@mui/icons-material";
import { Chip, Grid, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IOrder } from "api/order";
import { getPdfViewerUrl, getImageViewerUrl } from "helpers/files";
import { hasOneOfRoles } from "helpers/user";
import linq from "linq";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import ChipFileList from "./ChipFileList";

interface IProps {
  order: IOrder;
  showMerchFiles?: boolean;
}

export default function OrderSummary(props: IProps) {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const { order, showMerchFiles = true } = props;
  const { session } = useSelector((state: RootState) => state.auth);

  const orderFormUrl = useMemo(() => {
    return getPdfViewerUrl(order.orderFormId);
  }, [order.orderFormId]);

  const orderFormUrlSigned = useMemo(() => {
    if (order.orderFormSignedId) {
      return order.signature && order.signature !== ""
        ? getPdfViewerUrl(order.orderFormSignedId)
        : getImageViewerUrl(order.orderFormSignedId ?? "");
    }
    return undefined;
  }, [order.orderFormSignedId, order.signature]);

  const deliveryFormUrl = useMemo(() => {
    return getPdfViewerUrl(order.deliveryFormId);
  }, [order.deliveryFormId]);

  const markupUrl = useMemo(() => {
    return getPdfViewerUrl(order.markupFileId);
  }, [order.markupFileId]);

  const handleOpenFile = useCallback((url?: string) => {
    if (!url) {
      return;
    }
    window.open(url, "blank");
  }, []);

  const canSeeOrderForms = useMemo(() => {
    // TODO: use a permission or check from server side
    return hasOneOfRoles(session.user, ["Admin", "AdminPLV"]);
  }, [session.user]);

  return (
    <Grid container flexDirection="column" className={classes.container}>
      <Grid item>
        <Typography variant="h4">
          {t("Order reference")}: {order.reference}
        </Typography>
      </Grid>
      <Grid item sx={{ marginTop: "10px" }}>
        <Typography variant="h6">
          {order.storeReference}
          <br />
          {order.storeLabel}
          <br />
          {order.address} {order.postalCode}
          <br />
          {order.city}
        </Typography>
      </Grid>
      <Grid item sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          {canSeeOrderForms && (
            <>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrl)}
                  label={t("See order form")}
                  variant="outlined"
                  disabled={orderFormUrl ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrlSigned)}
                  label={t("See signed order form")}
                  variant="outlined"
                  disabled={orderFormUrlSigned ? false : true}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Chip
              icon={<Download />}
              onClick={() => handleOpenFile(deliveryFormUrl)}
              label={t("See delivery form")}
              variant="outlined"
              disabled={deliveryFormUrl ? false : true}
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<Download />}
              onClick={() => handleOpenFile(markupUrl)}
              label={t("See markup")}
              variant="outlined"
              disabled={markupUrl ? false : true}
            />
          </Grid>
        </Grid>
      </Grid>
      {showMerchFiles && order.merch?.files && order.merch?.files.length > 0 && (
        <Grid item sx={{ marginTop: "10px" }}>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item>
              <Typography>{t("Merch files")}</Typography>
            </Grid>
            <Grid item>
              <ChipFileList
                files={linq
                  .from(order.merch?.files)
                  .select((o) => o.file)
                  .toArray()}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);
