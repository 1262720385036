import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { defaultEntity, service } from "api/factory";
import { toast } from "react-toastify";
import { hasPermission } from "helpers/user";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getResponseExceptionMessage } from "api/apiSettings";
import UserSelector from "components/UserSelector";
import { IUser } from "types/IUser";
import linq from "linq";

const Edit = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [entity, setEntity] = useState(defaultEntity);
  const { session } = useSelector((state: RootState) => state.auth);
  const { factoryId } = useParams();

  useEffect(() => {
    (async function () {
      if (factoryId) {
        const apiResult = await toast.promise(service.get({ id: factoryId }), {
          pending: {
            render() {
              return t("loading");
            },
          },
          // success: t("loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.result?.id) {
          setEntity(apiResult.result);
        }
      }
    })();
  }, [factoryId, t]);

  const validationSchema = yup.object({
    reference: yup
      .string()
      .required(t("field_name.required", { field_name: t("Reference") })),
    label: yup
      .string()
      .required(t("field_name.required", { field_name: t("Label") })),
  });

  const formik = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      await toast.promise(service.update(values), {
        pending: t("processing"),
        success: t("processing.success"),
        error: t("processing.error"),
      });
    },
  });

  const {
    handleSubmit,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = formik;

  const initialUsers = useMemo(() => {
    return entity.users?.length
      ? linq
          .from(entity.users)
          .select((o) => o.user)
          .toArray()
      : [];
  }, [entity.users]);

  const handleUsersChange = useCallback(
    (users: IUser[]) => {
      const factoryUsers = users.map((user) => {
        return { user: user, userId: user.id };
      });
      setFieldValue("users", factoryUsers || []);
    },
    [setFieldValue]
  );

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="isActive"
                  onChange={handleChange}
                  checked={values.isActive}
                  color="primary"
                />
              }
              label={t("Enabled")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Edit factory")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            {hasPermission(session.user, "Factory.Update") === true && (
              <Button
                color="primary"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                type="submit"
              >
                {t("button.validate")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Reference")}
              name="reference"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.reference}
              error={touched.reference && Boolean(errors.reference)}
              helperText={touched.reference && errors.reference}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Label")}
              name="label"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.label}
              error={touched.label && Boolean(errors.label)}
              helperText={touched.label && errors.label}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Weight")}
              name="weight"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.weight}
              error={touched.weight && Boolean(errors.weight)}
              helperText={touched.weight && errors.weight}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserSelector
              key="roleSelector"
              onChange={handleUsersChange}
              initialValues={initialUsers}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default Edit;
