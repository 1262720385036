import AbpAppService from "api/AbpAppService";
import { IAbpGetList, IAbpResponse, IAbpResponseGet } from "types/IApi";
import { IRole, IRolePermission } from "types/IRole";

const appService = new AbpAppService<any, any>("Role");

export const getAsync = async (id: number): Promise<IAbpResponseGet<IRole>> => {
  return await appService.get({ id: id });
};

export const getAllAsync = async (
  params = {}
): Promise<IAbpResponse<IAbpGetList<IRole>>> => {
  return await appService.getAll(params);
};

export const createAsync = async (
  params: IRole
): Promise<IAbpResponseGet<IRole>> => {
  return await appService.create(params);
};

export const updateAsync = async (
  params: IRole
): Promise<IAbpResponseGet<IRole>> => {
  return await appService.update(params);
};

export const deleteEntityAsync = async (
  id: string
): Promise<IAbpResponse<any>> => {
  return await appService.delete({ id: id });
};

export const getRolePermissions = async (
  params = {}
): Promise<IAbpResponse<IRolePermission[]>> => {
  return await appService.invoke("get", "GetRolePermissions", params);
};

export const updateRolePermissions = async (params: {}): Promise<
  IAbpResponse<IRolePermission[]>
> => {
  return await appService.invoke("put", "UpdateRolePermissions", params);
};

export enum RoleNames {
  Admin = "Admin",
  GL = "GL",
  RRS = "RRS",
  CVR = "CVR",
  DZ = "DZ",
  Merch = "Merch",
}
