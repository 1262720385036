import React, { useState } from "react";
import {
  Alert,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import backgroundImage from "images/login_background.jpg";
import logo from "images/logo.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { resetPassword } from "api/account";
import * as ApiSettings from "../../api/apiSettings";
import QueryString from "qs";

const ResetPassword = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [error, setError] = useState("");

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "8 caractères minimum")
      .required("Mot de passe requis"),
    confirmPassword: yup
      .string()
      .required("Confirmation du mot de passe requis")
      .oneOf(
        [yup.ref("password"), null],
        "Confirmation du mot de passe incorrect"
      ),
  });

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        const params = QueryString.parse(window.location.search, {
          ignoreQueryPrefix: true,
        }) as { userId: string; token: string };
        const resetPasswordApi = await resetPassword(
          params.userId,
          params.token,
          values.password
        );
        if (resetPasswordApi?.result.passwordReset === true) {
          navigate("/login");
        } else {
          setError("Erreur lors de la mise à jour du mot de passe.");
        }
      } catch (error) {
        let errorContent = ApiSettings.getResponseExceptionMessage(error);
        setError(errorContent);
      }
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.containerBackground}></div>
      <div className={classes.containerBackgroundImage}></div>
      <div className={classes.contentContainer}>
        <img src={logo} className={classes.logo} alt="" />
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.formContainer}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            Mise à jour du mot de passe
          </Typography>
          <Grid
            container
            spacing={smallScreen ? 0 : 2}
            style={{ minWidth: smallScreen ? 250 : 540 }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mot de passe"
                margin="normal"
                name="password"
                required
                type="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirmation du mot de passe"
                margin="normal"
                name="confirmPassword"
                required
                type="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
          {error && error !== "" && <Alert severity="error">{error}</Alert>}
          {formik.isSubmitting && (
            <Alert severity="info">Mise à jour en cours...</Alert>
          )}
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Valider
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
    },
    containerBackground: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: theme.palette.secondary.main,
    },
    containerBackgroundImage: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    contentContainer: {
      position: "relative",
      margin: "0 auto", // auto auto to center both vertically / horizontally
      textAlign: "center",
      padding: 10,
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "white",
      borderRadius: "10px",
      boxShadow: "0 0 10px #000",
      borderColor: theme.palette.primary.main,
      border: 2,
      borderStyle: "solid",
    },
    submitButtonContainer: {
      marginTop: 20,
    },
  })
);
export default ResetPassword;
// Registration.whyDidYouRender = {
//   logOnDifferentValues: false,
//   customName: "Registration",
// };
