import {
  Cancel,
  Check,
  Delete,
  Info,
  QuestionMark,
  Send,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  RetailOrderStatusEnum,
  RetailOrderStatusLabels,
  service,
} from "api/retailOrder";
import { formatAmount, formatDate, isNullOrUndefined } from "helpers/tools";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCommentAsync, updateState } from "redux/reducers/retailOrder";
import { RootState, useAppDispatch } from "redux/store";
import Products from "routes/retailOrders/Steps/Products";
import Shipping from "routes/retailOrders/Steps/Shipping";
import Signature from "./Steps/Signature";
import Store from "./Steps/Store";
import Summary from "./Steps/Summary";
import useHelper from "hooks/useHelper";
import { hasOneOfRoles } from "helpers/user";
import ConfirmModal, { IConfirmModal } from "components/ConfirmModal";
import { getResponseExceptionMessage } from "api/apiSettings";
import { ModalContext } from "contexts/ModalContext";

const ProcessManager = () => {
  const classes = useStyles();
  const { getTotal } = useHelper();
  const { addModal } = useContext(ModalContext);
  const { t } = useTranslation(["common"]);
  let navigate = useNavigate();
  const { session } = useSelector((state: RootState) => state.auth);
  const { id, busy, error, step, store, products, retailOrder } = useSelector(
    (state: RootState) => state.retailOrder
  );
  const appDispatch = useAppDispatch();
  let [comment, setComment] = useState("");

  const steps = useMemo(
    () =>
      [
        {
          label: t("Store selection"),
          component: <Store />,
          stepOptionalText: store?.id ? (
            `${store?.label} - ${store?.city}`
          ) : (
            <QuestionMark />
          ),
        },
        {
          label: t("Product selection"),
          component: <Products />,
          stepOptionalText: products?.length
            ? `${products?.length} ${t("product", { count: products?.length })}`
            : "",
        },
        {
          label: t("Shipping Cost"),
          component: <Shipping />,
        },
        {
          label: t("Signature"),
          component: <Signature />,
          stepOptionalText:
            retailOrder.status === RetailOrderStatusEnum.Done ? (
              <Check />
            ) : retailOrder.status === RetailOrderStatusEnum.Canceled ? (
              <Cancel />
            ) : (
              <QuestionMark />
            ),
        },
        {
          label: t("Summary"),
          component: <Summary />,
        },
      ] as { label: string; component: JSX.Element; stepOptionalText: any }[],
    [
      t,
      store?.id,
      store?.label,
      store?.city,
      products?.length,
      retailOrder.status,
    ]
  );
  const canDelete = useMemo(() => {
    return (
      retailOrder.id &&
      retailOrder.status &&
      retailOrder.status <= 6 &&
      (hasOneOfRoles(session.user, ["Merch", "Admin", "AdminPLV"]) ||
        retailOrder.creatorUser?.id === session.user.id)
    );
  }, [
    retailOrder.id,
    retailOrder.status,
    session.user.roles,
    session.user.id,
    retailOrder.creatorUser?.id,
  ]);
  const sidebarTitle = useMemo(
    () =>
      retailOrder.reference ? `N°${retailOrder.reference}` : t("New order"),
    [retailOrder.reference, t]
  );

  const creationDate = useMemo(
    () =>
      retailOrder.creationTime
        ? formatDate(retailOrder.creationTime, "DD/MM/YYYY HH:mm")
        : formatDate(new Date().toISOString(), "DD/MM/YYYY HH:mm"),
    [retailOrder.creationTime]
  );

  const creatorName = useMemo(
    () =>
      retailOrder.creatorUser
        ? `${retailOrder.creatorUser.name} ${retailOrder.creatorUser.surname}`
        : `${session.user.name} ${session.user.surname}`,
    [retailOrder.creatorUser, session.user.name, session.user.surname]
  );

  const total = useMemo(() => {
    return getTotal(products, true);
  }, [getTotal, products]);

  const canAddComment = useMemo(() => {
    return (
      retailOrder.status === RetailOrderStatusEnum.Signature ||
      retailOrder.status === RetailOrderStatusEnum.Shipping ||
      retailOrder.status === RetailOrderStatusEnum.Delivering
    );
  }, [retailOrder.status]);

  const statuslineContent = useMemo(() => {
    return retailOrder.statusLines
      ? retailOrder.statusLines.map((statusLine) => {
          return (
            <Grid item key={statusLine.id} className={classes.statusLine}>
              <Typography variant={"caption"}>
                <strong>
                  {formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm")}
                </strong>{" "}
                ({statusLine.creatorUser.emailAddress}) -{" "}
                {RetailOrderStatusLabels[statusLine.status]}
              </Typography>
              {statusLine.comment && statusLine.comment !== "" ? (
                <Typography variant={"caption"} className={classes.multilines}>
                  {" - "}
                  {statusLine.comment}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          );
        })
      : [];
  }, [classes.multilines, classes.statusLine, retailOrder.statusLines]);
  const handleDeleteClick = () => {
    const modal: IConfirmModal = {
      component: ConfirmModal,
      props: {
        title: t("Delete order ?"),
        children: <></>,
        onConfirm: async () => {
          if (retailOrder.id) {
            const apiResult = await toast.promise(
              service.delete({
                id: retailOrder.id,
              }),
              {
                pending: {
                  render() {
                    return t("deleting");
                  },
                },
                // success: t("loading.success"),
                error: {
                  render({ data }: any) {
                    return getResponseExceptionMessage(data);
                  },
                  autoClose: false,
                },
              }
            );
            if (apiResult?.success) {
              navigate("/retailorders");
            }
          }
        },
      },
    };
    addModal(modal);
  };
  const handleAddComment = useCallback(() => {
    if (id && id !== "" && comment && comment !== "") {
      appDispatch(addCommentAsync({ retailOrderId: id, comment: comment }));
      setComment("");
    }
  }, [appDispatch, comment, id]);

  // handle busy toast
  const loadingToastId = useRef<any>(null);
  useEffect(() => {
    if (busy === true && !loadingToastId.current) {
      loadingToastId.current = toast(t("common|loading"), {
        isLoading: true,
        type: toast.TYPE.INFO,
        autoClose: false,
      });
    }
    if (busy === false && loadingToastId.current) {
      toast.dismiss(loadingToastId.current);
      loadingToastId.current = null;
    }
  }, [busy, t]);

  // handle error toast
  useEffect(() => {
    if (!isNullOrUndefined(error)) {
      toast(error, {
        type: toast.TYPE.ERROR,
        autoClose: false,
      });
      appDispatch(updateState({ error: undefined }));
    }
  }, [appDispatch, error, t]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "row",
        padding: "15px",
        flex: 1,
      }}
      spacing={3}
    >
      <Grid item xs={12} lg={9}>
        <Grid
          container
          sx={{
            flexDirection: "column",
            height: "100%",
            flexWrap: "nowrap",
          }}
        >
          <Grid item>
            <Stepper alternativeLabel activeStep={step}>
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    sx={{ textAlign: "center" }}
                    optional={
                      <Typography variant="caption">
                        {step.stepOptionalText}
                      </Typography>
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            {steps[step].component}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper elevation={2}>
          <Grid
            container
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Grid item>
              <Grid container sx={{ flexDirection: "column" }}>
                <Grid item sx={{ textAlign: "center", padding: "5px" }}>
                  <Typography variant="h6">{sidebarTitle}</Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <List>
                    <ListItem dense key={t("Creation date")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Creation date")}
                        secondary={creationDate}
                      />
                    </ListItem>
                    <ListItem dense key={t("Created by")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Created by")}
                        secondary={creatorName}
                      />
                    </ListItem>
                  </List>
                </Grid>
                {canDelete && (
                  <Grid item sx={{ textAlign: "center", padding: "10px" }}>
                    <Button
                      startIcon={<Delete />}
                      color="error"
                      variant="outlined"
                      onClick={handleDeleteClick}
                    >
                      {t("button.delete")}
                    </Button>
                  </Grid>
                )}
                <Grid item sx={{ padding: "10px" }}>
                  <Typography sx={{ fontStyle: "italic" }}>
                    {t("History")}
                  </Typography>
                  {canAddComment && (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("Comment")}
                      name="comment"
                      type="text"
                      inputProps={{
                        autoComplete: "new-comment",
                      }}
                      variant="outlined"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={handleAddComment}>
                            <Send />
                          </IconButton>
                        ),
                      }}
                      sx={{ marginTop: "10px" }}
                    />
                  )}
                  <Grid
                    container
                    sx={{
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: "250px",
                      width: "100%",
                      overflowY: "scroll",
                      marginTop: "10px",
                      flexWrap: "nowrap",
                    }}
                  >
                    {statuslineContent}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
            <Grid item sx={{ textAlign: "right", padding: "10px" }}>
              <Typography variant="h5">
                {t("Total")}: {formatAmount(total)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusLine: {
      width: "95%",
      borderBottom: "1px solid lightgrey",
    },
    multilines: {
      whiteSpace: "break-spaces",
    },
  })
);
export default ProcessManager;
