import { accessTokenName, encryptedAccessTokenName } from "api/authentication";
import { deleteCookie, getCookie } from "helpers/cookie";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadSessionAsync } from "redux/actions/auth";
import { reset } from "redux/reducers/auth";
import { RootState, useAppDispatch } from "redux/store";
import Home from "routes/app/Home";
import { ILoadingType } from "types/ILoadingType";
import LoadingScreen from "../../components/LoadingScreen";

const ScreenManager = () => {
  const { loadSessionStatus, session, isSessionOpened, isReset } = useSelector(
    (state: RootState) => state.auth
  );
  const appDispatch = useAppDispatch();
  let navigate = useNavigate();

  const logoutAndRedirect = useCallback(() => {
    deleteCookie(accessTokenName);
    deleteCookie(encryptedAccessTokenName);
    appDispatch(reset());
    navigate("/login");
  }, [appDispatch, navigate]);

  const accessToken = useMemo(() => getCookie(accessTokenName), []);

  // Try to authenticate user with current accessToken
  useEffect(() => {
    (async function () {
      if (accessToken && accessToken !== "undefined") {
        await appDispatch(loadSessionAsync());
      } else {
        logoutAndRedirect();
      }
    })();
  }, [appDispatch, accessToken, logoutAndRedirect]);

  // Authentication reset or missing user data on load session success, logout & redirect
  useEffect(() => {
    if (
      isReset ||
      loadSessionStatus === ILoadingType.failed ||
      (loadSessionStatus === ILoadingType.succeeded && !session?.user)
    ) {
      logoutAndRedirect();
    }
  }, [isReset, loadSessionStatus, logoutAndRedirect, session?.user]);

  return isSessionOpened === false ? <LoadingScreen /> : <Home />;
};

export default ScreenManager;
