import AbpAppService from "api/AbpAppService";
import { IAbpGetList, IAbpResponse, IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";

const appService = new AbpAppService<any, any>("AuditLog");

export const getAsync = async (id: number): Promise<IAbpResponseGet<IUser>> => {
  return await appService.get({ id: id });
};

export const getAllAsync = async (
  params = {}
): Promise<IAbpResponse<IAbpGetList<IUser>>> => {
  return await appService.getAll(params);
};

export const createAsync = async (
  params: IUser
): Promise<IAbpResponseGet<IUser>> => {
  return await appService.create(params);
};

export const updateAsync = async (
  params: IUser
): Promise<IAbpResponseGet<IUser>> => {
  return await appService.update(params);
};

export const deleteEntityAsync = async (
  id: string
): Promise<IAbpResponse<any>> => {
  return await appService.delete({ id: id });
};
