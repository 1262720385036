import {
  Box,
  Grid,
  MenuItem,
  Popper,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MuiReactTable, { IFilter } from "components/MuiReactTable";
import ConfirmModal, { IConfirmModal } from "components/ConfirmModal";
import { ModalContext } from "contexts/ModalContext";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { selectFormById, setForm } from "redux/reducers/forms";
import { checkDate, formatDate } from "helpers/tools";
import linq from "linq";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  IOrder,
  OrderStatusColors,
  OrderStatusEnum,
  OrderStatusLabels,
  service,
} from "api/order";
import { getResponseExceptionMessage } from "api/apiSettings";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { $enum } from "ts-enum-util";
import { hasOneOfRoles } from "helpers/user";
import { IUser } from "types/IUser";

const FORM_ID = "orders"; // used to save filter values

function weekDateFormat(date: string) {
  return formatDate(date, "YYYY-WW");
}

const List = () => {
  const classes = useStyles();
  const { addModal } = useContext(ModalContext);
  const { t } = useTranslation(["common", "components/muireacttable"]);
  let navigate = useNavigate();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);
  const authStore = useSelector((state: RootState) => state.auth);

  // #region Popper handlers start
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperContent, setPopperContent] = useState<any>(null);

  const handleMouseEnter = useCallback((event: any, order: IOrder) => {
    setAnchorEl(event.currentTarget);
    let content = linq
      .from(order.orderStatusLines)
      .orderByDescending((o) => o.creationTime)
      .toArray()
      .map((statusLine) => (
        <li key={statusLine.id}>
          <Typography>
            {formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm")} -{" "}
            {OrderStatusLabels[statusLine.orderStatus]}
          </Typography>
        </li>
      ));
    setPopperContent(
      <div>
        {/* <Typography variant="h5">Historique</Typography> */}
        <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>{content}</ul>
      </div>
    );
  }, []);
  const handleMouseLeave = useCallback((event: any) => {
    setAnchorEl(null);
    setPopperContent(null);
  }, []);
  const open = Boolean(anchorEl);
  const id = open
    ? (anchorEl as any)?.getAttribute("aria-describedby")
    : undefined;
  // #endregion Popper handlers end

  const [listFilters, setListFilters] = useState<{
    loadingDateStart: Moment | null;
    loadingDateEnd: Moment | null;
  }>({
    loadingDateStart: moment().startOf("year"),
    loadingDateEnd: moment().add(3, "months").endOf("month"),
  });

  const loadOrders = useCallback(async () => {
    const apiResult = await toast.promise(
      service.getAll({
        LoadingDateStart: listFilters.loadingDateStart?.toDate(),
        LoadingDateEnd: listFilters.loadingDateEnd?.toDate(),
      }),
      {
        pending: {
          render() {
            return t("loading");
          },
        },
        // success: t("loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      }
    );
    if (apiResult?.success && apiResult.result) {
      setOrders(apiResult.result.items);
    }
  }, [t, listFilters]);

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  // const handleDeleteClick = (data: IOrder) => {
  //   const modal: IConfirmModal = {
  //     component: ConfirmModal,
  //     props: {
  //       title: t("Delete order ?"),
  //       children: <></>,
  //       onConfirm: async () => {
  //         if (data.id) {
  //           const apiResult = await toast.promise(
  //             service.delete({
  //               id: data.id,
  //             }),
  //             {
  //               pending: {
  //                 render() {
  //                   return t("deleting");
  //                 },
  //               },
  //               // success: t("loading.success"),
  //               error: {
  //                 render({ data }: any) {
  //                   return getResponseExceptionMessage(data);
  //                 },
  //                 autoClose: false,
  //               },
  //             }
  //           );
  //           if (apiResult?.success) {
  //             loadOrders();
  //           }
  //         }
  //       },
  //     },
  //   };
  //   addModal(modal);
  // };

  const tableColumns = useMemo(
    () => [
      {
        Header: t("column.week"),
        accessor: "week",
        Cell: ({ row: { original } }: { row: { original: IOrder } }) => {
          return original.loadingDate
            ? weekDateFormat(original.loadingDate)
            : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.loadingDate
            ? weekDateFormat(row.original.loadingDate)
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          if (!filterValue || filterValue === "") {
            return rows;
          }
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            const weekDateFormatted = weekDateFormat(rowOrder.loadingDate);
            return weekDateFormatted.includes(filterValue);
          });
        },
      },
      { Header: t("column.reference"), accessor: "reference" },
      {
        Header: t("column.orderStatus"),
        accessor: "orderStatus",
        style: {
          minWidth: "200px",
          maxWidth: "200px", // this is not working properly but at least the column is larger than default
        },
        //filter: "equals",
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            const rowOrder: any = row.original;
            if ((filterValue as number) > 0) {
              return rowOrder.orderStatus === filterValue;
            }
            return true;
          });
        },
        Cell: ({ row: { original } }: { row: { original: IOrder } }) => {
          return (
            <Grid
              container
              sx={{
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
              spacing={1}
              aria-describedby={original.reference}
              onMouseEnter={(event) => handleMouseEnter(event, original)}
              onMouseLeave={handleMouseLeave}
            >
              <Grid item>
                <RemoveRedEye />
              </Grid>
              <Grid item>{OrderStatusLabels[original.orderStatus]}</Grid>
            </Grid>
          );
        },
        getCellExportValue: (row: any, column: any) => {
          return (
            OrderStatusLabels[row.original.orderStatus as OrderStatusEnum] || ""
          );
        },
        Filter: ({ column: { filterValue = 0, setFilter } }: any) => {
          let menuItems: any = $enum(OrderStatusEnum)
            .getValues()
            .map((value) => {
              return (
                <MenuItem key={value} value={Number(value)}>
                  {OrderStatusLabels[value]}
                </MenuItem>
              );
            });
          return (
            <Select
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value || 0);
              }}
              value={filterValue}
              sx={{ height: "40px" }}
            >
              <MenuItem value={0}>Tous</MenuItem>
              {menuItems}
            </Select>
          );
        },
      },
      {
        Header: t("column.name"),
        accessor: "creatorUser.fullName",
      },
      { Header: t("column.storeReference"), accessor: "storeReference" },
      { Header: t("column.storeLabel"), accessor: "storeLabel" },
      { Header: t("column.address"), accessor: "address" },
      { Header: t("column.city"), accessor: "city" },
      { Header: t("column.postalCode"), accessor: "postalCode" },
      { Header: t("column.company"), accessor: "companyLabel" },
      { Header: t("column.linearGain"), accessor: "linearGain" },
      {
        Header: t("column.lastImplantation"),
        accessor: "lastImplantation",
        Cell: ({ value }: { value: string }) => {
          return value ? formatDate(value, "DD/MM/YYYY") : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.lastImplantation
            ? formatDate(row.original.lastImplantation, "DD/MM/YYYY")
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            return checkDate(filterValue, rowOrder.lastImplantation);
          });
        },
      },

      { Header: t("column.furniture"), accessor: "furnitureLabel" },
      { Header: t("column.depth"), accessor: "depthLabel" },
      { Header: t("column.configuration"), accessor: "configurationLabel" },
      { Header: t("column.merch"), accessor: "merchLabel" },
      { Header: t("column.cip"), accessor: "merchCIP" },
      {
        Header: t("column.creationDate"),
        accessor: "creationTime",
        Cell: ({ value }: { value: string }) => {
          return value ? formatDate(value, "DD/MM/YYYY HH:mm") : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.creationTime
            ? formatDate(row.original.creationTime, "DD/MM/YYYY HH:mm")
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            return checkDate(filterValue, rowOrder.creationTime);
          });
        },
      },
      {
        Header: t("column.expectedDeliveryDate"),
        accessor: "expectedDeliveryDate",
        Cell: ({ value }: { value: string }) => {
          return value ? formatDate(value, "DD/MM/YYYY") : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.expectedDeliveryDate
            ? formatDate(row.original.expectedDeliveryDate, "DD/MM/YYYY")
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            return checkDate(filterValue, rowOrder.expectedDeliveryDate);
          });
        },
      },
      {
        Header: t("column.completionDate"),
        accessor: "completionDate",
        Cell: ({ value }: { value: string }) => {
          return value ? formatDate(value, "DD/MM/YYYY HH:mm") : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.completionDate
            ? formatDate(row.original.completionDate, "DD/MM/YYYY HH:mm")
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            return checkDate(filterValue, rowOrder.completionDate);
          });
        },
      },
      {
        Header: t("column.loadingDate"),
        accessor: "loadingDate",
        Cell: ({ value }: { value: string }) => {
          return value ? formatDate(value, "DD/MM/YYYY HH:mm") : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.loadingDate
            ? formatDate(row.original.loadingDate, "DD/MM/YYYY HH:mm")
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            return checkDate(filterValue, rowOrder.loadingDate);
          });
        },
      },
      {
        Header: t("column.deliveryDate"),
        accessor: "deliveryDate",
        Cell: ({ value }: { value: string }) => {
          return value ? formatDate(value, "DD/MM/YYYY HH:mm") : "";
        },
        getCellExportValue: (row: any, column: any) => {
          return row.original.deliveryDate
            ? formatDate(row.original.deliveryDate, "DD/MM/YYYY HH:mm")
            : "";
        },
        filter: (rows: any, id: any, filterValue: string) => {
          return rows.filter((row: any) => {
            const rowOrder: IOrder = row.original;
            return checkDate(filterValue, rowOrder.deliveryDate);
          });
        },
      },
      // ...(canDelete
      //   ? [
      //       {
      //         Header: t("delete"),
      //         accessor: "delete",
      //         Cell: ({ row: { original } }: { row: { original: IOrder } }) => {
      //           return (
      //             original.orderStatus <= 6 && (canDelete || authStore.session.user.id === original.creatorUser?.id)  && ( // ou <=2 ?
      //               <Delete
      //                 onClick={(e) => {
      //                   handleDeleteClick(original);
      //                   e.stopPropagation();
      //                 }}
      //               />
      //             )
      //           );
      //         },
      //       },
      //     ]
      //   : []),
    ],
    [handleMouseEnter, handleMouseLeave, t]
  );

  const handleRowClick = (row: any, data: IOrder, event: any) => {
    navigate(`/orders/${data.id}`);
  };

  const handleAddClick = () => {
    navigate(`/orders/create`);
  };

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  const getRowStyle = (row: any, data: IOrder) => {
    let styles = {};
    if (data.orderStatus) {
      styles = {
        borderLeft: `10px solid ${OrderStatusColors[data.orderStatus]}`,
      };
    }
    return styles;
  };

  const tableHeader = ({ setFilter, setGlobalFilter, globalFilter }: any) => {
    const datePicker = (
      <Box sx={{ marginBottom: "20px" }}>
        <DesktopDatePicker
          label="Date de chargement du"
          inputFormat="DD/MM/yyyy"
          value={listFilters.loadingDateStart}
          onChange={(value) =>
            setListFilters((old) => ({ ...old, loadingDateStart: value }))
          }
          renderInput={(params) => <TextField {...params} />}
        />{" "}
        <DesktopDatePicker
          label="au"
          inputFormat="DD/MM/yyyy"
          value={listFilters.loadingDateEnd}
          onChange={(value) =>
            setListFilters((old) => ({ ...old, loadingDateEnd: value }))
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    );
    return datePicker;
  };

  return (
    <div className={classes.container}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right"
        style={{
          background: "#F8F8F8",
          padding: "10px",
          border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        <Box>{popperContent}</Box>
      </Popper>
      <MuiReactTable
        // autoResetPage={autoResetPage}
        header={tableHeader}
        columns={tableColumns}
        data={orders}
        onAddClick={handleAddClick}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        title={t("Orders")}
        csvExport={true}
        fileName={t("orders")}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
        rowStyle={getRowStyle}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);

export default List;
