import { Edit, PushPin, QuestionMark, TouchApp } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import {
  StepEnum,
  updateAsync,
  updateCompany,
  updateConfiguration,
  updateDepth,
  updateFurniture,
  updateMerch,
  updateStep,
} from "redux/reducers/order";
import { ModalContext } from "contexts/ModalContext";
import ViewModal, { IViewModal } from "routes/catalog/merchs/ViewModal";
import { getResponseExceptionMessage } from "api/apiSettings";
import { service } from "api/merch";
import { IMerch } from "api/merch";
import linq from "linq";
import { toast } from "react-toastify";

const Merch = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const theme = useTheme();
  const appDispatch = useAppDispatch();
  const { addModal } = useContext(ModalContext);
  const orderStore = useSelector((state: RootState) => state.order);
  const { company, configuration, depth, furniture, merch } = useSelector(
    (state: RootState) => state.order
  );

  let [merchList, setMerchList] = useState<IMerch[]>([]);

  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(
        service.getAll({ isActive: true, includeFiles: true }),
        {
          pending: {
            render() {
              return t("common|Loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        }
      );
      if (apiResult?.success && apiResult.result) {
        setMerchList(apiResult.result.items);
      }
    })();
  }, [t]);

  const companies = useMemo(() => {
    const items = linq
      .from(merchList)
      .select((o) => o.company)
      .toArray();

    return [...new Map(items.map((item) => [item.id, item])).values()];
  }, [merchList]);

  const furnitures = useMemo(() => {
    if (company) {
      const items = linq
        .from(merchList)
        .where((o) => o.companyId === company.id)
        .select((o) => o.furniture)
        .toArray();

      return [...new Map(items.map((item) => [item.id, item])).values()];
    }
    return [];
  }, [company, merchList]);

  const depths = useMemo(() => {
    if (company && furniture) {
      const items = linq
        .from(merchList)
        .where((o) => o.companyId === company.id)
        .where((o) => o.furnitureId === furniture.id)
        .select((o) => o.depth)
        .toArray();

      return [...new Map(items.map((item) => [item.id, item])).values()];
    }
    return [];
  }, [company, merchList, furniture]);

  const configurations = useMemo(() => {
    if (company && furniture && depth) {
      const items = linq
        .from(merchList)
        .where((o) => o.companyId === company.id)
        .where((o) => o.furnitureId === furniture.id)
        .where((o) => o.depthId === depth.id)
        .select((o) => o.configuration)
        .toArray();

      return [...new Map(items.map((item) => [item.id, item])).values()];
    }
    return [];
  }, [company, furniture, depth, merchList]);

  const merchs = useMemo(() => {
    if (company && furniture && depth && configuration) {
      const items = linq
        .from(merchList)
        .where((o) => o.companyId === company.id)
        .where((o) => o.furnitureId === furniture.id)
        .where((o) => o.depthId === depth.id)
        .where((o) => o.configurationId === configuration.id)
        .toArray();

      return [...new Map(items.map((item) => [item.id, item])).values()];
    }
    return [];
  }, [company, furniture, depth, configuration, merchList]);

  const merchSteps = [
    {
      title: t("Store selection"),
      items: companies,
    },
    {
      title: t("Furniture selection"),
      items: furnitures,
    },
    {
      title: t("Depth selection"),
      items: depths,
    },
    {
      title: t("Configuration selection"),
      items: configurations,
    },
    {
      title: t("Merch selection"),
      items: merchs,
    },
  ];

  const initialCurrentMerchStep = useMemo(
    () => (merch ? 4 : configuration ? 3 : depth ? 2 : furniture ? 1 : 0),
    [configuration, depth, furniture, merch]
  );

  const [currentMerchStepIndex, setCurrentMerchStepIndex] = useState<number>(
    initialCurrentMerchStep
  );

  const isSeletedItem = useCallback(
    (stepIndex: number, value: any) => {
      switch (stepIndex) {
        case 0:
          return company?.id === value.id;
        case 1:
          return furniture?.id === value.id;
        case 2:
          return depth?.id === value.id;
        case 3:
          return configuration?.id === value.id;
        case 4:
          return merch?.id === value.id;
      }
    },
    [company?.id, configuration?.id, depth?.id, furniture?.id, merch?.id]
  );

  const handleClick = useCallback(
    (stepIndex: number, value: any) => {
      switch (stepIndex) {
        case 0:
          appDispatch(updateCompany(value));
          break;
        case 1:
          appDispatch(updateFurniture(value));
          break;
        case 2:
          appDispatch(updateDepth(value));
          break;
        case 3:
          appDispatch(updateConfiguration(value));
          break;
        case 4:
          appDispatch(updateMerch(value));
          break;
      }
      if (stepIndex < 4) {
        setCurrentMerchStepIndex((old) => old + 1);
      }
    },
    [appDispatch]
  );

  const handleValidate = useCallback(async () => {
    await appDispatch(updateAsync(orderStore));
  }, [appDispatch, orderStore]);

  const stepValues = useMemo(() => {
    const companyStep = (
      <Grid item className={classes.stepValueItem}>
        <Grid container className={classes.captionContainer}>
          <Grid item>
            <Typography variant="caption">{t("Company")}:</Typography>
          </Grid>
          <Grid item sx={{ marginLeft: "5px" }}>
            {company && (
              <span>
                <Typography variant="caption" className={"caption-value"}>
                  {company.label}
                </Typography>
              </span>
            )}
            {!company && <QuestionMark className="icon" />}
          </Grid>
          {company && (
            <Grid item className="icon-container">
              <Edit
                className="icon"
                onClick={() => setCurrentMerchStepIndex(0)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );

    const furnitureStep = furniture && (
      <Grid item className={classes.stepValueItem}>
        <Grid container className={classes.captionContainer}>
          <Grid item>
            <Typography variant="caption">{t("Furniture")}:</Typography>
          </Grid>
          <Grid item sx={{ marginLeft: "5px" }}>
            <span>
              <Typography variant="caption" className={"caption-value"}>
                {furniture.label}
              </Typography>
            </span>
          </Grid>
          {furniture && (
            <Grid item className="icon-container">
              <Edit
                className="icon"
                onClick={() => setCurrentMerchStepIndex(1)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );

    const depthStep = depth && (
      <Grid item className={classes.stepValueItem}>
        <Grid container className={classes.captionContainer}>
          <Grid item>
            <Typography variant="caption">{t("Depth")}:</Typography>
          </Grid>
          <Grid item sx={{ marginLeft: "5px" }}>
            <span>
              <Typography variant="caption" className={"caption-value"}>
                {depth.label}
              </Typography>
            </span>
          </Grid>
          {depth && (
            <Grid item className="icon-container">
              <Edit
                className="icon"
                onClick={() => setCurrentMerchStepIndex(2)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );

    const configurationStep = configuration && (
      <Grid item className={classes.stepValueItem}>
        <Grid container className={classes.captionContainer}>
          <Grid item>
            <Typography variant="caption">{t("Configuration")}:</Typography>
          </Grid>
          <Grid item sx={{ marginLeft: "5px" }}>
            <span>
              <Typography variant="caption" className={"caption-value"}>
                {configuration.label}
              </Typography>
            </span>
          </Grid>
          {configuration && (
            <Grid item className="icon-container">
              <Edit
                className="icon"
                onClick={() => setCurrentMerchStepIndex(3)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );

    return (
      <Grid container spacing={1} sx={{ justifyContent: "center" }}>
        {companyStep}
        {furnitureStep}
        {depthStep}
        {configurationStep}
      </Grid>
    );
  }, [
    classes.captionContainer,
    classes.stepValueItem,
    company,
    configuration,
    depth,
    furniture,
    t,
  ]);

  const handleMerchDetails = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      merchSelected: IMerch
    ) => {
      e.stopPropagation();
      const modal: IViewModal = {
        component: ViewModal,
        props: {
          title: merchSelected.label,
          merch: merchSelected,
        },
      };
      addModal(modal);
    },
    [addModal]
  );

  const isFormInvalid = useMemo(() => {
    return !merch || orderStore.busy;
  }, [merch, orderStore.busy]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {currentMerchStepIndex + 1}.{" "}
                {merchSteps[currentMerchStepIndex].title}
              </Typography>
              {stepValues}
            </Paper>
          </Grid>
          <Grid item sx={{ marginTop: "30px", width: "100%" }}>
            <Grid
              container
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                userSelect: "none",
              }}
              spacing={"30px"}
            >
              {merchSteps[currentMerchStepIndex].items.map((stepItem) => (
                <Grid
                  key={stepItem.id}
                  item
                  sx={{ width: "250px", height: "auto" }}
                  onClick={() => handleClick(currentMerchStepIndex, stepItem)}
                >
                  <Card
                    sx={{
                      position: "relative",
                      width: "100%",
                      textAlign: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {isSeletedItem(currentMerchStepIndex, stepItem) && (
                      <>
                        <PushPin
                          sx={{
                            position: "absolute",
                            top: 5,
                            left: 5,
                            color: theme.palette.primary.main,
                            transform: "rotate(-25deg)",
                          }}
                        />
                      </>
                    )}
                    <TouchApp sx={{ fontSize: "80px" }} />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {stepItem.label}
                      </Typography>
                    </CardContent>
                    {currentMerchStepIndex === 4 && (
                      <CardActions>
                        <Button
                          size="small"
                          sx={{
                            ...(isSeletedItem(
                              currentMerchStepIndex,
                              stepItem.label
                            )
                              ? {
                                  color: theme.palette.secondary.contrastText,
                                }
                              : {}),
                          }}
                          onClick={(e) =>
                            handleMerchDetails(e, stepItem as IMerch)
                          }
                        >
                          Voir détail
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => appDispatch(updateStep(StepEnum.Store))}
            >
              {t("Retour au choix du magasin")}
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={isFormInvalid}
              onClick={handleValidate}
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    item: {
      "& .MuiPaper-root": {
        padding: "20px",
        height: "300px",
        width: "300px",
      },
    },
    stepValueItem: {},
    captionContainer: {
      alignContent: "center",
      "& .caption-value": {
        fontWeight: "bold",
        paddingLeft: "5px",
      },
      "& .icon-container": {
        marginLeft: "5px",
      },
      "& .icon": {
        cursor: "pointer",
      },
    },
  })
);
export default Merch;
