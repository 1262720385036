import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IModal, IModalProps } from "contexts/ModalContext";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

interface IConfirmModalProps extends IModalProps {
  children: string | JSX.Element;
  onConfirm: Function;
  fullScreen?: boolean;
}

export interface IConfirmModal extends IModal {
  props: IConfirmModalProps;
}

const ConfirmModal = (props: IConfirmModalProps) => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const fullScreen = props.fullScreen !== undefined ? props.fullScreen : downMd;

  const handleClose = () => {
    props.dialogProps?.onClose?.();
  };

  const handleConfirm = async () => {
    await props.onConfirm();
    props.dialogProps?.onClose?.();
  };

  return (
    <div className={classes.container}>
      <Dialog
        {...props.dialogProps}
        fullScreen={fullScreen}
        onClose={handleClose}
        transitionDuration={500}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);

export default ConfirmModal;
