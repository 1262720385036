import { Delete, Download } from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  updateStep,
  StepEnum,
  updateSignatureName,
  updateSignature,
  updateAsync,
} from "redux/reducers/order";
import { RootState, useAppDispatch } from "redux/store";
import SignatureCanvas from "react-signature-canvas";
import Dropzone from "components/Dropzone";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IOrder, OrderStatusEnum } from "api/order";
import {
  IMAGE_EXTENSIONS,
  PDF_EXTENSIONS,
  MAX_SIZE,
  ITempFile,
  uploadFile,
  getPdfViewerUrl,
} from "helpers/files";
import { FileWithPath } from "react-dropzone";
import moment from "moment";

const Signature = () => {
  const { t } = useTranslation(["common"]);
  const appDispatch = useAppDispatch();
  const orderStore = useSelector((state: RootState) => state.order);
  const { status, signature, signatureName } = useSelector(
    (state: RootState) => state.order
  );
  const [dropzoneKey, setDropzoneKey] = useState(Date.now());
  const [tempFile, setTempFile] = useState<ITempFile | undefined>(undefined);
  const handleBackToMerch = useCallback(() => {
    appDispatch(updateStep(StepEnum.ExpectedDeliveryDate));
  }, [appDispatch]);

  const handleValidate = useCallback(async () => {
    let uploadedFile = undefined;
    if (tempFile) {
      uploadedFile = await toast.promise(uploadFile(tempFile.file), {
        pending: t("processing.files"),
        success: t("processing.files.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      setDropzoneKey(Date.now());
    }

    const retapp = await appDispatch(
      updateAsync({ ...orderStore, orderFormSignedTemp: uploadedFile })
    );
    if (!(retapp.payload as IOrder).isGoodDeliveryDate) {
      alert(
        "Trop d'éléments sur la semaine de livraison. La Date de livraison à été modifiée : " +
          moment((retapp.payload as IOrder).deliveryDate).format(
            "DD/MMYYYY HH:mm"
          )
      );
    }
  }, [appDispatch, orderStore, t, tempFile]);

  // Signature ref
  const signatureRef = useRef<SignatureCanvas>();

  const handleOnSignatureEnd = (e: MouseEvent) => {
    const signatureString = signatureRef.current?.toDataURL("png");
    appDispatch(updateSignature(signatureString));
  };

  const clear = () => {
    signatureRef.current?.clear();
    appDispatch(updateSignature(undefined));
  };

  const handleFileChange = useCallback((fileList: FileWithPath[]) => {
    const item = fileList?.[0];
    if (item) {
      setTempFile({
        file: item,
        originalFileName: item.name,
        path: item.path ?? "",
      });
    }
  }, []);

  const orderFormUrl = useMemo(() => {
    return getPdfViewerUrl(orderStore?.orderFormId);
  }, [orderStore?.orderFormId]);

  const handleOpenFile = useCallback((url?: string) => {
    if (!url) {
      return;
    }
    window.open(url, "blank");
  }, []);

  const isFormInvalid = useMemo(() => {
    return (
      !signatureName ||
      signatureName?.length <= 0 ||
      ((!signature || signature.length <= 0) && !tempFile) ||
      orderStore.busy
    );
  }, [orderStore.busy, signature, signatureName, tempFile]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item sx={{ flex: 1 }}>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Signature")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item sx={{ padding: "20px 0px", width: "100%" }}>
            <Grid container>
              <Grid item xs={12}>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrl)}
                  label={t("See order form")}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="name"
                      label={t("Signature name")}
                      name="signatureName"
                      onChange={(e) => {
                        appDispatch(updateSignatureName(e?.target?.value));
                      }}
                      placeholder="Dupont Jean"
                      required={true}
                      size={"small"}
                      value={signatureName || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      sx={{ justifyContent: "space-between" }}
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant={"h5"} paragraph>
                          {t("I sign")}
                        </Typography>
                        {status === OrderStatusEnum.Signature && (
                          <Paper
                            elevation={2}
                            style={{
                              position: "relative",
                            }}
                          >
                            <SignatureCanvas
                              ref={(el) =>
                                (signatureRef.current = el as SignatureCanvas)
                              }
                              penColor="black"
                              canvasProps={{
                                width: "350px",
                                height: "120px",
                                style: {
                                  background: "#eaeaea",
                                  margin: "10px",
                                },
                              }}
                              onEnd={(e) => handleOnSignatureEnd(e)}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: 15,
                                bottom: 10,
                                zIndex: 9999,
                                cursor: "pointer",
                              }}
                              onClick={() => clear()}
                            >
                              <Delete />
                            </div>
                          </Paper>
                        )}
                      </Grid>
                      <Grid item sx={{ flex: 1 }}>
                        <Typography variant={"h5"} paragraph>
                          {t("Or I upload a file")}
                        </Typography>
                        <Dropzone
                          // define a key that will re-render the component after files update
                          key={dropzoneKey}
                          accept={{
                            "image/*": IMAGE_EXTENSIONS,
                            "application/pdf": PDF_EXTENSIONS,
                          }}
                          maxSize={MAX_SIZE}
                          onChange={handleFileChange}
                          multiple={false}
                          maxFiles={1}
                          maxFilesUploaded={1}
                          onDelete={() => setTempFile(undefined)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleBackToMerch}
            >
              {t("Back to expected delivery date")}
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={isFormInvalid}
              onClick={handleValidate}
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Signature;
