import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "api/product";
import {
  IRetailOrder,
  IRetailOrderStatusLine,
  RetailOrderStatusEnum,
  service,
} from "api/retailOrder";
import { IStore } from "api/store";
import { ITempFile } from "helpers/files";
import { AppDispatch, RootState } from "redux/store";
import { IReferenceItem } from "types/IReferenceItem";

export interface IRetailOrderState {
  id?: string;
  step: StepEnum;
  products: IRetailOrderProduct[];
  store: IStore;
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
  signature?: string;
  signatureName: string;
  retailOrder: IRetailOrder;
  extraPreparation?: number;
  extraShipping?: number;
  orderFormSignedTemp?: ITempFile;
  busy: boolean;
  error?: string;
  status: RetailOrderStatusEnum;
}

export interface IRetailOrderProduct {
  product: IProduct;
  quantity: number;
}

export enum StepEnum {
  Store = 0,
  Products,
  Shipping,
  Signature,
  Validation,
}

export const initialState: IRetailOrderState = {
  id: undefined,
  step: StepEnum.Store,
  products: [],
  store: {} as IStore,
  contactEmail: "",
  contactName: "",
  contactPhoneNumber: "",
  signature: undefined,
  signatureName: "",
  retailOrder: {} as IRetailOrder,
  status: RetailOrderStatusEnum.Draft,
  busy: false,
  error: undefined,
} as IRetailOrderState;

const getOrderStateFromOrder = (
  retailOrder: IRetailOrder
): IRetailOrderState => {
  let newState = {
    ...initialState,
    retailOrder: retailOrder,
    id: retailOrder.id,
    store: retailOrder.store,
    contactEmail: retailOrder.contactEmail,
    contactName: retailOrder.contactName,
    contactPhoneNumber: retailOrder.contactPhoneNumber,
    signatureName: retailOrder.signatureName || "",
    extraPreparation: retailOrder.extraPreparation,
    extraShipping: retailOrder.extraShipping,
    status: retailOrder.status,
  };

  newState.products =
    retailOrder.orderLines?.map((productLine) => {
      return {
        product: productLine.product,
        quantity: productLine.quantity,
      };
    }) || [];

  return newState;
};

export const getAsync = createAsyncThunk<
  IRetailOrderState,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/getAsync", async (input, thunkApi) => {
  const retailOrder: IRetailOrder = (await service.get({ id: input })).result;
  const retailOrderState = getOrderStateFromOrder(retailOrder);
  return retailOrderState;
});

export const getReferencesAsync = createAsyncThunk<
  IReferenceItem[],
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/getReferencesAsync", async (input, thunkApi) => {
  const items: IReferenceItem[] = (await service.invoke("get", "getReferences"))
    .result;
  return items;
});

export const createAsync = createAsyncThunk<
  IRetailOrderState,
  IRetailOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/createAsync", async (input, thunkApi) => {
  const retailOrder = (
    await service.create({ ...input, storeId: input.store?.id })
  ).result;
  const retailOrderState = getOrderStateFromOrder(retailOrder);
  return retailOrderState;
});

export const updateAsync = createAsyncThunk<
  IRetailOrderState,
  IRetailOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/updateAsync", async (input, thunkApi) => {
  const retailOrder: IRetailOrder = (
    await service.update({
      ...input,
      storeId: input.store?.id,
    })
  ).result;
  const retailOrderState = getOrderStateFromOrder(retailOrder);
  return retailOrderState;
});

export const completeAsync = createAsyncThunk<
  IRetailOrderState,
  IRetailOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/completeAsync", async (input, thunkApi) => {
  const retailOrder: IRetailOrder = (
    await service.invoke("put", "Complete", input)
  ).result;
  const retailOrderState = getOrderStateFromOrder(retailOrder);
  return retailOrderState;
});

export const validateAsync = createAsyncThunk<
  IRetailOrderState,
  IRetailOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/validateAsync", async (input, thunkApi) => {
  const retailOrder: IRetailOrder = (
    await service.invoke("put", "Validate", input)
  ).result;
  const retailOrderState = getOrderStateFromOrder(retailOrder);
  return retailOrderState;
});

export const refuseAsync = createAsyncThunk<
  IRetailOrderState,
  IRetailOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/refuseAsync", async (input, thunkApi) => {
  const retailOrder: IRetailOrder = (
    await service.invoke("put", "Refuse", input)
  ).result;
  const retailOrderState = getOrderStateFromOrder(retailOrder);
  return retailOrderState;
});

export const addCommentAsync = createAsyncThunk<
  IRetailOrderStatusLine,
  { retailOrderId: string; comment: string; sendNotification?: boolean },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("retailOrder/addCommentAsync", async (input, thunkApi) => {
  const result: IRetailOrderStatusLine = (
    await service.invoke("post", "AddComment", input)
  ).result;
  return result;
});

const retailOrdersSlice = createSlice({
  name: "retailOrder",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      return newState;
    },
    updateProducts: (state, action: PayloadAction<IRetailOrderProduct[]>) => {
      state.products = action.payload;
    },
    updateStore: (state, action: PayloadAction<IStore>) => {
      state.store = action.payload;
    },
    updateStep: (state, action: PayloadAction<StepEnum>) => {
      state.step = action.payload;
    },
    updateSignatureName: (state, action: PayloadAction<string>) => {
      state.signatureName = action.payload;
    },
    updateSignature: (state, action: PayloadAction<string | undefined>) => {
      state.signature = action.payload;
    },
    updateState: (state, action: PayloadAction<Partial<IRetailOrderState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(getAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step:
          action.payload.retailOrder.status === RetailOrderStatusEnum.Draft
            ? StepEnum.Products
            : action.payload.retailOrder.status ===
              RetailOrderStatusEnum.Shipping
            ? StepEnum.Shipping
            : action.payload.retailOrder.status ===
              RetailOrderStatusEnum.Signature
            ? StepEnum.Signature
            : StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(createAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(createAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(createAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(updateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(updateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step:
          state.step === StepEnum.Store
            ? StepEnum.Products
            : state.step === StepEnum.Products
            ? StepEnum.Shipping
            : state.step === StepEnum.Shipping
            ? StepEnum.Signature
            : StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(completeAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(completeAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(completeAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step: StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(addCommentAsync.fulfilled, (state, action) => {
      state.retailOrder.statusLines?.unshift(action.payload);
      state.busy = false;
      state.error = undefined;
    });
    builder.addCase(validateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(validateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(validateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step: StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(refuseAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(refuseAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(refuseAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step: StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
  },
});

export const {
  reset,
  updateProducts,
  updateStore,
  updateStep,
  updateSignature,
  updateSignatureName,
  updateState,
} = retailOrdersSlice.actions;
export default retailOrdersSlice.reducer;
