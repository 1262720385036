import React, { useCallback, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import backgroundImage from "images/login_background.jpg";
import logo from "images/logo.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { forgotPassword } from "api/account";
import * as ApiSettings from "../../api/apiSettings";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleLink = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email("Email non valide")
      .required("Email requis"),
  });

  const formik = useFormik({
    initialValues: { emailAddress: "" },
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        const apiResult = await forgotPassword(values.emailAddress);
        if (apiResult.result.mailSent === true) {
          setSuccess(true);
          setError("");
        } else {
          setSuccess(false);
          setError("Erreur lors de la réinitialisation du mot de passe.");
        }
      } catch (error) {
        let errorContent = ApiSettings.getResponseExceptionMessage(error);
        setSuccess(false);
        setError(errorContent);
      }
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.containerBackground}></div>
      <div className={classes.containerBackgroundImage}></div>
      <div className={classes.contentContainer}>
        <img src={logo} className={classes.logo} alt="" />
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.formContainer}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            Mot de passe oublié
          </Typography>
          <Grid
            container
            spacing={smallScreen ? 0 : 2}
            style={{ minWidth: smallScreen ? 250 : 540 }}
          >
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                name="emailAddress"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.emailAddress}
                error={
                  formik.touched.emailAddress &&
                  Boolean(formik.errors.emailAddress)
                }
                helperText={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
          {error && error !== "" && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">
              {
                "Le mot de passe a été réinitialisé avec succès ! Vous allez recevoir un email afin de continuer la procédure"
              }
            </Alert>
          )}
          {formik.isSubmitting && (
            <Alert severity="info">Inscription en cours...</Alert>
          )}
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Valider
            </Button>
          </div>
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              type="button"
              onClick={() => handleLink("/login")}
            >
              Connexion
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
    },
    containerBackground: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: theme.palette.secondary.main,
    },
    containerBackgroundImage: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    contentContainer: {
      position: "relative",
      margin: "0 auto", // auto auto to center both vertically / horizontally
      textAlign: "center",
      padding: 10,
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "white",
      borderRadius: "10px",
      boxShadow: "0 0 10px #000",
      borderColor: theme.palette.primary.main,
      border: 2,
      borderStyle: "solid",
    },
    submitButtonContainer: {
      marginTop: 20,
    },
  })
);
export default ForgotPassword;
// Registration.whyDidYouRender = {
//   logOnDifferentValues: false,
//   customName: "Registration",
// };
