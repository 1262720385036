import { makeStyles, createStyles } from "@mui/styles";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Theme, toast } from "react-toastify";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getResponseExceptionMessage } from "api/apiSettings";
import { service, TransportRequestStatusEnum } from "api/transportRequest";
import { ITransportRequest } from "api/transportRequest";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import ColorLegend from "components/ColorLegend";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "helpers/user";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const localizer = momentLocalizer(moment);

interface IEvent {
  id: any;
  title: string;
  start: any;
  end?: any;
  type: "sav" | any;
  orderRoute: string;
  route: string;
  color: string;
}

export default function Planning() {
  const classes = useStyles();
  const { t } = useTranslation(["common", "components/muireacttable"]);
  const { session } = useSelector((state: RootState) => state.auth);
  let navigate = useNavigate();
  const [transportRequests, setTransportRequests] = useState<
    ITransportRequest[]
  >([]);

  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(service.getAll(), {
        pending: {
          render() {
            return t("loading");
          },
        },
        // success: t("loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.success && apiResult.result) {
        setTransportRequests(apiResult.result.items);
      }
    })();
  }, [t]);

  const getTransportRequestColor = (transportRequest: ITransportRequest) => {
    let color = "#8CB6DB";
    switch (transportRequest.status) {
      case TransportRequestStatusEnum.Draft:
        color = "#8CB6DB";
        break;
      case TransportRequestStatusEnum.Pending:
        color = "#4191D8";
        break;
      case TransportRequestStatusEnum.Done:
        color = "#c0ffd1";
        break;
      case TransportRequestStatusEnum.Canceled:
        color = "#FF7F7F";
        break;
      default:
        color = "#218BE8";
        break;
    }
    return color;
  };

  const events = useMemo(() => {
    let result: IEvent[] = [];
    transportRequests.forEach((entity) => {
      result.push({
        id: entity.id,
        title: entity.storeCity + " - réf: " + entity.reference,
        start: moment(entity.deliveryDate).toDate(),
        end: moment(entity.deliveryDate).toDate(),
        type: "delivery",
        orderRoute: `/orders/${entity.orderId}`,
        route: `/transportRequests/${entity.id}`,
        color: getTransportRequestColor(entity),
      });
    });

    return result;
  }, [transportRequests]);

  const eventStyleGetter = (
    event: IEvent,
    start: string | Date,
    end: string | Date,
    isSelected: boolean
  ): { className?: string; style?: React.CSSProperties } => {
    return {
      style: {
        backgroundColor: event.color,
        color: "black",
      },
    };
  };

  const canSeeOrders = useMemo(() => {
    return hasPermission(session.user, "Order.Get");
  }, [session.user]);

  const canSeeTransportRequests = useMemo(() => {
    return hasPermission(session.user, "TransportRequest.Get");
  }, [session.user]);

  const handleClickEvent = (
    event: IEvent,
    e: React.SyntheticEvent<HTMLElement, Event>
  ) => {
    if (canSeeTransportRequests === true) {
      navigate(event.route);
    } else if (canSeeOrders === true) {
      navigate(event.orderRoute);
    }
  };

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: 20 }}>
        <Grid container spacing={2}>
          <Grid item style={{ width: 100 }}>
            <Typography>
              <strong>{t("Delivery")}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <ColorLegend color={"#8CB6DB"} text={t("Pending")} />
          </Grid>
          <Grid item>
            <ColorLegend color={"#4191D8"} text={t("Planned")} />
          </Grid>
          <Grid item>
            <ColorLegend color={"#c0ffd1"} text={t("Done")} />
          </Grid>
          <Grid item>
            <ColorLegend color={"#FF7F7F"} text={t("Canceled")} />
          </Grid>
        </Grid>
      </div>
      {/* http://jquense.github.io/react-big-calendar/examples/index.html#intro */}
      <Calendar
        key={events.length}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "calc(100vh - 200px)" }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleClickEvent}
        messages={{
          date: "Date",
          time: "Temps",
          event: "Événement",
          allDay: "Toute la journée",
          week: "Semaine",
          work_week: "Jour ouvrable",
          day: "Jour",
          month: "Mois",
          previous: "Précédent",
          next: "Suivant",
          yesterday: "Hier",
          tomorrow: "Demain",
          today: "Aujourd'hui",
          agenda: "Agenda",
          noEventsInRange: "Aucun évènement.",
          showMore: (total: any) => `+${total} de plus`,
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
    },
  })
);
