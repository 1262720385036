import { Delete, Download } from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  updateStep,
  StepEnum,
  updateAsync,
  updateState,
} from "redux/reducers/retailOrder";
import { RootState, useAppDispatch } from "redux/store";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { RetailOrderStatusEnum } from "api/retailOrder";
import { hasOneOfRoles } from "helpers/user";

const Shipping = () => {
  const { t } = useTranslation(["common"]);
  const appDispatch = useAppDispatch();
  const retailOrderStore = useSelector((state: RootState) => state.retailOrder);
  const {
    retailOrder: { status },
  } = useSelector((state: RootState) => state.retailOrder);
  const { session } = useSelector((state: RootState) => state.auth);
  const handleInputChange = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    appDispatch(updateState({ [event.target.name]: event.target.value }));
  };

  const handleBackToProducts = useCallback(() => {
    appDispatch(updateStep(StepEnum.Products));
  }, [appDispatch]);

  const handleValidate = useCallback(async () => {
    let toUpdateRetailOrderStore = { ...retailOrderStore };
    toUpdateRetailOrderStore.status = RetailOrderStatusEnum.Shipping;

    appDispatch(updateAsync(toUpdateRetailOrderStore));
  }, [appDispatch, retailOrderStore, t]);

  // Signature ref
  const canShipping = useMemo(() => {
    // TODO: use a permission or check from server side
    return hasOneOfRoles(session.user, ["Admin", "AdminPLV"]);
  }, [session.user]);
  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item sx={{ flex: 1 }}>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Shipping Cost")}
              </Typography>
            </Paper>
          </Grid>
          {canShipping && (
            <Grid item md={12} lg={5} sx={{ paddingLeft: "20px" }}>
              <form noValidate autoComplete="off">
                {/* <fieldset className={classes.fieldset}> */}
                {/* <legend>
                      <Typography color={"primary"}>Contact</Typography>
                    </legend> */}
                <TextField
                  fullWidth
                  label={t("Preparation")}
                  name="extraPreparation"
                  type="number"
                  value={retailOrderStore.extraPreparation}
                  inputProps={{}}
                  onChange={handleInputChange}
                  required
                  variant="filled"
                />
                <TextField
                  fullWidth
                  label={t("Shipping")}
                  name="extraShipping"
                  type="number"
                  value={retailOrderStore.extraShipping}
                  inputProps={{}}
                  onChange={handleInputChange}
                  required
                  variant="filled"
                />

                {/* </fieldset> */}
              </form>
            </Grid>
          )}
          {!canShipping && (
            <Grid
              item
              sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
            >
              <Paper elevation={2} sx={{ padding: "20px" }}>
                <Typography variant="h1" sx={{ fontSize: "18px" }}>
                  {t("Waiting for Preparation and Shipping cost")}
                </Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleBackToProducts}
            >
              {t("Back to product selection")}
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Grid container spacing={2}>
              <Grid item>
                {canShipping && (
                  <Button
                    color="primary"
                    variant="contained"
                    //disabled={isFormInvalid}
                    onClick={handleValidate}
                  >
                    {t("common|button.validate")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Shipping;
