import React, { useEffect } from "react";
import { reset } from "redux/reducers/retailOrder";
import { useAppDispatch } from "redux/store";
import ProcessManager from "./ProcessManager";

export default function Create() {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(reset());
  }, [appDispatch]);
  return <ProcessManager />;
}
