import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IFile } from "helpers/files";
import { IAbpResponse } from "types/IApi";
import { ISimpleEntity } from "types/ISimpleEntity";
import { getDefaultHeaders } from "./apiSettings";
import { ICompany } from "./company";
import { IConfiguration } from "./configuration";
import { IDepth } from "./depth";
import { IFurniture } from "./furniture";
import { IImportEntities, IProduct } from "./product";
import { IWidth } from "./width";
import * as ApiSettings from "./apiSettings";

export interface IMerch extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
  price: number;
  description: string;
  company: ICompany;
  companyId: string;
  furniture: IFurniture;
  furnitureId: string;
  depth: IDepth;
  depthId: string;
  width: IWidth;
  widthId: string;
  configuration: IConfiguration;
  configurationId: string;
  merchProducts: IMerchProduct[];
  files?: IMerchFile[];
  cip: string;
  creationTime: string;
}

export interface IMerchProduct {
  id?: string;
  product: IProduct;
  quantity: number;
}

export interface IMerchFile {
  merchId: string;
  file: IFile;
  fileId: string;
}

export interface ICreateMerch extends Omit<IMerch, "id"> {}

export const defaultEntity = {
  reference: "",
  label: "",
  isActive: true,
  price: 0,
  description: "",
  company: null,
  companyId: "",
  furniture: null,
  furnitureId: "",
  depth: null,
  depthId: "",
  width: null,
  widthId: "",
  configuration: null,
  configurationId: "",
  merchProducts: [],
  cip: "",
} as unknown as ICreateMerch;

export const defaultEditEntity = {
  id: "",
  reference: "",
  label: "",
  isActive: false,
  price: 0,
  description: "",
  company: null,
  companyId: "",
  furniture: null,
  furnitureId: "",
  depth: null,
  depthId: "",
  width: null,
  widthId: "",
  configuration: null,
  configurationId: "",
  merchProducts: [],
  cip: "",
} as unknown as IMerch;

export const service = new AbpAppService<IMerch, IMerch>("Merch");

export const duplicate = (params: {
  merchId: string;
  reference: string;
  label: string;
}): Promise<AxiosResponse<IAbpResponse<string>>> => {
  return axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Duplicate`,
    params,
    {
      headers: {
        ...getDefaultHeaders(),
      },
    }
  );
};

export const importMerchs = (params: {
  filePath: string;
  isPreview?: boolean;
  isDebug?: boolean;
  maxRowsToHandle?: number;
}): Promise<AxiosResponse<IAbpResponse<IImportEntities<IMerch>>>> => {
  return axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Import`,
    params,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );
};
