import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IDepth, service } from "api/depth";
import MuiReactTable, {
  BooleanColumnFilter,
  BooleanColumnFilterCell,
  BooleanColumnFilterMethod,
  IFilter,
} from "components/MuiReactTable";
import { hasPermission } from "helpers/user";
import { toast } from "react-toastify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setForm, selectFormById } from "redux/reducers/forms";
import { RootState, useAppDispatch } from "redux/store";

const FORM_ID = "depths"; // used to save filter values

const List = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  const { session } = useSelector((state: RootState) => state.auth);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);
  let [depths, setDepths] = useState<IDepth[]>([]);

  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(service.getAll(), {
        pending: {
          render() {
            return t("common|Loading");
          },
        },
        // success: t("common|loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.success && apiResult.result) {
        setDepths(apiResult.result.items);
      }
    })();
  }, [t]);

  const handleRowClick = useCallback(
    (row: any, data: IDepth, event: any) => {
      navigate(`/catalog/depths/${data.id}`);
    },
    [navigate]
  );

  const handleAddClick = useCallback(() => {
    navigate(`/catalog/depths/create`);
  }, [navigate]);

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  return (
    <div className={classes.container}>
      <MuiReactTable
        title={t("Depths")}
        columns={[
          { Header: t("Reference"), accessor: "reference" },
          { Header: t("Label"), accessor: "label" },
          {
            Header: t("column.isActive"),
            accessor: "isActive",
            Filter: BooleanColumnFilter,
            filter: BooleanColumnFilterMethod,
            Cell: BooleanColumnFilterCell,
          },
        ]}
        data={depths}
        onRowClick={
          hasPermission(session.user, "Depth.Get") === false
            ? undefined
            : handleRowClick
        }
        onAddClick={
          hasPermission(session.user, "Depth.Create") === false
            ? undefined
            : handleAddClick
        }
        csvExport={true}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
        onFiltersChange={handleFiltersChange}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);
export default List;
