import {
  Box,
  Grid,
  MenuItem,
  Popper,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MuiReactTable, { IFilter } from "components/MuiReactTable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { selectFormById, setForm } from "redux/reducers/forms";
import { formatDate } from "helpers/tools";
import linq from "linq";
import { RemoveRedEye } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  IAfterSaleRequest,
  AfterSaleRequestStatusColors,
  AfterSaleRequestStatusEnum,
  AfterSaleRequestStatusLabels,
  service,
} from "api/afterSaleRequest";
import { getResponseExceptionMessage } from "api/apiSettings";

const FORM_ID = "afterSaleRequests"; // used to save filter values

const AfterSaleRequests = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "components/muireacttable"]);
  let navigate = useNavigate();
  const [afterSaleRequests, setAfterSaleRequests] = useState<
    IAfterSaleRequest[]
  >([]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);

  // #region Popper handlers start
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperContent, setPopperContent] = useState<any>(null);
  const handleMouseEnter = useCallback(
    (event: any, afterSaleRequest: IAfterSaleRequest) => {
      setAnchorEl(event.currentTarget);
      let content = linq
        .from(afterSaleRequest.statusLines)
        .orderByDescending((o) => o.creationTime)
        .toArray()
        .map((statusLine) => (
          <li key={statusLine.id}>
            <Typography>
              {formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm")} -{" "}
              {AfterSaleRequestStatusLabels[statusLine.status]}
              {statusLine.comment && statusLine.comment !== ""
                ? ` : ${statusLine.comment}`
                : ""}
            </Typography>
          </li>
        ));
      setPopperContent(
        <div>
          {/* <Typography variant="h5">Historique</Typography> */}
          <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
            {content}
          </ul>
        </div>
      );
    },
    []
  );
  const handleMouseLeave = useCallback((event: any) => {
    setAnchorEl(null);
    setPopperContent(null);
  }, []);
  const open = Boolean(anchorEl);
  const id = open
    ? (anchorEl as any)?.getAttribute("aria-describedby")
    : undefined;
  // #endregion Popper handlers end

  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(service.getAll(), {
        pending: {
          render() {
            return t("loading");
          },
        },
        // success: t("loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.success && apiResult.result) {
        setAfterSaleRequests(apiResult.result.items);
      }
    })();
  }, [t]);

  const tableColumns = useMemo(
    () => [
      { Header: t("column.reference"), accessor: "reference" },
      {
        Header: t("column.status"),
        accessor: "status",
        style: {
          minWidth: "200px",
          maxWidth: "200px", // this is not working properly but at least the column is larger than default
        },
        //filter: "equals",
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            const rowAfterSaleRequest: any = row.original;
            if ((filterValue as number) > 0) {
              return rowAfterSaleRequest.status === filterValue;
            }
            return true;
          });
        },
        Cell: ({
          row: { original },
        }: {
          row: { original: IAfterSaleRequest };
        }) => {
          return (
            <Grid
              container
              sx={{
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
              spacing={1}
              aria-describedby={original.reference}
              onMouseEnter={(event) => handleMouseEnter(event, original)}
              onMouseLeave={handleMouseLeave}
            >
              <Grid item>
                <RemoveRedEye />
              </Grid>
              <Grid item>{AfterSaleRequestStatusLabels[original.status]}</Grid>
            </Grid>
          );
        },
        getCellExportValue: (row: any, column: any) => {
          return (
            AfterSaleRequestStatusLabels[
              row.original.status as AfterSaleRequestStatusEnum
            ] || ""
          );
        },
        Filter: ({ column: { filterValue = 0, setFilter } }: any) => {
          let menuItems: any = Object.keys(AfterSaleRequestStatusLabels).map(
            (value, index) => {
              if (!isNaN(Number(value))) {
                return (
                  <MenuItem key={value} value={Number(value)}>
                    {(AfterSaleRequestStatusLabels as any)[value]}
                  </MenuItem>
                );
              }
              return null;
            }
          );
          return (
            <Select
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value || 0);
              }}
              value={filterValue}
              sx={{ height: "40px" }}
            >
              <MenuItem value={0}>Tous</MenuItem>
              {menuItems}
            </Select>
          );
        },
      },
      { Header: t("column.reason"), accessor: "reason" },
      { Header: t("column.orderReference"), accessor: "orderReference" },
      { Header: t("column.storeReference"), accessor: "storeReference" },
      { Header: t("column.storeLabel"), accessor: "storeLabel" },
      { Header: t("column.address"), accessor: "storeAddress" },
      { Header: t("column.city"), accessor: "storeCity" },
      { Header: t("column.postalCode"), accessor: "storePostalCode" },
    ],
    [handleMouseEnter, handleMouseLeave, t]
  );

  const handleRowClick = (row: any, data: IAfterSaleRequest, event: any) => {
    navigate(`/afterSaleRequests/${data.id}`);
  };

  const handleAddClick = () => {
    navigate(`/afterSaleRequests/create`);
  };

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  const getRowStyle = (row: any, data: IAfterSaleRequest) => {
    let styles = {};
    if (data.status) {
      styles = {
        bafterSaleRequestLeft: `10px solid ${
          AfterSaleRequestStatusColors[data.status]
        }`,
      };
    }
    return styles;
  };

  return (
    <div className={classes.container}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right"
        style={{
          background: "#F8F8F8",
          padding: "10px",
          border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        <Box>{popperContent}</Box>
      </Popper>
      <MuiReactTable
        // autoResetPage={autoResetPage}
        columns={tableColumns}
        data={afterSaleRequests}
        onAddClick={handleAddClick}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        title={t("After sale request list")}
        csvExport={true}
        fileName={t("afterSaleRequest")}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
        rowStyle={getRowStyle}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);

export default AfterSaleRequests;
