import Axios from "axios";
import * as ApiSettings from "./apiSettings";

export const upload = (formData: any, onUploadProgress: any = undefined) => {
  return Axios.post(`${ApiSettings.apiBaseUrl}/api/file/upload`, formData, {
    headers: {
      ...ApiSettings.getDefaultHeaders(),
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};
