import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { service } from "api/afterSaleRequest";
import {
  IAfterSaleRequest,
  IAfterSaleRequestStatusLine,
} from "api/afterSaleRequest";
import { AppDispatch, RootState } from "redux/store";
import {
  validateAsync as validateAsyncApi,
  refuseAsync as refuseAsyncApi,
  addCommentAsync as addCommentAsyncApi,
} from "api/afterSaleRequest";
import { getReferencesAsync as getOrderReferencesAsync } from "./order";
import { getReferencesAsync as getRetailOrderReferencesAsync } from "./retailOrder";
import { IReferenceItem } from "types/IReferenceItem";

export interface IAfterSaleRequestState {
  busy?: boolean;
  error?: string;
  entity?: IAfterSaleRequest;
  orderReferences?: IReferenceItem[];
  retailOrderReferences?: IReferenceItem[];
}

export const initialState: IAfterSaleRequestState = {
  busy: false,
  error: "",
  entity: undefined,
};

const getAfterSaleRequestStateFromAfterSaleRequest = (
  afterSaleRequest: IAfterSaleRequest
): IAfterSaleRequestState => {
  return {
    ...initialState,
    entity: afterSaleRequest,
  };
};

export const getAsync = createAsyncThunk<
  IAfterSaleRequestState,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("afterSaleRequest/getAsync", async (input, thunkApi) => {
  const afterSaleRequest: IAfterSaleRequest = (await service.get({ id: input }))
    .result;
  const afterSaleRequestState =
    getAfterSaleRequestStateFromAfterSaleRequest(afterSaleRequest);
  return afterSaleRequestState;
});

export const createAsync = createAsyncThunk<
  IAfterSaleRequestState,
  { orderId?: string; retailOrderId?: string },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/createAsync", async (input, thunkApi) => {
  const afterSaleRequest = (await service.create(input)).result;
  const afterSaleRequestState =
    getAfterSaleRequestStateFromAfterSaleRequest(afterSaleRequest);
  return afterSaleRequestState;
});

export const updateAsync = createAsyncThunk<
  IAfterSaleRequestState,
  IAfterSaleRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("afterSaleRequest/updateAsync", async (input, thunkApi) => {
  const afterSaleRequest: IAfterSaleRequest = (
    await service.update({
      ...input,
    })
  ).result;
  const afterSaleRequestState =
    getAfterSaleRequestStateFromAfterSaleRequest(afterSaleRequest);
  return afterSaleRequestState;
});

export const validateAsync = createAsyncThunk<
  IAfterSaleRequestState,
  IAfterSaleRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("afterSaleRequest/validateAsync", async (input, thunkApi) => {
  const afterSaleRequest: IAfterSaleRequest = (await validateAsyncApi(input))
    .data.result;
  const afterSaleRequestState =
    getAfterSaleRequestStateFromAfterSaleRequest(afterSaleRequest);
  return afterSaleRequestState;
});

export const refuseAsync = createAsyncThunk<
  IAfterSaleRequestState,
  IAfterSaleRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("afterSaleRequest/refuseAsync", async (input, thunkApi) => {
  const afterSaleRequest: IAfterSaleRequest = (await refuseAsyncApi(input)).data
    .result;
  const afterSaleRequestState =
    getAfterSaleRequestStateFromAfterSaleRequest(afterSaleRequest);
  return afterSaleRequestState;
});

export const addCommentAsync = createAsyncThunk<
  IAfterSaleRequestStatusLine,
  { afterSaleRequestId: string; comment: string },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("afterSaleRequest/addCommentAsync", async (input, thunkApi) => {
  const result: IAfterSaleRequestStatusLine = (await addCommentAsyncApi(input))
    .data.result;
  return result;
});

const afterSaleRequestSlice = createSlice({
  name: "afterSaleRequest",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      return newState;
    },
    updateState: (
      state,
      action: PayloadAction<Partial<IAfterSaleRequestState>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(getAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(createAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(createAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(createAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(updateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(updateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(addCommentAsync.fulfilled, (state, action) => {
      state.entity?.statusLines?.unshift(action.payload);
      state.busy = false;
      state.error = undefined;
    });
    builder.addCase(validateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(validateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(validateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(refuseAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(refuseAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(refuseAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(getOrderReferencesAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getOrderReferencesAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(getOrderReferencesAsync.fulfilled, (state, action) => {
      state.busy = false;
      state.error = undefined;
      state.orderReferences = action.payload;
    });
    builder.addCase(getRetailOrderReferencesAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getRetailOrderReferencesAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(
      getRetailOrderReferencesAsync.fulfilled,
      (state, action) => {
        state.busy = false;
        state.error = undefined;
        state.retailOrderReferences = action.payload;
      }
    );
  },
});

export const { reset, updateState } = afterSaleRequestSlice.actions;
export default afterSaleRequestSlice.reducer;
