import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { defaultEntity, service } from "api/product";
import { toast } from "react-toastify";
import { hasPermission } from "helpers/user";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResponseExceptionMessage } from "api/apiSettings";
import { MIN_PRICE, MAX_PRICE } from "./Create";

const Edit = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [entity, setEntity] = useState(defaultEntity);
  const { session } = useSelector((state: RootState) => state.auth);
  const { productId } = useParams();

  useEffect(() => {
    (async function () {
      if (productId) {
        const apiResult = await toast.promise(service.get({ id: productId }), {
          pending: {
            render() {
              return t("loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.result?.id) {
          setEntity(apiResult.result);
        }
      }
    })();
  }, [productId, t]);

  const validationSchema = yup.object({
    reference: yup
      .string()
      .required(
        t("common|field_name.required", { field_name: t("common|Reference") })
      ),
    label: yup
      .string()
      .required(
        t("common|field_name.required", { field_name: t("common|Label") })
      ),
    price: yup
      .number()
      .required(t("field_name.required", { field_name: t("Price") }))
      .min(
        MIN_PRICE,
        t("field_name.min", { field_name: t("Price"), min: MIN_PRICE })
      )
      .max(
        MAX_PRICE,
        t("field_name.max", { field_name: t("Price"), max: MAX_PRICE })
      ),
    retailPrice: yup
      .number()
      .min(
        MIN_PRICE,
        t("field_name.min", { field_name: t("Retail price"), min: MIN_PRICE })
      )
      .max(
        MAX_PRICE,
        t("field_name.max", { field_name: t("Retail price"), max: MAX_PRICE })
      )
      .nullable(true) // not required but add it for "logic"
      .transform((_, val) => (val === Number(val) ? val : null)),
      weight: yup
      .number()
    //https://stackoverflow.com/a/63230828/5052969
  });

  const formik = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      await toast.promise(service.update(values), {
        pending: t("processing"),
        success: t("processing.success"),
        error: t("processing.error"),
      });
    },
  });

  return (
    <div className={classes.container}>
      <form
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="isActive"
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                  color="primary"
                />
              }
              label={t("Enabled")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Edit product")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            {hasPermission(session.user, "Product.Update") === true && (
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                type="submit"
              >
                {t("common|button.validate")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Reference")}
              name="reference"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Label")}
              name="label"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.label}
              error={formik.touched.label && Boolean(formik.errors.label)}
              helperText={formik.touched.label && formik.errors.label}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("Price")}
              name="price"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.price}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("Retail price")}
              name="retailPrice"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.retailPrice}
              error={
                formik.touched.retailPrice && Boolean(formik.errors.retailPrice)
              }
              helperText={
                formik.touched.retailPrice && formik.errors.retailPrice
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("Weight")}
              name="weight"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.weight}
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  name="isOrderOption"
                  onChange={formik.handleChange}
                  checked={formik.values.isOrderOption}
                  color="primary"
                />
              }
              label={t("Order option")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  name="isPallet"
                  onChange={formik.handleChange}
                  checked={formik.values.isPallet}
                  color="primary"
                />
              }
              label={t("Pallet")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  name="usedForTransportPrice"
                  onChange={formik.handleChange}
                  checked={formik.values.usedForTransportPrice}
                  color="primary"
                />
              }
              label={t("Used for transport price")}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default Edit;
