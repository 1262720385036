import { Button, MenuItem, Select, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getAllAsync, importUsers } from "api/user";
import { getAllAsync as getAllRolesAsync } from "api/role";
import MuiReactTable, {
  BooleanColumnFilter,
  BooleanColumnFilterCell,
  BooleanColumnFilterMethod,
  IFilter,
} from "components/MuiReactTable";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "types/IUser";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { selectFormById, setForm } from "redux/reducers/forms";
import { IRole } from "types/IRole";
import { generateKey } from "helpers/tools";
import ModalImport, { IModalImport } from "./ModalImport";
import { ModalContext } from "contexts/ModalContext";
import { upload } from "api/files";
import LoadingScreen from "components/LoadingScreen";

const FORM_ID = "users"; // used to save filter values

const List = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "components/muireacttable"]);
  let navigate = useNavigate();
  const [users, setUsers] = useState<IUser[]>([]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);
  let [importFileInputKey, setImportFileInputKey] = useState(
    generateKey("importFileInputKey")
  );
  let [busy, setBusy] = useState(false);
  const { addModal } = useContext(ModalContext);

  const load = useCallback(async () => {
    try {
      const apiResult = await toast.promise(getAllAsync(), {
        pending: {
          render() {
            return t("Loading");
          },
        },
        // success: t("loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.success && apiResult.result) {
        setUsers(apiResult.result.items);
      }
    } catch (error) {}
  }, [t]);

  useEffect(() => {
    (async function () {
      await load();
    })();
  }, [load]);

  const [roles, setRoles] = useState<IRole[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const apiResult = await toast.promise(getAllRolesAsync(), {
          pending: {
            render() {
              return t("Loading");
            },
          },
          // success: t("loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.success && apiResult.result) {
          setRoles(apiResult.result.items);
        }
      } catch (error) {}
    })();
  }, [t]);

  const tableColumns = useMemo(
    () => [
      { Header: t("users.id"), accessor: "id" },
      { Header: t("users.username"), accessor: "userName" },
      { Header: t("users.firstname"), accessor: "name" },
      { Header: t("users.lastname"), accessor: "surname" },
      { Header: t("users.email"), accessor: "emailAddress" },
      {
        Header: "Roles",
        accessor: "roleNames",
        Cell: ({ value }: { value: string[] }) => {
          return value.join(", ");
        },
        // Default filterValue to avoid uncontrolled react error
        Filter: ({ column: { filterValue = "", setFilter } }: any) => {
          return (
            <Select
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value || "");
              }}
              value={filterValue}
              sx={{ height: "40px" }}
            >
              <MenuItem value={""}>Tous</MenuItem>
              {roles.map((role) => {
                return (
                  <MenuItem
                    key={role.normalizedName}
                    value={role.normalizedName}
                  >
                    {role.normalizedName}
                  </MenuItem>
                );
              })}
            </Select>
          );
        },
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            const rowUser: IUser = row.original;
            if (filterValue) {
              return rowUser.roleNames.indexOf(filterValue) !== -1;
            }
            return true;
          });
        },
      },
      {
        Header: t("users.isActive"),
        accessor: "isActive",
        Filter: BooleanColumnFilter,
        filter: BooleanColumnFilterMethod,
        Cell: BooleanColumnFilterCell,
      },
    ],
    [roles, t]
  );

  const handleRowClick = (row: any, data: IUser, event: any) => {
    navigate(`/users/${data.id}`);
  };

  const handleAddClick = () => {
    navigate(`/users/create`);
  };

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  const onFileDownloaded = useCallback(
    async (path: string) => {
      const apiResult = await importUsers({
        filePath: path,
      });
      const modal: IModalImport = {
        component: ModalImport,
        props: {
          title: t("User import"),
          result: apiResult.data.result,
          filePath: path,
          dialogProps: {
            onClose: async () => {
              await load();
            },
          },
        },
      };
      addModal(modal);
    },
    [addModal, load, t]
  );

  const handleImportFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setBusy(true);
        const files = e?.target?.files;
        if (files && files?.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            formData.append(file.name, file);
            formData.set("path", "/importUsers");
          }
          const uploadApiResult = await upload(formData);
          if (
            uploadApiResult?.data?.success &&
            uploadApiResult?.data?.result?.path
          ) {
            await onFileDownloaded(uploadApiResult?.data?.result?.path);
          } else {
            toast(uploadApiResult.data.error);
          }
        }
      } catch (error: any) {
        toast(error.toString(), { type: "error" });
      } finally {
        setImportFileInputKey(generateKey("importFileInputKey"));
        setBusy(false);
      }
    },
    [onFileDownloaded]
  );

  const muiTableHeader = useCallback(() => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <input
          key={importFileInputKey}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={(e) => handleImportFile(e)}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            {t("User import")}
          </Button>
        </label>
      </div>
    );
  }, [handleImportFile, importFileInputKey, t]);

  return (
    <div className={classes.container}>
      <MuiReactTable
        // autoResetPage={autoResetPage}
        header={muiTableHeader}
        columns={tableColumns}
        data={users}
        onAddClick={handleAddClick}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        title={t("users.title")}
        csvExport={true}
        fileName={t("users.exportFileName")}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
      />
      {busy && <LoadingScreen />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);

export default List;
