import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  // Theme
} from "@mui/material";
// import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataGrid from "react-data-grid";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { BillingOrder, getBilling } from "api/order";
import { formatAmount, formatDate, formatWeight } from "helpers/tools";
import linq from "linq";
import "./Billing.css";
import saveAs from "file-saver";
import { unparse } from "papaparse";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IFurniture, service as furnitureService } from "api/furniture";
import { toast } from "react-toastify";

function billingDateFormat(momentDate: Moment) {
  return momentDate.set("hour", 0).set("minute", 0).set("second", 0);
}

interface ISummaryRow {
  type: "TotalHT" | "TotalTTC";
}

interface ICsvLine {
  Semaine: string;
  Référence: string;
  Enseigne: string;
  Ville: string;
  "Code magasin": string;
  Type: string;
  "Nbre éléments": string;
  Prof: string;
  "Date de livraison": string;
  "Poids Total": string;
  "Code CIP": string;
  "Prix mobilier": string;
  "Prix transport": string;
  Total: string;
}

export default function Billing() {
  // const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const [furnitureId, setFurnitureId] = useState<string>("all");
  const [loadingDate, setLoadingDate] = useState(billingDateFormat(moment()));
  const [billableDate, setBillableDate] = useState<string>("");
  let [furnitures, setFurnitures] = useState<IFurniture[]>([]);
  const [data, setData] = useState<BillingOrder[]>([]);

  const getFurnitures = useCallback(async () => {
    const apiResult = await toast.promise(furnitureService.getAll(), {
      pending: {
        render() {
          return t("common|Loading");
        },
      },
      // success: t("common|loading.success"),
      error: {
        render({ data }: any) {
          return getResponseExceptionMessage(data);
        },
        autoClose: false,
      },
    });
    if (apiResult?.success && apiResult.result) {
      setFurnitures(apiResult.result.items);
    }
  }, [t]);

  const getData = useCallback(async () => {
    const apiResult = await toast.promise(
      getBilling({
        loadingDate: loadingDate.toDate().toDateString(),
        furnitureId: furnitureId !== "all" ? furnitureId : "",
        billable: billableDate !== "",
      }),
      {
        pending: {
          render() {
            return t("common|Loading");
          },
        },
        // success: t("common|loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      }
    );
    if (apiResult.success) {
      setData(
        linq
          .from(apiResult.result.billingOrders)
          .orderBy((o) => o.order.loadingDate)
          .toArray()
      );
    }
  }, [billableDate, furnitureId, loadingDate, t]);

  useEffect(() => {
    (async function () {
      await getFurnitures();
    })();
  }, [getFurnitures]);

  useEffect(() => {
    (async function () {
      await getData();
    })();
  }, [getData]);

  const furnitureTotal = useMemo(() => {
    return linq.from(data).sum((o) => o.furnitureTotal);
  }, [data]);

  const transportTotal = useMemo(() => {
    return linq.from(data).sum((o) => o.transportTotal);
  }, [data]);

  const sumTotalHT = useMemo(() => {
    return linq.from(data).sum((o) => o.order.totalHT ?? 0);
  }, [data]);

  const sumTotalTTC = useMemo(() => {
    return linq.from(data).sum((o) => o.order.totalTTC ?? 0);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        name: "Semaine",
        key: "orderLoadingDateWeek",
        width: 60,
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{formatDate(row.order.loadingDate, "YYYY-WW")}</>;
        },
        summaryFormatter: ({ row }: { row: ISummaryRow }) => {
          return <>{row.type}</>;
        },
      },
      {
        name: "Date de chargement",
        key: "orderLoadingDate",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{formatDate(row.order.loadingDate)}</>;
        },
      },
      {
        name: "Référence",
        key: "orderReference",
        width: 90,
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.reference}</>;
        },
      },
      {
        name: "Enseigne",
        key: "orderCompanyLabel",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.companyLabel}</>;
        },
      },
      {
        name: "Ville",
        key: "orderCity",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.city}</>;
        },
      },
      {
        name: "Code magasin",
        key: "orderStoreNumber",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.storeReference}</>;
        },
      },
      {
        name: "Magasin",
        key: "orderStoreLabel",
        width: 250,
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.storeLabel}</>;
        },
      },
      {
        name: "Type",
        key: "orderFurnitureLabel",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.furnitureLabel}</>;
        },
      },
      {
        name: "Nbre éléments",
        key: "orderConfigurationLabel",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.configurationLabel}</>;
        },
      },
      {
        name: "Profondeur",
        key: "orderDepthLabel",
        width: 100,
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.depthLabel}</>;
        },
      },
      {
        name: "Code CIP",
        key: "orderMerchCIP",
        width: 100,
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{row.order.merch?.cip}</>;
        },
      },
      {
        name: "Poids Total",
        key: "orderTotalWeight",
        width: 100,
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{formatWeight(row.order.totalWeight ?? 0)}</>;
        },
      },
      {
        name: "Prix mobilier",
        key: "furnitureTotal",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{formatAmount(row.furnitureTotal)}</>;
        },
        summaryFormatter: ({ row }: { row: ISummaryRow }) => {
          return (
            <>{row.type === "TotalHT" ? formatAmount(furnitureTotal) : ""}</>
          );
        },
        cellClass: (row: BillingOrder) => {
          return "rdg-amount";
        },
        summaryCellClass: (row: any) => {
          return "rdg-amount";
        },
      },
      {
        name: "Prix transport",
        key: "transportTotal",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{formatAmount(row.transportTotal)}</>;
        },
        summaryFormatter: ({ row }: { row: ISummaryRow }) => {
          return (
            <>{row.type === "TotalHT" ? formatAmount(transportTotal) : ""}</>
          );
        },
        cellClass: (row: BillingOrder) => {
          return "rdg-amount";
        },
        summaryCellClass: (row: any) => {
          return "rdg-amount";
        },
      },
      {
        name: "Total",
        key: "orderTotal",
        formatter: ({ row }: { row: BillingOrder }) => {
          return <>{formatAmount(row.order.totalHT ?? 0)}</>;
        },
        summaryFormatter: ({ row }: { row: ISummaryRow }) => {
          return (
            <>
              {row.type === "TotalHT"
                ? formatAmount(sumTotalHT)
                : formatAmount(sumTotalTTC)}
            </>
          );
        },
        cellClass: (row: BillingOrder) => {
          return "rdg-amount";
        },
        summaryCellClass: (row: any) => {
          return "rdg-amount";
        },
      },
    ],
    [furnitureTotal, sumTotalHT, sumTotalTTC, transportTotal]
  );

  const summaryRows = useMemo(() => {
    return [
      {
        type: "TotalHT",
      },
      {
        type: "TotalTTC",
      },
    ] as ISummaryRow[];
  }, []);

  const csv = useMemo(() => {
    let result = unparse(
      data.map((row: BillingOrder) => {
        const line: ICsvLine = {
          Semaine: formatDate(row.order.loadingDate, "YYYY-WW"),
          "Date de livraison": formatDate(row.order.loadingDate),
          Référence: row.order.reference,
          Enseigne: row.order.companyLabel,
          Ville: row.order.city,
          "Code magasin": row.order.storeReference,
          Type: row.order.furnitureLabel,
          "Nbre éléments": row.order.configurationLabel,
          Prof: row.order.depthLabel,
          "Code CIP": row.order.merch?.cip ?? "",
          "Poids Total": formatWeight(row.order.totalWeight ?? 0),
          "Prix mobilier": formatAmount(row.furnitureTotal),
          "Prix transport": formatAmount(row.transportTotal),
          Total: formatAmount(row.order.totalHT ?? 0),
        };
        return line;
      }),
      {
        delimiter: ";",
      }
    );

    result += "\r\n";

    result += unparse(
      summaryRows.map((row: ISummaryRow) => {
        if (row.type === "TotalHT") {
          const line: ICsvLine = {
            Semaine: row.type,
            "Date de livraison": "",
            Référence: "",
            Enseigne: "",
            Ville: "",
            "Code magasin": "",
            Type: "",
            "Nbre éléments": "",
            Prof: "",
            "Code CIP": "",
            "Poids Total": "",
            "Prix mobilier": formatAmount(furnitureTotal).toString(),
            "Prix transport": formatAmount(transportTotal).toString(),
            Total: formatAmount(sumTotalHT).toString(),
          };
          return line;
        } else {
          const line: ICsvLine = {
            Semaine: row.type,
            "Date de livraison": "",
            Référence: "",
            Enseigne: "",
            Ville: "",
            "Code magasin": "",
            Type: "",
            "Nbre éléments": "",
            Prof: "",
            "Code CIP": "",
            "Poids Total": "",
            "Prix mobilier": "",
            "Prix transport": "",
            Total: formatAmount(sumTotalTTC).toString(),
          };
          return line;
        }
      }),
      {
        delimiter: ";",
        header: false,
      }
    );

    return result;
  }, [
    data,
    furnitureTotal,
    sumTotalHT,
    sumTotalTTC,
    summaryRows,
    transportTotal,
  ]);

  return (
    <Grid
      container
      flexDirection="column"
      spacing={2}
      sx={{ padding: "20px", height: "100%" }}
    >
      <Grid item>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "flex-start", alignItems: "center" }}
        >
          <Grid item>
            <DatePicker
              renderInput={(params) => <TextField {...params} />}
              label="Du"
              value={loadingDate}
              views={["year", "month"]}
              onChange={(value: Moment | null) => {
                if (value && value.isValid()) {
                  setLoadingDate(billingDateFormat(value));
                }
              }}
            />
          </Grid>
          <Grid item>
            <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel>{t("Furniture")}</InputLabel>
              <Select
                label={t("Furniture")}
                onChange={(e) => {
                  setFurnitureId(e.target.value);
                }}
                value={furnitureId || "all"}
              >
                <MenuItem value={"all"} defaultChecked>
                  Tous
                </MenuItem>
                {furnitures?.map((furnitures) => (
                  <MenuItem key={furnitures.id} value={furnitures.id}>
                    {furnitures.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={billableDate !== ""}
                  onChange={() => {
                    setBillableDate(
                      billableDate === "" ? new Date().toDateString() : ""
                    );
                  }}
                  color="primary"
                />
              }
              label="Facturable"
            />
            <Typography>
              <small>
                Date de chargement {`<= ${moment().format("DD-MM-YYYY")}`}
              </small>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                saveAs(
                  new Blob([`\uFEFF${csv}`], {
                    type: "text/csv;charset=utf-8",
                  }),
                  "export.csv"
                )
              }
            >
              Export CSV
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        <DataGrid
          style={{ height: "100%" }}
          columns={columns ?? []}
          rows={data ?? []}
          summaryRows={summaryRows}
          // headerRowHeight={30}
          // rowClass={(row: any) => {
          //   let className = "";
          //   if (row.loadingDate) {
          //     const loadingMoment = moment(row.loadingDate);
          //     const startOfDay = moment().startOf("day");
          //     if (startOfDay.diff(loadingMoment.startOf("day"), "days") <= 0)
          //       className = "rdg-billing-disabled";
          //   }
          //   if (row.order?.billingReference) {
          //     className = "rdg-billing-is-billed";
          //   }
          //   return className;
          // }}
        />
      </Grid>
    </Grid>
  );
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     container: {
//       padding: 20,
//     },
//   })
// );
