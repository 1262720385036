import { AddCircle, Check, Close, CloudDownload } from "@mui/icons-material";
import {
  Button,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { formatAmount, formatWeight } from "helpers/tools";
import Papa from "papaparse";
import React, { useEffect } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useExportData } from "react-table-plugins";

interface IMuiReactTable {
  autoResetPage?: boolean;
  columns: any;
  csvExport?: boolean;
  data: any;
  defaultState?: any;
  fileName?: string;
  header?: any;
  onAddClick?: any;
  onRowClick?: any;
  onFiltersChange?: (filters: IFilter[]) => void;
  rowStyle?: any;
  title?: string;
  labels?: {
    resultCount?: string;
    exportButton?: string;
    rowCount?: string;
    reinitiliazeFilters?: string;
  };
  forcePageSize?: number;
}

export interface IFilter {
  id: string;
  value: string;
}

const defaultValues: IMuiReactTable = {
  autoResetPage: false,
  columns: null,
  csvExport: false,
  data: null,
  defaultState: {},
  fileName: "export",
  header: null,
  onAddClick: null,
  onRowClick: null,
  onFiltersChange: undefined,
  rowStyle: null,
  title: "",
  labels: {
    resultCount: "Result count",
    exportButton: "CSV Export",
    rowCount: "Row count",
    reinitiliazeFilters: "Reset filters",
  },
  forcePageSize: undefined,
};

export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  // const count = preFilteredRows.length;

  return (
    <TextField
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`...`}
      size={"small"}
    />
  );
}

export function BooleanColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  // const count = preFilteredRows.length;
  return (
    <Select
      fullWidth
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      value={filterValue || ""}
      sx={{ height: "40px" }}
    >
      <MenuItem value={""}>Tous</MenuItem>
      <MenuItem value={"true"}>Oui</MenuItem>
      <MenuItem value={"false"}>Non</MenuItem>
    </Select>
  );
}

export function BooleanColumnFilterMethod(
  rows: any,
  id: any,
  filterValue: any
) {
  const filterValueBooleanOrEmptyString =
    filterValue === "true" ? true : filterValue === "false" ? false : "";
  if (filterValueBooleanOrEmptyString === "") {
    return rows;
  } else {
    return rows.filter((row: any) => {
      return row.values[id] === filterValueBooleanOrEmptyString;
    });
  }
}

export function BooleanColumnFilterCell({ value }: { value: boolean }) {
  const powerIcon =
    value === true ? (
      <Check sx={{ color: "green" }} />
    ) : (
      <Close sx={{ color: "red" }} />
    );
  return <div style={{ textAlign: "center" }}>{powerIcon}</div>;
}

export function NumberColumnFilterCell({ value }: { value?: number }) {
  if (value !== undefined && value !== null) {
    const powerIcon = formatAmount(value);
    return <div style={{ textAlign: "right" }}>{powerIcon}</div>;
  }
  return <div style={{ textAlign: "right" }}>-</div>;
}

export function WeightColumnFilterCell({ value }: { value?: number }) {
  if (value !== undefined && value !== null) {
    const powerIcon = formatWeight(value);
    return <div style={{ textAlign: "right" }}>{powerIcon}</div>;
  }
  return <div style={{ textAlign: "right" }}>-</div>;
}

function getExportFileBlob({ columns, data, fileType, fileName }: any) {
  if (fileType === "csv") {
    const headerNames = columns.map((col: any) => col.exportValue);
    const csvString = Papa.unparse(
      { fields: headerNames, data },
      {
        delimiter: ";",
        //  encoding: "utf-8"
      }
    );
    // https://stackoverflow.com/a/16231345
    return new Blob(["\uFEFF" + csvString], { type: "text/csv;charset=utf-8" });
  }
}

export default function MuiReactTable(props: IMuiReactTable) {
  const mergeProps = { ...defaultValues, ...props };
  const {
    autoResetPage,
    columns,
    csvExport,
    data,
    defaultState,
    fileName,
    header,
    onAddClick,
    onRowClick,
    onFiltersChange,
    rowStyle,
    title,
    labels,
    forcePageSize,
  } = mergeProps;
  const classes = useStyles();
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    rows,
    setPageSize,
    setFilter,
    setAllFilters,
    setGlobalFilter,
    state: { pageIndex, globalFilter, pageSize, filters }, // filters
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      autoResetPage: autoResetPage,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: defaultState,
      getExportFileName: () => fileName,
      getExportFileBlob: getExportFileBlob,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  );

  useEffect(() => {
    if (forcePageSize && pageSize !== forcePageSize) {
      setPageSize(forcePageSize);
    }
  }, [forcePageSize, pageSize, setPageSize]);

  useEffect(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  return (
    <>
      <TableContainer component={Paper}>
        {(title || onAddClick || header) && (
          <div className={classes.headerContainer}>
            <div className={classes.titleContainer}>
              {title && (
                <Typography component="h1" className={classes.title}>
                  {title}
                </Typography>
              )}
              {onAddClick && (
                <AddCircle
                  color="primary"
                  onClick={() => onAddClick()}
                  className={classes.addIcon}
                />
              )}
            </div>
            {header && header(setFilter, setGlobalFilter, globalFilter)}
            {onFiltersChange && (
              <Button
                color="primary"
                variant="contained"
                disabled={!filters?.length}
                onClick={() => setAllFilters([])}
              >
                {labels?.reinitiliazeFilters}
              </Button>
            )}
          </div>
        )}
        <Table size="small" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{ ...column.style, verticalAlign: "bottom" }}
                  >
                    <TableSortLabel
                      {...column.getSortByToggleProps()}
                      active={column.isSorted}
                      direction={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "desc"
                            : "asc"
                          : "asc"
                      }
                      className={classes.headerLabel}
                    >
                      {column.render("Header")}
                    </TableSortLabel>
                    <div>
                      {column.canFilter &&
                        column.Filter &&
                        column.render("Filter")}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <TableRow
                  className={classes.tr}
                  hover={true}
                  key={"row-" + i}
                  {...row.getRowProps()}
                  onClick={(e: any) =>
                    onRowClick?.(row, row.original, e, setFilter)
                  }
                  style={rowStyle?.(row, row.original)}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Typography component="p" className={classes.resultCount}>
          {labels?.resultCount}: <strong>{rows.length}</strong>
        </Typography>
      </TableContainer>

      <Grid
        container
        className={classes.paginationContainer}
        alignItems={"center"}
        justifyContent={"flex-end"}
        spacing={2}
      >
        <Grid item>
          <Grid container alignItems={"center"} spacing={2}>
            {csvExport && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    exportData("csv", false);
                  }}
                >
                  <CloudDownload />
                  &nbsp;&nbsp; {labels?.exportButton}
                </Button>
              </Grid>
            )}
            {!forcePageSize && (
              <>
                <Grid item>
                  <Typography>{labels?.rowCount}:</Typography>
                </Grid>
                <Grid item>
                  <Select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                    size={"small"}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <MenuItem key={pageSize} value={pageSize}>
                        {pageSize}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Pagination
            count={pageCount}
            page={pageIndex + 1}
            variant="outlined"
            shape="rounded"
            size="large"
            onChange={(e, value) => gotoPage(value - 1)}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paginationContainer: {
      "&.MuiGrid-root": {
        marginTop: 10,
      },
    },
    headerContainer: {
      padding: 10,
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      "&.MuiTypography-root": {
        fontSize: 24,
        marginRight: 20,
      },
    },
    addIcon: {
      cursor: "pointer",
    },
    headerLabel: {
      fontWeight: 700,
    },
    resultCount: {
      fontSize: 16,
      padding: 10,
    },
    tr: {
      cursor: "pointer",
    },
  })
);
