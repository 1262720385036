import { Info, Send } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  AfterSaleRequestStatusEnum,
  AfterSaleRequestStatusLabels,
  IAfterSaleRequest,
  IAfterSaleRequestFile,
} from "api/afterSaleRequest";
import { useFormik } from "formik";
import { formatDate } from "helpers/tools";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { reset } from "redux/reducers/order";
import {
  getAsync,
  updateAsync,
  addCommentAsync,
  validateAsync,
  refuseAsync,
} from "redux/reducers/afterSaleRequest";
import OrderSummary from "components/OrderSummary";
import RetailOrderSummary from "components/RetailOrderSummary";
import { hasRole } from "helpers/user";
import { ITempFile, uploadFiles } from "helpers/files";
import { FileWithPath } from "react-dropzone";
import { getResponseExceptionMessage } from "api/apiSettings";
import AfterSaleRequestFileUpload from "./AfterSaleRequestFileUpload";

const Edit = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  let [comment, setComment] = useState("");
  const { afterSaleRequestId } = useParams();
  const { busy, error, entity } = useSelector(
    (state: RootState) => state.afterSaleRequest
  );
  const { session } = useSelector((state: RootState) => state.auth);

  const appDispatch = useAppDispatch();
  const loadingToastId = useRef<any>(null);
  let [tempFiles, setTempFiles] = useState<ITempFile[]>([]);
  const [dropzoneKey, setDropzoneKey] = useState(Date.now());

  const loadAfterSaleRequest = useCallback(
    async (id: string) => {
      await appDispatch(getAsync(id));
    },
    [appDispatch]
  );

  useEffect(() => {
    appDispatch(reset());
    if (afterSaleRequestId) {
      (async function () {
        await loadAfterSaleRequest(afterSaleRequestId);
      })();
    }
  }, [appDispatch, afterSaleRequestId, loadAfterSaleRequest, t]);

  useEffect(() => {
    if (error) {
      loadingToastId.current = toast(t("Processing failed"), {
        type: toast.TYPE.ERROR,
        data: error,
        autoClose: false,
      });
    } else {
      if (busy) {
        loadingToastId.current = toast(t("Processing"), {
          type: toast.TYPE.INFO,
          autoClose: false,
        });
      } else {
        toast.dismiss(loadingToastId.current);
      }
    }
  }, [busy, error, t]);

  const getUploadedFiles = useCallback(async () => {
    let uploadedFiles: ITempFile[] = [];
    if (tempFiles?.length) {
      uploadedFiles = await toast.promise(uploadFiles(tempFiles), {
        pending: t("processing.files"),
        success: t("processing.files.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      setDropzoneKey(Date.now());
    }
    return uploadedFiles;
  }, [t, tempFiles]);

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: entity ? entity : ({} as IAfterSaleRequest),
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      let uploadedFiles: ITempFile[] = await getUploadedFiles();
      await appDispatch(updateAsync({ ...values, tempFiles: uploadedFiles }));
    },
  });

  const { setFieldValue, handleSubmit, values, isSubmitting, isValid } = formik;

  const sidebarTitle = useMemo(
    () =>
      entity?.reference ? `N°${entity?.reference}` : t("New factory request"),
    [entity?.reference, t]
  );

  const creationDate = useMemo(
    () =>
      entity?.creationTime
        ? formatDate(entity?.creationTime, "DD/MM/YYYY HH:mm")
        : formatDate(new Date().toISOString(), "DD/MM/YYYY HH:mm"),
    [entity?.creationTime]
  );

  const statuslineContent = useMemo(() => {
    return entity?.statusLines
      ? entity?.statusLines.map((statusLine) => {
          return (
            <Grid item key={statusLine.id} className={classes.statusLine}>
              <Typography variant={"caption"}>
                <strong>
                  {formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm")}
                </strong>{" "}
                ({statusLine.creatorUser.name}) -{" "}
                {AfterSaleRequestStatusLabels[statusLine.status]}
              </Typography>
              {statusLine.comment && statusLine.comment !== "" ? (
                <Typography variant={"caption"} className={classes.multilines}>
                  {" - "}
                  {statusLine.comment}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          );
        })
      : [];
  }, [classes.multilines, classes.statusLine, entity?.statusLines]);

  const handleAddComment = useCallback(async () => {
    if (entity?.id && entity?.id !== "" && comment && comment !== "") {
      await appDispatch(
        addCommentAsync({ afterSaleRequestId: entity?.id, comment })
      );
      setComment("");
    }
  }, [appDispatch, comment, entity?.id]);

  const handleValidate = useCallback(async () => {
    if (entity) {
      let uploadedFiles: ITempFile[] = await getUploadedFiles();
      await appDispatch(validateAsync({ ...entity, tempFiles: uploadedFiles }));
    }
  }, [appDispatch, entity, getUploadedFiles]);

  const handleRefuse = useCallback(async () => {
    if (entity) {
      await appDispatch(refuseAsync(entity));
    }
  }, [appDispatch, entity]);

  const isEditable = useMemo(
    () => entity?.status === AfterSaleRequestStatusEnum.Draft,
    [entity?.status]
  );

  const canValidate = useMemo(
    () =>
      hasRole(session.user, "AdminPLV") &&
      (entity?.status === AfterSaleRequestStatusEnum.Draft ||
        entity?.status === AfterSaleRequestStatusEnum.Pending),
    [entity?.status, session.user]
  );

  const canAddComment = useMemo(() => {
    return isEditable || canValidate;
  }, [canValidate, isEditable]);

  const validateLabel = useMemo(() => {
    switch (entity?.status) {
      case AfterSaleRequestStatusEnum.Draft:
        return t("Validate support");
      case AfterSaleRequestStatusEnum.Pending:
        return t("Validate processing");
    }
    return t("Validate");
  }, [entity?.status, t]);

  const handleFileChange = useCallback((fileList: FileWithPath[]) => {
    const result: ITempFile[] = fileList.map((item) => {
      return { file: item, originalFileName: item.name, path: item.path ?? "" };
    });
    setTempFiles(result);
  }, []);

  const handleOnDeleteFile = useCallback(
    (file: IAfterSaleRequestFile) => {
      setFieldValue(
        "files",
        values.files?.filter((o) => o.fileId !== file.fileId)
      );
    },
    [setFieldValue, values.files]
  );

  return entity ? (
    <Grid
      container
      sx={{
        flexDirection: "row",
        padding: "15px",
        flex: 1,
      }}
      spacing={3}
    >
      <Grid item xs={12} lg={9}>
        <Grid
          container
          sx={{
            flexDirection: "column",
            height: "100%",
            flexWrap: "nowrap",
          }}
        >
          <Grid item sx={{ flex: 1 }}>
            <Grid
              container
              sx={{
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Grid container sx={{ flexDirection: "column" }} spacing={2}>
                  <Grid
                    item
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Paper elevation={2} sx={{ padding: "20px" }}>
                      <Typography variant="h2" sx={{ fontSize: "36px" }}>
                        {t("After sale request")} n° {entity?.reference}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item>
                    {entity?.order && <OrderSummary order={entity?.order} />}
                    {entity?.retailOrder && (
                      <RetailOrderSummary order={entity?.retailOrder} />
                    )}
                  </Grid>
                  <Grid item>
                    <AfterSaleRequestFileUpload
                      dropzoneKey={dropzoneKey}
                      isEditable={canAddComment}
                      onChange={handleFileChange}
                      onDelete={handleOnDeleteFile}
                      files={values.files}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting || !isValid || !isEditable}
                      // type="submit"
                      onClick={() => handleSubmit()}
                      fullWidth
                    >
                      {t("Update")}
                    </Button>
                  </Grid>
                  {canValidate && (
                    <Grid item sx={{ textAlign: "right" }}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            color="secondary"
                            variant="contained"
                            disabled={isSubmitting || !isValid || !canValidate}
                            onClick={() => handleRefuse()}
                            fullWidth
                          >
                            {t("Refuse")}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting || !isValid || !canValidate}
                            onClick={() => handleValidate()}
                            fullWidth
                          >
                            {validateLabel}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper elevation={2}>
          <Grid
            container
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Grid item>
              <Grid container sx={{ flexDirection: "column" }}>
                <Grid item sx={{ textAlign: "center", padding: "5px" }}>
                  <Typography variant="h6">{sidebarTitle}</Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <List>
                    <ListItem dense key={t("Creation date")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Creation date")}
                        secondary={creationDate}
                      />
                    </ListItem>
                    <ListItem dense key={t("Reason")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Reason")}
                        secondary={entity?.reason}
                      />
                    </ListItem>
                    <ListItem dense key={t("Status")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Status")}
                        secondary={AfterSaleRequestStatusLabels[entity?.status]}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item sx={{ padding: "10px" }}>
                  <Typography sx={{ fontStyle: "italic" }}>
                    {t("History")}
                  </Typography>
                  {canAddComment && (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("Comment")}
                      name="comment"
                      type="text"
                      inputProps={{
                        autoComplete: "new-comment",
                      }}
                      variant="outlined"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={handleAddComment}>
                            <Send />
                          </IconButton>
                        ),
                      }}
                      sx={{ marginTop: "10px" }}
                    />
                  )}
                  <Grid
                    container
                    sx={{
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: "250px",
                      width: "100%",
                      overflowY: "scroll",
                      marginTop: "10px",
                      flexWrap: "nowrap",
                    }}
                  >
                    {statuslineContent}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <LoadingScreen />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusLine: {
      width: "95%",
      borderBottom: "1px solid lightgrey",
    },
    multilines: {
      whiteSpace: "break-spaces",
    },
  })
);
export default Edit;
