import { RemoveRedEye } from "@mui/icons-material";
import { Grid, Typography, Chip } from "@mui/material";
import { IAfterSaleRequestFile } from "api/afterSaleRequest";
import Dropzone from "components/Dropzone";
import InfoModal, { IInfoModal } from "components/InfoModal";
import {
  IMAGE_EXTENSIONS,
  getFileUrlFromObject,
  PDF_EXTENSIONS,
  getPdfViewerUrlFromObject,
  MAX_SIZE,
} from "helpers/files";
import { Fragment, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "contexts/ModalContext";
import { FileWithPath } from "react-dropzone";

interface IProps {
  dropzoneKey: any;
  isEditable: boolean;
  onChange: (files: FileWithPath[]) => any;
  onDelete?: (file: IAfterSaleRequestFile) => any;
  files?: IAfterSaleRequestFile[];
}

const AfterSaleRequestFileUpload = (props: IProps) => {
  const { dropzoneKey, isEditable, onChange, onDelete, files } = props;
  const { t } = useTranslation(["common"]);
  const { addModal } = useContext(ModalContext);

  const handleViewFile = useCallback(
    (file: IAfterSaleRequestFile) => {
      const fileNameSplit = file.file.originalFileName.split(".");
      const fileExtension = fileNameSplit[fileNameSplit.length - 1];
      if (IMAGE_EXTENSIONS.findIndex((o) => o === `.${fileExtension}`) !== -1) {
        const imgUrl = getFileUrlFromObject(file.file);
        const modal: IInfoModal = {
          component: InfoModal,
          props: {
            fullScreen: true,
            children: (
              <div>
                <img src={imgUrl} alt="" />
              </div>
            ),
          },
        };
        addModal(modal);
      } else if (
        PDF_EXTENSIONS.findIndex((o) => o === `.${fileExtension}`) !== -1
      ) {
        const pdfUrl = getPdfViewerUrlFromObject(file.file);
        window?.open?.(pdfUrl, "_blank")?.focus();
      } else {
      }
    },
    [addModal]
  );

  const handleDelete = useCallback(
    (e: Event, file: IAfterSaleRequestFile) => {
      if (onDelete) {
        e.preventDefault();
        e.stopPropagation();
        onDelete(file);
      }
    },
    [onDelete]
  );

  return (
    <Grid container spacing={2}>
      {isEditable && (
        <Grid item xs={12} md={6}>
          <Typography variant={"h5"} paragraph>
            {t("File upload")}
          </Typography>
          <Dropzone
            // define a key that will re-render the component after files update
            key={dropzoneKey}
            accept={{
              "image/*": IMAGE_EXTENSIONS,
              "application/pdf": PDF_EXTENSIONS,
            }}
            maxSize={MAX_SIZE}
            onChange={onChange}
            multiple={true}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Typography variant={"h5"} paragraph>
          {t("Uploaded files")}
        </Typography>
        <Grid container spacing={1}>
          {files?.map((file, index) =>
            file.file ? (
              <Grid item key={`file-${index}`}>
                <Chip
                  icon={<RemoveRedEye />}
                  label={file.file.originalFileName}
                  onClick={() => handleViewFile(file)}
                  onDelete={
                    files.length > 1
                      ? (e: Event) => handleDelete(e, file)
                      : undefined
                  }
                />
              </Grid>
            ) : (
              <Fragment key={`file-${index}`}></Fragment>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AfterSaleRequestFileUpload;
