import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  getByFromUserIdAndRoleAsync,
  IUserToUser,
  service,
} from "api/userToUser";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { IUser } from "types/IUser";
import UserSelector from "components/UserSelector";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useNavigate } from "react-router-dom";
import { DeleteForever } from "@mui/icons-material";

interface IProps {
  entity: IUserToUser;
}

const EditChild = (props: IProps) => {
  const { entity } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  const [localEntity, setLocalEntity] = useState<IUserToUser>(entity);
  const [childEntity, setChildEntity] = useState<IUserToUser>();

  const loadChildEntity = useCallback(async () => {
    const apiResult = await toast.promise(
      getByFromUserIdAndRoleAsync(localEntity.toUserId, localEntity.toRole), // load where "fromUserId" is "toUserId" of current item
      {
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      }
    );
    if (apiResult?.result?.id) {
      setChildEntity(apiResult.result);
    }
  }, [localEntity.toRole, localEntity.toUserId]);

  const deleteEntity = useCallback(async () => {
    if (entity.id) {
      const apiResult = await toast.promise(service.delete({ id: entity.id }), {
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.success) {
        navigate(`/userToUsers`);
      }
    }
  }, [entity.id, navigate]);

  useEffect(() => {
    (async function () {
      await loadChildEntity();
    })();
  }, [loadChildEntity]);

  const validationSchema = yup.object({
    fromUserId: yup
      .string()
      .required(t("field_name.required", { field_name: t("From userId") })),
    fromRole: yup
      .string()
      .required(t("field_name.required", { field_name: t("From role") })),
    toUserId: yup
      .string()
      .required(t("field_name.required", { field_name: t("To userId") })),
    toRole: yup
      .string()
      .required(t("field_name.required", { field_name: t("To role") })),
  });

  const formik = useFormik({
    initialValues: localEntity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const apiResult = await toast.promise(service.update(values), {
        // pending: t("processing"),
        success: t("processing.success"),
        // error: t("processing.error"),
      });
      if (apiResult?.result?.id) {
        setLocalEntity(apiResult.result);
      }
    },
  });

  const {
    setValues,
    setFieldValue,
    handleSubmit,
    // touched,
    // errors,
    values,
    // handleBlur,
    // handleChange,
    isSubmitting,
  } = formik;

  const handleFromUserChange = useCallback(
    async (users: IUser[]) => {
      if (users?.[0] && users?.[0]?.id !== values?.fromUser?.id) {
        setValues({
          ...values,
          fromUser: users[0],
          fromUserId: users[0].id.toString(),
          fromEmail: users[0].emailAddress,
          fromRole: users[0].roleNames?.[0] || "",
        });
      }
    },
    [setValues, values]
  );

  const handleToUserChange = useCallback(
    async (users: IUser[]) => {
      if (users?.[0] && users?.[0]?.id !== values?.toUser?.id) {
        setValues({
          ...values,
          toUser: users[0],
          toUserId: users[0].id.toString(),
          toEmail: users[0].emailAddress,
          toRole: users[0].roleNames?.[0] || "",
        });
      }
    },
    [setValues, values]
  );

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container flexDirection="column" spacing={2}>
              <Grid item>
                <Typography>From</Typography>
              </Grid>
              <Grid item>
                <UserSelector
                  key="fromUserSelector"
                  onChange={handleFromUserChange}
                  initialValues={values?.fromUser ? [values.fromUser] : []}
                  maxCount={1}
                />
              </Grid>
              <Grid item>
                <Select
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("fromRole", e.target.value);
                  }}
                  value={values?.fromRole || ""}
                  sx={{ height: "40px" }}
                >
                  {values?.fromUser?.roleNames.map((roleName) => (
                    <MenuItem key={roleName} value={roleName}>
                      {roleName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container flexDirection="column" spacing={2}>
              <Grid item>
                <Typography>To</Typography>
              </Grid>
              <Grid item>
                <UserSelector
                  key="toUserSelector"
                  onChange={handleToUserChange}
                  initialValues={values?.toUser ? [values.toUser] : []}
                  maxCount={1}
                />
              </Grid>
              <Grid item>
                <Select
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("toRole", e.target.value);
                  }}
                  value={values?.toRole}
                  sx={{ height: "40px" }}
                >
                  {values?.toUser?.roleNames.map((roleName) => (
                    <MenuItem key={roleName} value={roleName}>
                      {roleName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "10px" }}>
          <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
            <Grid item>
              <IconButton onClick={deleteEntity}>
                <DeleteForever />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                type="submit"
              >
                {t("common|button.validate")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      {childEntity && (
        <Box
          key={childEntity.id}
          sx={{
            marginLeft: "20px",
          }}
        >
          <EditChild entity={childEntity} />
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default EditChild;
