import { Button, Grid, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { duplicate, IMerch } from "api/merch";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useNavigate } from "react-router-dom";

interface IProps {
  merch: IMerch;
  onSubmit?: Function;
}

const Duplicate = (props: IProps) => {
  const { merch, onSubmit } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();

  const validationSchema = yup.object({
    reference: yup
      .string()
      .required(t("field_name.required", { field_name: t("Reference") })),
    label: yup
      .string()
      .required(t("field_name.required", { field_name: t("Label") })),
  });

  const formik = useFormik({
    initialValues: { reference: merch.reference, label: merch.label },
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        const apiResult = await toast.promise(
          duplicate({ merchId: merch.id, ...values }),
          {
            pending: t("processing"),
            success: t("processing.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          }
        );
        if (apiResult?.data?.result) {
          console.log(apiResult.data.result);
          navigate(`/catalog/merchs/${apiResult.data.result}`);
          onSubmit?.();
        }
      } catch (error) {}
    },
  });

  const {
    handleSubmit,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
  } = formik;

  return (
    <div className={classes.container}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            label={t("Reference")}
            name="reference"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.reference}
            error={touched.reference && Boolean(errors.reference)}
            helperText={touched.reference && errors.reference}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            label={t("Label")}
            name="label"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.label}
            error={touched.label && Boolean(errors.label)}
            helperText={touched.label && errors.label}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Button
            color="primary"
            disabled={isSubmitting}
            size="large"
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
          >
            {t("Duplicate")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
    },
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);
export default Duplicate;
