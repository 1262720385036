import { Button, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IMerch, importMerchs, service } from "api/merch";
import MuiReactTable, {
  BooleanColumnFilter,
  BooleanColumnFilterCell,
  BooleanColumnFilterMethod,
  IFilter,
} from "components/MuiReactTable";
import { hasPermission } from "helpers/user";
import { toast } from "react-toastify";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setForm, selectFormById } from "redux/reducers/forms";
import { RootState, useAppDispatch } from "redux/store";
import ModalImport, { IModalImport } from "./ModalImport";
import { ModalContext } from "contexts/ModalContext";
import { generateKey } from "helpers/tools";
import { upload } from "api/files";
import LoadingScreen from "components/LoadingScreen";

const FORM_ID = "merchs"; // used to save filter values

const List = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  const { session } = useSelector((state: RootState) => state.auth);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);
  let [merchs, setMerchs] = useState<IMerch[]>([]);
  const { addModal } = useContext(ModalContext);
  let [busy, setBusy] = useState(false);
  let [importFileInputKey, setImportFileInputKey] = useState(
    generateKey("importFileInputKey")
  );

  const load = useCallback(async () => {
    const apiResult = await toast.promise(service.getAll(), {
      pending: {
        render() {
          return t("common|Loading");
        },
      },
      // success: t("common|loading.success"),
      error: {
        render({ data }: any) {
          return getResponseExceptionMessage(data);
        },
        autoClose: false,
      },
    });
    if (apiResult?.success && apiResult.result) {
      // Sort entities to have inactive merch at the end & ordered by creationTime
      // const entities = linq
      //   .from(apiResult.result.items)
      //   .orderBy((o) => o.isActive === false)
      //   .thenByDescending((o) => o.creationTime)
      //   // .thenBy((o) => o.reference.toLowerCase()) // order by reference is case sensitive
      //   .toArray();
      // setMerchs(entities);
      setMerchs(apiResult.result.items);
    }
  }, [t]);

  useEffect(() => {
    (async function () {
      await load();
    })();
  }, [load, t]);

  const handleRowClick = useCallback(
    (row: any, data: IMerch, event: any) => {
      navigate(`/catalog/merchs/${data.id}`);
    },
    [navigate]
  );

  const handleAddClick = useCallback(() => {
    navigate(`/catalog/merchs/create`);
  }, [navigate]);

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  const columns = useMemo(
    () => [
      { Header: t("Reference"), accessor: "reference" },
      { Header: t("Label"), accessor: "label" },
      { Header: t("Company"), accessor: "company.label" },
      { Header: t("Furniture"), accessor: "furniture.label" },
      { Header: t("Depth"), accessor: "depth.label" },
      { Header: t("Width"), accessor: "width.label" },
      { Header: t("Configuration"), accessor: "configuration.label" },
      { Header: t("CIP"), accessor: "cip" },
      { Header: t("Price"), accessor: "price" },
      {
        Header: t("column.isActive"),
        accessor: "isActive",
        Filter: BooleanColumnFilter,
        filter: BooleanColumnFilterMethod,
        Cell: BooleanColumnFilterCell,
      },
    ],
    [t]
  );

  const onFileDownloaded = useCallback(
    async (path: string) => {
      const apiResult = await importMerchs({
        filePath: path,
      });
      const modal: IModalImport = {
        component: ModalImport,
        props: {
          title: t("Merch import"),
          result: apiResult.data.result,
          filePath: path,
          dialogProps: {
            onClose: async () => {
              await load();
            },
          },
        },
      };
      addModal(modal);
    },
    [addModal, load, t]
  );

  const handleImportFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setBusy(true);
        const files = e?.target?.files;
        if (files && files?.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            formData.append(file.name, file);
            formData.set("path", "/importUsers");
          }
          const uploadApiResult = await upload(formData);
          if (
            uploadApiResult?.data?.success &&
            uploadApiResult?.data?.result?.path
          ) {
            await onFileDownloaded(uploadApiResult?.data?.result?.path);
          } else {
            toast(uploadApiResult.data.error);
          }
        }
      } catch (error: any) {
        toast(error.toString(), { type: "error" });
      } finally {
        setImportFileInputKey(generateKey("importFileInputKey"));
        setBusy(false);
      }
    },
    [onFileDownloaded]
  );

  const muiTableHeader = useCallback(() => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <input
          key={importFileInputKey}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={(e) => handleImportFile(e)}
        />
        <label htmlFor="raised-button-file">
          {hasPermission(session.user, "Merch.Create") === true && (
            <Button variant="contained" component="span">
              {t("Import")}
            </Button>
          )}
        </label>
      </div>
    );
  }, [handleImportFile, importFileInputKey, t]);

  return (
    <div className={classes.container}>
      <MuiReactTable
        title={t("Merchs")}
        header={muiTableHeader}
        columns={columns}
        data={merchs}
        onRowClick={
          hasPermission(session.user, "Merch.Get") === false
            ? undefined
            : handleRowClick
        }
        onAddClick={
          hasPermission(session.user, "Merch.Create") === false
            ? undefined
            : handleAddClick
        }
        csvExport={true}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
        onFiltersChange={handleFiltersChange}
      />
      {busy && <LoadingScreen />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);
export default List;
