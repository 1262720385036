import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { defaultEntity, service } from "api/store";
import { toast } from "react-toastify";
import { hasPermission } from "helpers/user";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResponseExceptionMessage } from "api/apiSettings";

const Edit = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [entity, setEntity] = useState(defaultEntity);
  const { session } = useSelector((state: RootState) => state.auth);
  const { storeId } = useParams();

  useEffect(() => {
    (async function () {
      if (storeId) {
        const apiResult = await toast.promise(service.get({ id: storeId }), {
          pending: {
            render() {
              return t("loading");
            },
          },
          // success: t("loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.result?.id) {
          setEntity(apiResult.result);
        }
      }
    })();
  }, [storeId, t]);

  const validationSchema = yup.object({
    reference: yup
      .string()
      .required(t("field_name.required", { field_name: t("Reference") })),
    label: yup
      .string()
      .required(t("field_name.required", { field_name: t("Label") })),
    address: yup
      .string()
      .required(t("field_name.required", { field_name: t("Address") })),
    city: yup
      .string()
      .required(t("field_name.required", { field_name: t("City") })),
    postalCode: yup
      .string()
      .required(t("field_name.required", { field_name: t("Postal code") })),
    contactEmail: yup
      .string()
      .required(t("field_name.required", { field_name: t("Contact email") })),
    contactName: yup
      .string()
      .required(t("field_name.required", { field_name: t("Contact name") })),
    contactPhoneNumber: yup
      .string()
      .required(
        t("field_name.required", { field_name: t("Contact phone number") })
      ),
  });

  const formik = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      await toast.promise(service.update(values), {
        pending: t("processing"),
        success: t("processing.success"),
        error: t("processing.error"),
      });
    },
  });

  return (
    <div className={classes.container}>
      <form
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="isActive"
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                  color="primary"
                />
              }
              label={t("Enabled")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Edit store")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            {hasPermission(session.user, "Store.Update") === true && (
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                type="submit"
              >
                {t("button.validate")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Reference")}
              name="reference"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Label")}
              name="label"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.label}
              error={formik.touched.label && Boolean(formik.errors.label)}
              helperText={formik.touched.label && formik.errors.label}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Address")}
              name="address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("City")}
              name="city"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Postal code")}
              name="postalCode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.postalCode}
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Contact email")}
              name="contactEmail"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contactEmail}
              error={
                formik.touched.contactEmail &&
                Boolean(formik.errors.contactEmail)
              }
              helperText={
                formik.touched.contactEmail && formik.errors.contactEmail
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Contact name")}
              name="contactName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contactName}
              error={
                formik.touched.contactName && Boolean(formik.errors.contactName)
              }
              helperText={
                formik.touched.contactName && formik.errors.contactName
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("Contact phone number")}
              name="contactPhoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contactPhoneNumber}
              error={
                formik.touched.contactPhoneNumber &&
                Boolean(formik.errors.contactPhoneNumber)
              }
              helperText={
                formik.touched.contactPhoneNumber &&
                formik.errors.contactPhoneNumber
              }
              variant="filled"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default Edit;
