import {
  CalendarMonth,
  DataSaverOff,
  HomeMax,
  ListAlt,
  Lock,
  Logout,
  Menu as MenuIcon,
  People,
  Percent,
  Person,
  ProductionQuantityLimits,
  Settings,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Toolbar,
  Menu,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import { accessTokenName, encryptedAccessTokenName } from "api/authentication";
import { deleteCookie } from "helpers/cookie";
import React, { useCallback, useState } from "react";
import { reset } from "redux/reducers/auth";
import { RootState, useAppDispatch } from "redux/store";
import logo from "images/logo.png";
import { makeStyles, createStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  matchPath,
} from "react-router-dom";
import { Location as HistoryLocation } from "history";
import enFlag from "../../images/icons/en-flag.svg";
import frFlag from "../../images/icons/fr-flag.svg";
import useRoutes, { IRoute } from "hooks/useRoutes";
import Breadcrumbs from "components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { update } from "api/account";
import { hasOneOfPermissions } from "helpers/user";

const drawerWidth = 260;

const Home = () => {
  const appDispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["common"]);
  const { routes, routesFlattened } = useRoutes();
  let navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { session } = useSelector((state: RootState) => state.auth);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const handleLogout = useCallback(() => {
    deleteCookie(accessTokenName);
    deleteCookie(encryptedAccessTokenName);
    appDispatch(reset());
    navigate("/login");
  }, [appDispatch, navigate]);

  const drawer = (
    <div className={classes.drawerContainer}>
      <div>
        <Toolbar className={classes.toolbarDrawer}>
          <img src={logo} className={classes.logo} alt="" />
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            dense
            button
            key={"Accueil"}
            onClick={() => handleMenu("/")}
          >
            <ListItemIcon>
              <HomeMax />
            </ListItemIcon>
            <ListItemText primary={t("menu.home")} />
          </ListItem>
          <Typography variant="h6" className={classes.menuCategory}>
            {t("Lists")}
          </Typography>
          {hasOneOfPermissions(session.user, ["Order.Menu"]) && (
            <>
              {" "}
              <ListItem
                dense
                button
                key={"Commandes"}
                onClick={() => handleMenu("/orders")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.orders")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["FactoryRequest.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Factory"}
                onClick={() => handleMenu("/factoryRequests")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.factoryRequests")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["TransportRequest.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Demande de transport"}
                onClick={() => handleMenu("/transportRequests")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.transports")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["RetailOrder.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Commandes au détail"}
                onClick={() => handleMenu("/retailOrders")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.retailOrders")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["AfterSaleRequest.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"afterSaleRequests"}
                onClick={() => handleMenu("/afterSaleRequests")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.afterSaleRequests")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, [
            "Pages.Catalog",
            "Store.Menu",
            "Factory.Menu",
            "Deliverer.Menu",
            "Pages.Planning",
            "Pages.Billing",
            "Stock.Menu",
            "Pages.Users",
            "Pages.Roles",
          ]) && (
            <Typography variant="h6" className={classes.menuCategory}>
              {t("Application")}
            </Typography>
          )}
          {hasOneOfPermissions(session.user, ["Pages.Catalog"]) && (
            <>
              <ListItem
                button
                dense
                key={"Catalog"}
                onClick={() => handleMenu("/catalog")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.catalog")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Store.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Magasins"}
                onClick={() => handleMenu("/stores")}
              >
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary={t("menu.stores")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Factory.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Usines"}
                onClick={() => handleMenu("/factories")}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary={t("menu.factories")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Deliverer.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Transporteurs"}
                onClick={() => handleMenu("/deliverers")}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary={t("menu.deliverers")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Pages.Planning"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Planning"}
                onClick={() => handleMenu("/planning")}
              >
                <ListItemIcon>
                  <CalendarMonth />
                </ListItemIcon>
                <ListItemText primary={t("menu.planning")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Pages.Billing"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Billing"}
                onClick={() => handleMenu("/billing")}
              >
                <ListItemIcon>
                  <Percent />
                </ListItemIcon>
                <ListItemText primary={t("menu.billing")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Stock.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Stocks"}
                onClick={() => handleMenu("/stocks")}
              >
                <ListItemIcon>
                  <ProductionQuantityLimits />
                </ListItemIcon>
                <ListItemText primary={t("menu.stocks")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Pages.Users"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Utilisateurs"}
                onClick={() => handleMenu("/users")}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary={t("menu.users")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["UserToUser.Menu"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Hiérarchie utilisateurs"}
                onClick={() => handleMenu("/userToUsers")}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary={t("menu.userToUsers")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Pages.Roles"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Roles"}
                onClick={() => handleMenu("/roles")}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary={t("menu.roles")} />
              </ListItem>
            </>
          )}
          {hasOneOfPermissions(session.user, ["Pages.Roles"]) && (
            <>
              <Divider variant="middle" component="li" />
              <ListItem
                dense
                button
                key={"Permissions"}
                onClick={() => handleMenu("/permissions")}
              >
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary={t("menu.permissions")} />
              </ListItem>
            </>
          )}
        </List>
      </div>
      <div>
        <Divider />
        <List>
          {hasOneOfPermissions(session.user, ["Pages.Logs"]) && (
            <ListItem
              dense
              button
              key={"Logs"}
              onClick={() => handleMenu("/logs")}
            >
              <ListItemIcon>
                <DataSaverOff />
              </ListItemIcon>
              <ListItemText primary={"Logs"} />
            </ListItem>
          )}
          <ListItem
            dense
            button
            key={"Mon compte"}
            onClick={() => handleMenu("/account")}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary={t("menu.account")} />
          </ListItem>
          <ListItem
            dense
            button
            key={"Déconnexion"}
            onClick={() => handleLogout()}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={t("menu.logout")} />
          </ListItem>
        </List>
        <p className={classes.appVersion}>
          <small>v{session?.application?.version}</small>
        </p>
      </div>
    </div>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = async (language: string) => {
    i18n.changeLanguage(language);
    //TODO: implement change on server side
    try {
      await update(session.user.id, { languageName: language });
    } catch (error) {
      alert(error);
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleChangeLanguage("en-GB");
          handleMenuClose();
        }}
      >
        <img src={enFlag} alt="EN" className={classes.icon} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleChangeLanguage("fr-FR");
          handleMenuClose();
        }}
      >
        <img src={frFlag} alt="FR" className={classes.icon} />
      </MenuItem>
    </Menu>
  );

  function getCrumbChildren(route: IRoute, location: HistoryLocation) {
    let result: {
      route: IRoute;
      match: any;
      location: HistoryLocation;
    }[] = [];
    if (route.children?.length) {
      route.children.every((routeChild, index) => {
        const match = matchPath(
          { path: routeChild.path, end: false },
          location.pathname
        );
        if (match) {
          result.push({ route: routeChild, match, location });
          result = [...result, ...getCrumbChildren(routeChild, location)];
          return false; // stops the loop
        }
        return true; // equivalent of continue;
      });
    }
    return result;
  }

  const BreadcrumbsComponent = () => {
    let crumbItems: {
      route: IRoute;
      match: any;
      location: HistoryLocation;
    }[] = [];

    routes.every((route, index) => {
      const match = matchPath(
        { path: route.path, end: false },
        location.pathname
      );
      if (match) {
        crumbItems = [
          { route, match, location },
          ...getCrumbChildren(route, location),
        ];
        return false; // stops the loop
      }
      return true; // equivalent of continue;
    });

    const crumbs: IRoute[] = [];
    crumbItems.forEach((crumbItem, index) => {
      let newCrumbItem = {
        ...crumbItem.route,
        path: crumbItem.match.pathname,
        name: crumbItem.route.name,
      };

      if (
        crumbItem.route.displayParamValue &&
        crumbItem.route.displayParamValue === true
      ) {
        const routeSplit = crumbItem.route.path.split(":");
        const lastRouteSplitPart = routeSplit[routeSplit.length - 1];
        const matchHasParam =
          crumbItem.match.params.hasOwnProperty(lastRouteSplitPart);
        const nameSuffix = matchHasParam
          ? ` ${crumbItem.match.params[lastRouteSplitPart]}`
          : "";
        newCrumbItem.name = newCrumbItem.name + nameSuffix;
      }

      crumbs.push(newCrumbItem);
    });

    return <Breadcrumbs crumbs={crumbs} />;
  };

  return (
    <Box className={classes.container}>
      <CssBaseline />
      <AppBar className={classes.appBar} color={"primary"}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {!smallScreen && (
            <div className={classes.breadcrumbContainer}>
              <BreadcrumbsComponent />
              {/* <Routes>
                {routes.map(({ path, name }: any, key) => {
                  return (
                    <Route
                      path={path}
                      key={key}
                      element={<BreadcrumbsComponent />}
                    />
                  );
                })}
              </Routes> */}
            </div>
          )}
          <div style={{ paddingLeft: 20 }}>
            <IconButton
              size="small"
              edge="end"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              // color="inherit"
              sx={{ background: "white", "&:hover": { background: "white" } }}
            >
              {i18n.language === "fr-FR" && (
                <img src={frFlag} alt="FR" className={classes.icon} />
              )}
              {i18n.language === "en-GB" && (
                <img src={enFlag} alt="EN" className={classes.icon} />
              )}
            </IconButton>
            {renderMenu}
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav" className={classes.drawerWrapper}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.temporaryDrawer}
        >
          {drawer}
        </Drawer>
        <Drawer variant="permanent" className={classes.permanentDrawer} open>
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" className={classes.mainContainer}>
        {/* {smallScreen && <Toolbar />} */}
        <Toolbar />
        <Routes>
          {routesFlattened.map(({ path, name, component }: any, key) => {
            return <Route path={path} key={key} element={component} />;
          })}
        </Routes>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      height: "100%",
    },
    appBar: {
      "&.MuiPaper-root": {
        position: "fixed",
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down("sm")]: {
          width: `100%`,
          marginLeft: "unset",
        },
        "& .MuiToolbar-root": {
          justifyContent: "space-between",
        },
      },
    },
    logo: {
      margin: 5,
      height: 35,
    },
    menuButton: {
      "&.MuiButtonBase-root": {
        marginRight: 16,
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
    },
    breadcrumbContainer: {
      overflow: "hidden",
      "& ol": {
        flexWrap: "nowrap",
      },
      "& li": {
        whiteSpace: "nowrap",
      },
      "& li:last-child, li:last-child p": {
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    drawerWrapper: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    temporaryDrawer: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth,
      },
    },
    permanentDrawer: {
      color: "red",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth,
      },
    },
    drawerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "& .MuiSvgIcon-root": {
        width: 30,
        color: theme.palette.secondary.contrastText,
      },
      "& .MuiTypography-root": {
        fontWeight: "bold",
      },
    },
    menuCategory: {
      "&.MuiTypography-root": {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 10,
      },
    },
    toolbarDrawer: {
      backgroundColor: theme.palette.secondary.main,
      justifyContent: "center",
    },
    icon: {
      width: 20,
    },
    mainContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    appVersion: {
      textAlign: "center",
      margin: 0,
    },
  })
);

export default Home;
