import { Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import {
  IRetailOrderProduct,
  StepEnum,
  updateAsync,
  updateProducts,
  updateStep,
} from "redux/reducers/retailOrder";
import ProductsSelector from "../ProductsSelector";
import { IMerchProduct } from "api/merch";
import { RetailOrderStatusEnum } from "api/retailOrder";

const Products = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const appDispatch = useAppDispatch();
  const retailOrderStore = useSelector((state: RootState) => state.retailOrder);
  const { products } = useSelector((state: RootState) => state.retailOrder);

  const handleValidate = useCallback(async () => {
    let toUpdateRetailOrderStore = { ...retailOrderStore };
    toUpdateRetailOrderStore.status = RetailOrderStatusEnum.Draft;

    await appDispatch(updateAsync(toUpdateRetailOrderStore));
  }, [appDispatch, retailOrderStore]);

  const handleProductListChange = useCallback(
    (merchProducts: IMerchProduct[]) => {
      let productList: IRetailOrderProduct[] = merchProducts.map((product) => {
        return { product: product.product, quantity: product.quantity };
      });
      appDispatch(updateProducts(productList));
    },
    [appDispatch]
  );

  const productList = useMemo(
    () =>
      products.map((retailOrderProduct) => {
        return {
          product: retailOrderProduct.product,
          quantity: retailOrderProduct.quantity,
          id: retailOrderProduct.product.id,
        } as IMerchProduct;
      }),
    [products]
  );

  return (
    <Grid container className={classes.container}>
      <Grid item sx={{ flex: 1 }}>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid item sx={{ textAlign: "center", width: "100%" }}>
            <Typography variant="h2">{t("Product selection")}</Typography>
          </Grid>
          <Grid item sx={{ marginTop: "30px", width: "100%", height: "530px" }}>
            <ProductsSelector
              selectedProductList={productList}
              onChange={handleProductListChange}
              useRetailPrice={true}
              limitedProducts={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => appDispatch(updateStep(StepEnum.Store))}
            >
              {t("Retour au choix du magasin")}
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={!products || products?.length === 0}
              onClick={handleValidate}
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexDirection: "column",
      height: "100%",
      alignContent: "space-between",
    },
  })
);
export default Products;
