import { useCallback, useMemo } from "react";

export default function useConfig() {
  const getConfigValue = useCallback((configName: string) => {
    return (window as any)?.config?.[configName];
  }, []);

  const refusedReasons: string[] | undefined = useMemo(
    () => getConfigValue("refusedReasons"),
    [getConfigValue]
  );

  const savReasons: string[] | undefined = useMemo(
    () => getConfigValue("savReasons"),
    [getConfigValue]
  );

  const videosUrl: string | undefined = useMemo(
    () => getConfigValue("videosUrl"),
    [getConfigValue]
  );

  return { getConfigValue, refusedReasons, savReasons, videosUrl };
}
