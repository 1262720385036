import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useEffect, useRef, useState } from "react";
import TabPanel from "../../../components/TabPanel";
import { defaultEntity, service } from "api/merch";
import { ICompany, service as companyService } from "api/company";
import {
  IConfiguration,
  service as configurationService,
} from "api/configuration";
import { IDepth, service as depthService } from "api/depth";
import { IFurniture, service as furnitureService } from "api/furniture";
import { IWidth, service as widthService } from "api/width";
import { toast } from "react-toastify";

export const MIN_PRICE = 0;
export const MAX_PRICE = 100000;

const Create = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  let [tabIndex, setTabIndex] = useState(0);
  let [entities, setEntities] = useState({
    companies: [] as ICompany[],
    furnitures: [] as IFurniture[],
    depths: [] as IDepth[],
    configurations: [] as IConfiguration[],
    widths: [] as IWidth[],
  });

  const loadingToastId = useRef<any>(null);
  useEffect(() => {
    (async function () {
      try {
        loadingToastId.current = toast(t("loading"), {
          type: toast.TYPE.INFO,
          autoClose: false,
        });
        const companies = await companyService.getAll({ isActive: true });
        const configurations = await configurationService.getAll({
          isActive: true,
        });
        const furnitures = await furnitureService.getAll({ isActive: true });
        const depths = await depthService.getAll({ isActive: true });
        const widths = await widthService.getAll({ isActive: true });
        if (
          companies?.result?.items &&
          configurations?.result?.items &&
          furnitures?.result?.items &&
          depths?.result?.items &&
          widths?.result?.items
        ) {
          setEntities({
            companies: companies.result.items,
            configurations: configurations.result.items,
            furnitures: furnitures.result.items,
            depths: depths.result.items,
            widths: widths.result.items,
          });
          toast.update(loadingToastId.current, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            render: t("loading.success"),
          });
        }
      } catch (error) {
      } finally {
        toast.dismiss(loadingToastId.current);
      }
    })();
  }, [t]);

  const validationSchema = yup.object({
    reference: yup
      .string()
      .required(t("field_name.required", { field_name: t("Reference") })),
    label: yup
      .string()
      .required(t("field_name.required", { field_name: t("Label") })),
    price: yup
      .number()
      .required(t("field_name.required", { field_name: t("Price") }))
      .min(
        MIN_PRICE,
        t("field_name.min", { field_name: t("Price"), min: MIN_PRICE })
      )
      .max(
        MAX_PRICE,
        t("field_name.max", { field_name: t("Price"), max: MAX_PRICE })
      ),
    companyId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Company") })),
    furnitureId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Furniture") })),
    depthId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Depth") })),
    configurationId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Configuration") })),
    widthId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Width") })),
  });

  const formik = useFormik({
    initialValues: defaultEntity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        const apiResult = await toast.promise(service.create(values), {
          pending: t("processing"),
          success: t("processing.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.result?.id) {
          navigate(`/catalog/merchs/${apiResult.result.id}`);
        }
      } catch (error) {}
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
  } = formik;

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="isActive"
                  onChange={handleChange}
                  checked={values.isActive}
                  color="primary"
                />
              }
              label={t("Enabled")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Create merch")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              variant="contained"
              type="submit"
            >
              {t("button.validate")}
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
        >
          <Tabs value={tabIndex} onChange={handleChangeTab}>
            <Tab label="Général" />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <Box>
            {/* spacing adds marginTop, avoid this by adding mt: 0 (mt=marginTop) */}
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label={t("Reference")}
                  name="reference"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reference}
                  error={touched.reference && Boolean(errors.reference)}
                  helperText={touched.reference && errors.reference}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label={t("Label")}
                  name="label"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.label}
                  error={touched.label && Boolean(errors.label)}
                  helperText={touched.label && errors.label}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("CIP code")}
                  name="cip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cip}
                  error={touched.cip && Boolean(errors.cip)}
                  helperText={touched.cip && errors.cip}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("Description")}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="company"
                  options={entities.companies}
                  onChange={(e, value) => {
                    setFieldValue("company", value);
                    setFieldValue("companyId", value?.id ?? "");
                  }}
                  value={values.company}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("Company")}
                      variant="filled"
                      error={touched.companyId && Boolean(errors.companyId)}
                      helperText={touched.companyId && errors.companyId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="furniture"
                  options={entities.furnitures}
                  onChange={(e, value) => {
                    setFieldValue("furniture", value);
                    setFieldValue("furnitureId", value?.id ?? "");
                  }}
                  value={values.furniture}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("Furniture")}
                      variant="filled"
                      error={touched.furnitureId && Boolean(errors.furnitureId)}
                      helperText={touched.furnitureId && errors.furnitureId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="depth"
                  options={entities.depths}
                  onChange={(e, value) => {
                    setFieldValue("depth", value);
                    setFieldValue("depthId", value?.id ?? "");
                  }}
                  value={values.depth}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("Depth")}
                      variant="filled"
                      error={touched.depthId && Boolean(errors.depthId)}
                      helperText={touched.depthId && errors.depthId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="width"
                  options={entities.widths}
                  onChange={(e, value) => {
                    setFieldValue("width", value);
                    setFieldValue("widthId", value?.id ?? "");
                  }}
                  value={values.width}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("Width")}
                      variant="filled"
                      error={touched.widthId && Boolean(errors.widthId)}
                      helperText={touched.widthId && errors.widthId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="configuration"
                  options={entities.configurations}
                  onChange={(e, value) => {
                    setFieldValue("configuration", value);
                    setFieldValue("configurationId", value?.id ?? "");
                  }}
                  value={values.configuration}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("Configuration")}
                      variant="filled"
                      error={
                        touched.configurationId &&
                        Boolean(errors.configurationId)
                      }
                      helperText={
                        touched.configurationId && errors.configurationId
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label={t("Price")}
                  name="price"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.price}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                  variant="filled"
                />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "20px",
      height: "100%",
    },
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    submitButtonContainer: {
      marginTop: 20,
      textAlign: "right",
    },
  })
);
export default Create;
