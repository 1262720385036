import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { hasPermission } from "helpers/user";
import { useCallback, useMemo } from "react";
// import { hasPermission } from "helpers/user";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import companiesImg from "./companies.jpg";
import depthsImg from "./depths.jpg";
import furnituresImg from "./furnitures.jpg";
import merchsImg from "./merchs.jpg";
import productsImg from "./products.png";
import configurationsImg from "./configurations.png";
import widthsImg from "./widths.png";
import videosImg from "./videos.jpg";
import useConfig from "hooks/useConfig";

interface IMenu {
  name: string;
  route: string;
  imgPath: string;
  permissionName: string;
}

const Catalog = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  const { session } = useSelector((state: RootState) => state.auth);
  const { videosUrl } = useConfig();

  const menu: IMenu[] = useMemo(
    () => [
      {
        name: t("Companies"),
        route: "/catalog/companies",
        imgPath: companiesImg,
        permissionName: "Company.Menu",
      },
      {
        name: t("Furnitures"),
        route: "/catalog/furnitures",
        imgPath: furnituresImg,
        permissionName: "Furniture.Menu",
      },
      {
        name: t("Depths"),
        route: "/catalog/depths",
        imgPath: depthsImg,
        permissionName: "Depth.Menu",
      },
      {
        name: t("Widths"),
        route: "/catalog/widths",
        imgPath: widthsImg,
        permissionName: "Width.Menu",
      },
      {
        name: t("Configurations"),
        route: "/catalog/configurations",
        imgPath: configurationsImg,
        permissionName: "Configuration.Menu",
      },
      {
        name: t("Products"),
        route: "/catalog/products",
        imgPath: productsImg,
        permissionName: "Product.Menu",
      },
      {
        name: t("Merchs"),
        route: "/catalog/merchs",
        imgPath: merchsImg,
        permissionName: "Merch.Menu",
      },
    ],
    [t]
  );

  const handleClick = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate]
  );

  return (
    <Grid container flexDirection="column" sx={{ padding: "20px" }}>
      <Grid item style={{ paddingBottom: "40px", textAlign: "center" }}>
        <Typography variant="h4" component="h2">
          {t("Catalog")}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid
            item
            onClick={() =>
              videosUrl
                ? window.open(videosUrl, "_blank")
                : alert("Unavailable for the moment")
            }
          >
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia className={classes.media} image={videosImg} />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("Explanatory videos")}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  {t("Set up")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {menu.map((item, index) => {
            if (hasPermission(session.user, item.permissionName)) {
              return (
                <Grid item key={index} onClick={() => handleClick(item.route)}>
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={item.imgPath}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          sx={{ fontWeight: "bold" }}
                        >
                          {item.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary">
                        {t("Set up")}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            }
            return <></>;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    root: {
      width: 350,
      textAlign: "center",
    },
    media: {
      height: 200,
    },
  })
);
export default Catalog;
