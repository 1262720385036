import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useCallback, useEffect, useRef, useState } from "react";
import { getAllAsync } from "api/user";
import { IUser } from "types/IUser";

const UserSelector = (props: {
  onChange: (users: IUser[]) => any;
  initialValues?: IUser[];
  maxCount?: number;
}) => {
  const { onChange, initialValues, maxCount } = props;
  const { t } = useTranslation(["common"]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const apiResult = await toast.promise(getAllAsync(), {
          pending: {
            render() {
              return t("common|Loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.success && apiResult.result) {
          setUsers(apiResult.result.items);
        }
      } catch (error) {}
    })();
  }, [t]);

  let shouldInitialize = useRef(true);
  useEffect(() => {
    if (shouldInitialize.current === true && initialValues?.length) {
      const initialSelectedUsers = initialValues || [];
      setSelectedUsers(initialSelectedUsers);
      shouldInitialize.current = false;
    }
  }, [initialValues, selectedUsers]);

  const handleChangeAutocomplete = useCallback(
    (e, value: IUser | null) => {
      const result = value ? [value] : [];
      setSelectedUsers(result);
      onChange(result);
    },
    [onChange]
  );

  const handleChangeAutocompleteMultiple = useCallback(
    (e, value: IUser[]) => {
      let result = value;

      if (maxCount) {
        result = value.length ? value.slice(0, maxCount - 1) : [];
      }

      setSelectedUsers(result);
      onChange(result);
    },
    [maxCount, onChange]
  );

  return (
    <FormControl fullWidth>
      {maxCount === 1 && (
        <Autocomplete
          options={users}
          getOptionLabel={(option: IUser) => {
            return `${option.fullName} - ${
              option.emailAddress
            } (${option.roleNames.join(", ")})`;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedUsers?.[0] || null}
          onChange={handleChangeAutocomplete}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t("Users")}
              variant="filled"
            />
          )}
        />
      )}
      {(!maxCount || maxCount > 1) && (
        <Autocomplete
          options={users}
          multiple={true}
          getOptionLabel={(option: IUser) => {
            return `${option.fullName} - ${
              option.emailAddress
            } (${option.roleNames?.join(", ")})`;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedUsers}
          onChange={handleChangeAutocompleteMultiple}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t("Users")}
              variant="filled"
            />
          )}
        />
      )}
    </FormControl>
  );
};

export default UserSelector;
