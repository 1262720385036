import { getCookie } from "helpers/cookie";

export const apiBaseUrl = (window as any).config.apiBaseUrl;
export const tenantId = (window as any).config.tenantId;

export const getDefaultHeaders = () => {
  const token = getCookie("accessToken") ?? "";
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Abp.TenantId": tenantId,
    Authorization: `Bearer ${token}`,
  };
};

export const getAllDefaultParams = () => {
  return {
    MaxResultCount: 2147483647,
  };
};

export const getResponseExceptionMessage = (ex: any) => {
  if (ex?.response?.data?.error?.message) {
    return ex.response.data.error.message;
  } else if (ex?.message) {
    return ex.message;
  } else {
    return "Erreur";
  }
};
