import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAsync, reset } from "redux/reducers/order";
import { RootState, useAppDispatch } from "redux/store";
import ProcessManager from "./ProcessManager";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoadingScreen from "components/LoadingScreen";

export default function Edit() {
  const { t } = useTranslation(["common"]);
  const appDispatch = useAppDispatch();
  const orderStore = useSelector((state: RootState) => state.order);
  const { orderId } = useParams();
  let [isReady, setIsReady] = useState(false);

  useEffect(() => {
    appDispatch(reset());
    (async function () {
      if (orderId) {
        // TODO: handle loading error, add toast ?
        appDispatch(getAsync(orderId));
        setIsReady(true);
      }
    })();
  }, [appDispatch, orderId, t]);

  return orderStore.id && isReady ? <ProcessManager /> : <LoadingScreen />;
}
