import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import linq from "linq";
import { Moment } from "moment";
import { PropsWithChildren, useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
} from "chart.js";
import { useTranslation } from "react-i18next";
import { IStatistics } from "./Orders";
import {
  FactoryRequestStatusColors,
  FactoryRequestStatusEnum,
  FactoryRequestStatusLabels,
  getStatistics,
} from "api/factoryRequest";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ArcElement,
  Title
);

export interface IStatisticComponentProps extends PropsWithChildren<any> {
  startDate: Moment;
  endDate: Moment;
}

export default function FactoryRequests(props: IStatisticComponentProps) {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [statistics, setStatistics] = useState<
    IStatistics<FactoryRequestStatusEnum>
  >({} as IStatistics<FactoryRequestStatusEnum>);

  useEffect(() => {
    async function getStats() {
      const apiResult = await getStatistics({
        startDate: props.startDate?.toDate(),
        endDate: props.endDate?.toDate(),
      });
      if (apiResult.success) {
        const formatted: IStatistics<FactoryRequestStatusEnum> = {
          ...apiResult.result,
          statistics: apiResult.result.statistics
            // .filter((o) => o.count > 0)
            .map((statistic) => {
              let newState = { ...statistic };
              newState.label = FactoryRequestStatusLabels[newState.statusCode];
              newState.color = FactoryRequestStatusColors[newState.statusCode];
              return newState;
            }),
        };
        setStatistics(formatted);
      }
    }
    (async () => {
      try {
        await getStats();
      } catch (error) {}
    })();
  }, [props]);

  const data = useMemo(() => {
    if (statistics.statistics && statistics.statistics.length > 0) {
      const labels = linq
        .from(statistics.statistics)
        .select((o) => o.label ?? "?")
        .toArray();
      const data = linq
        .from(statistics.statistics)
        .select((o) => o.count ?? 0)
        .toArray();
      const colors = linq
        .from(statistics.statistics)
        .select((o) => o.color ?? "#FFF")
        .toArray();
      return {
        labels: labels,
        datasets: [
          {
            data: data,
            labels: labels,
            backgroundColor: colors,
            borderColor: "#fff",
            datalabels: {
              formatter: (value: any, ctx: any) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data: any) => {
                  return (sum += data);
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
              },
              color: "#fff",
            },
          },
        ],
      };
    }
    return null;
  }, [statistics.statistics]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("Factory requests"),
      },
    },
  };

  return (
    <div className={classes.container}>
      {statistics.count === 0 && (
        <div className={classes.noDataContainer}>
          <h3>{t("No data available")}</h3>
        </div>
      )}
      {data && <Bar options={options} data={data} />}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    noDataContainer: {
      textAlign: "center",
    },
  })
);
