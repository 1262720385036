import AbpAppService from "api/AbpAppService";
import { ISimpleEntity } from "types/ISimpleEntity";
import { IUser } from "types/IUser";

export interface IDeliverer extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
  weight: number;
  users?: IDelivererUser[];
}

export interface IDelivererUser {
  user: IUser;
  userId: string;
  delivererId: string;
}

export interface ICreateDeliverer extends Omit<IDeliverer, "id"> {}

export const defaultEntity: ICreateDeliverer = {
  reference: "",
  label: "",
  isActive: false,
  weight: -1,
};

export const service = new AbpAppService<IDeliverer, IDeliverer>("Deliverer");
