import React, { useEffect, useMemo, useState } from "react";
import { Accept, DropEvent, FileWithPath, useDropzone } from "react-dropzone";
import clsx from "clsx";
import { Delete } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

interface IProps {
  accept?: Accept;
  maxFiles?: number; // maxFiles uploaded at same time
  maxFilesUploaded?: number; // total files uploaded count
  maxSize?: number;
  multiple?: boolean;
  onDrop?: () => any;
  onDropAccepted?: (files: FileWithPath[], event: DropEvent) => any;
  onDelete?: (index: number) => any;
  onChange?: (files: FileWithPath[]) => any;
}

export default function Dropzone(props: IProps) {
  const { accept, onChange } = props;
  const classes = useStyles();
  const [fileList, setFileList] = useState([] as FileWithPath[]);
  const [maxFileUploadedReached, setMaxFileUploadedReached] = useState(false);

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: props.maxSize,
    accept: props.accept,
    maxFiles: props.maxFiles,
    multiple: props.multiple,
    onDrop(files: FileWithPath[]) {},
    onDropAccepted(files: FileWithPath[], event) {
      let result = files;
      if (props.maxFilesUploaded) {
        if (fileList.length === props.maxFilesUploaded) {
          return;
        } else {
          const countToUpload = props.maxFilesUploaded - fileList.length;
          if (countToUpload > 0) {
            result = [...fileList, ...files.slice(0, countToUpload)];
          } else {
            return;
          }
        }
      } else {
        result = [...fileList, ...files];
      }
      setFileList(result);
      props.onDropAccepted?.(result, event);
    },
  });

  useEffect(() => {
    setMaxFileUploadedReached(fileList.length === props.maxFilesUploaded);
  }, [fileList, props.maxFilesUploaded]);

  const onDelete = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
    if (props.maxFilesUploaded && fileList.length < props.maxFilesUploaded) {
      setMaxFileUploadedReached(false);
    }
    props?.onDelete?.(index);
  };

  const fileListHtml = fileList.map((file: FileWithPath, index: number) => {
    return (
      <p key={file.name + index} className={classes.file}>
        <Delete
          className={classes.deleteIcon}
          onClick={() => onDelete(index)}
        />
        {file.name} - {file.size} bytes
      </p>
    );
  });

  const fileLimitReached = useMemo(() => {
    if (maxFileUploadedReached) {
      return (
        <p>
          Le nombre maximum de fichiers autorisés ({props.maxFilesUploaded}) est
          atteint
        </p>
      );
    }
    return "";
  }, [props.maxFilesUploaded, maxFileUploadedReached]);

  const className = useMemo(() => {
    return clsx({
      [classes.baseStyle]: true,
      [classes.hasFileStyle]: !isDragActive && fileList.length,
      [classes.acceptStyle]: isDragAccept,
      [classes.activeStyle]: isDragActive,
      [classes.rejectStyle]: isDragReject,
    });
  }, [classes, isDragActive, isDragReject, isDragAccept, fileList.length]);

  useEffect(() => {
    onChange?.(fileList);
  }, [onChange, fileList]);

  const extensions = useMemo(() => {
    let result = "";
    if (accept) {
      Object.keys(accept)?.forEach((item, index) => {
        if (index > 0) {
          result += " ";
        }
        result += accept[item].join(", ");
      });
    }
    if (result !== "") {
      return (
        <>
          <br />
          <span>Extensions autorisées: {result}</span>
        </>
      );
    }
    return result;
  }, [accept]);

  const fileMaxSize = useMemo(() => {
    let result = "";
    if (props.maxSize) {
      return (
        <>
          <br />
          <span>Taille de fichier maximum: {props.maxSize / 1000000} mo</span>
        </>
      );
    }
    return result;
  }, [props.maxSize]);

  return (
    <div className="container">
      <div {...getRootProps()} className={className}>
        <input {...getInputProps()} />
        <p>
          Glisser/déposer ou cliquer ici pour ajouter vos fichiers.
          {extensions}
          {fileMaxSize}
        </p>
      </div>
      {fileListHtml}
      {fileLimitReached}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseStyle: {
      flex: 1,
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      textAlign: "justify",
      transition: "border .24s ease-in-out",
    },
    activeStyle: {
      borderColor: "#2196f3",
    },
    acceptStyle: {
      borderColor: "#00e676",
    },
    rejectStyle: {
      borderColor: "#ff1744",
    },
    hasFileStyle: {
      color: "#80cd99",
      borderColor: "#80cd99",
    },
    file: {
      display: "flex",
      alignItems: "center",
    },
    deleteIcon: {
      cursor: "pointer",
    },
  })
);
