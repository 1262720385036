import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Checkbox, FormControlLabel, Modal, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { getAllAsync } from "api/auditLog";
import MuiReactTable, { IFilter } from "components/MuiReactTable";
import ReactJson from "react-json-view";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useSelector } from "react-redux";
import { setForm, selectFormById } from "redux/reducers/forms";
import { useAppDispatch, RootState } from "redux/store";

const FORM_ID = "logs"; // used to save filter values

const Logs = () => {
  const classes = useStyles();
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [exceptionMessageChecked, setExceptionMessageChecked] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalContent, setModalContent] = useState<object>({});
  const { t } = useTranslation(["common"]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);

  const getData = useCallback(
    async (withExceptionMessage?: boolean) => {
      try {
        const apiResult = await toast.promise(
          getAllAsync({
            MaxResultCount: 250,
            withExceptionMessage,
          }),
          {
            pending: {
              render() {
                return t("common|Loading");
              },
            },
            // success: t("common|loading.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          }
        );
        if (apiResult?.success && apiResult.result) {
          setAuditLogs(apiResult.result.items);
        }
      } catch (error) {}
    },
    [t]
  );

  useEffect(() => {
    (async function () {
      await getData(exceptionMessageChecked);
    })();
  }, [exceptionMessageChecked, getData]);

  const tableColumns = useMemo(
    () => [
      {
        Header: "ExecutionTime",
        accessor: "executionTime",
        Cell: ({ value }: { value: string }) => {
          return new Date(value).toLocaleString();
        },
      },
      { Header: "TenantId", accessor: "tenantId" },
      { Header: "UserId", accessor: "userId" },
      { Header: "ServiceName", accessor: "serviceName" },
      { Header: "MethodName", accessor: "methodName" },
      { Header: "ExceptionMessage", accessor: "exceptionMessage" },
    ],
    []
  );

  const tableHeader = (
    setFilter: any,
    setGlobalFilter: any,
    globalFilter: any
  ) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={exceptionMessageChecked}
            onChange={() => {
              setExceptionMessageChecked((old) => !old);
              // setFilter("exceptionMessage", "test");
            }}
            color="primary"
          />
        }
        label="ExceptionMessage non vide"
      />
    );
  };

  const handleRowClick = (row: any, data: object, event: any) => {
    setModalOpened(true);
    setModalContent(data);
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  return (
    <div className={classes.container}>
      <MuiReactTable
        // autoResetPage={autoResetPage}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        header={(setFilter: any, setGlobalFilter: any, globalFilter: any) =>
          tableHeader(setFilter, setGlobalFilter, globalFilter)
        }
        columns={tableColumns}
        data={auditLogs}
        title={"Logs"}
        csvExport={true}
        fileName={"export-logs"}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
      />
      <Modal open={modalOpened} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: 50,
            left: 50,
            right: 50,
            bottom: 50,
            background: "white",
            border: "2px solid #000",
            padding: 20,
            overflow: "auto",
          }}
        >
          <ReactJson
            src={modalContent}
            // theme={"monokai"}
          />
        </Box>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px", height: 400 },
  })
);
export default Logs;
