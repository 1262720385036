import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  // Theme,
  Typography,
  // useTheme,
} from "@mui/material";
// import { createStyles, makeStyles } from "@mui/styles";
import {
  useCallback,
  // useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import order, {
  IOrderLine,
  StepEnum,
  updateAsync,
  updateState,
  updateStep,
} from "redux/reducers/order";
// import { ModalContext } from "contexts/ModalContext";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IProduct, service } from "api/product";
import { toast } from "react-toastify";
import { IMerchProduct } from "api/merch";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Today } from "@mui/icons-material";

const ExpectedDeliveryDate = () => {
  const { t } = useTranslation(["common"]);
  // const classes = useStyles();
  // const theme = useTheme();
  const appDispatch = useAppDispatch();
  // const { addModal } = useContext(ModalContext);
  const orderStore = useSelector((state: RootState) => state.order);

  const handleValidate = useCallback(async () => {
    await appDispatch(updateAsync(orderStore));
  }, [appDispatch, orderStore]);

  const isFormInvalid = useMemo(() => {
    return orderStore.busy;
  }, [orderStore.busy]);

  useEffect(() => {
    (async function () {
      if (!orderStore.expectedDeliveryDate) {
        appDispatch(
          updateState({
            expectedDeliveryDate: moment()
              .add(24, "d")
              .startOf("day")
              .toISOString(),
          })
        );
      }
    })();
  }, [t, orderStore]);
  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Expected Delivery Date")}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            sx={{
              padding: "10px",
              paddingTop: "50px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <form
              /* className={classes.formContainer}*/
              noValidate
              autoComplete="off"
            >
              <DatePicker
                label={t("Expected Delivery Date")}
                renderInput={(params) => (
                  <TextField {...params} error={false} />
                )}
                minDate={moment()}
                value={orderStore.expectedDeliveryDate ?? moment().add(24, "d")}
                onChange={(e) =>
                  appDispatch(
                    updateState({
                      expectedDeliveryDate: moment(e)
                        .startOf("day")
                        .toISOString(),
                    })
                  )
                }
                InputProps={{
                  //required: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* </fieldset> */}
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                appDispatch(updateStep(StepEnum.AdditionalProducts))
              }
            >
              {t("Back to product selection")}
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={isFormInvalid}
              onClick={handleValidate}
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     container: {},
//   })
// );
export default ExpectedDeliveryDate;
