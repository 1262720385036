import axios, { AxiosInstance, AxiosResponse } from "axios";
import * as ApiSettings from "./apiSettings";
import { getDefaultHeaders } from "./apiSettings";
import qs from "qs";
import {
  IAbpResponseDelete,
  IAbpResponseGet,
  IAbpResponseGetList,
} from "types/IApi";

const defaultServicePath = "/api/services/app/";

type Verbs = "get" | "delete" | "post" | "put" | any;
type Methods = "get" | "getAll" | "create" | "update" | "delete" | any;

export default class AbpAppService<
  EntityList extends any,
  EntityDetail extends any
> {
  serviceName: string | null = null;
  servicePath: string | null = null;

  axiosInstance: AxiosInstance = axios.create();

  constructor(serviceName: string, servicePath?: string) {
    this.serviceName = serviceName;
    this.servicePath = servicePath || defaultServicePath;
    this.axiosInstance.interceptors.response.use(
      function (response: AxiosResponse<any>) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  invoke(verb: Verbs, methodName: Methods, input?: any, bearerToken?: string) {
    let result = null;
    const headers = {
      ...getDefaultHeaders(),
    };

    if (bearerToken) {
      headers.Authorization = `Bearer ${bearerToken}`;
    }

    switch (verb) {
      case "get":
        result = this.axiosInstance.get(
          `${ApiSettings.apiBaseUrl}${this.servicePath}${this.serviceName}/${methodName}`,
          {
            headers: headers,
            params: input,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          }
        );
        break;
      case "delete":
        result = this.axiosInstance.delete(
          `${ApiSettings.apiBaseUrl}${this.servicePath}${this.serviceName}/${methodName}`,
          {
            headers: headers,
            params: input,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          }
        );
        break;
      default:
        result = (this.axiosInstance as any)[verb](
          `${ApiSettings.apiBaseUrl}${this.servicePath}${this.serviceName}/${methodName}`,
          input,
          {
            headers: headers,
          }
        );
        break;
    }
    return result;
  }

  getAll = async (
    input?: object,
    authToken?: string
  ): Promise<IAbpResponseGetList<EntityList>> => {
    let newInput = { MaxResultCount: 2147483647, ...input };
    return this.invoke("get", "getAll", newInput, authToken);
  };

  get = async (
    input: any,
    authToken?: string
  ): Promise<IAbpResponseGet<EntityDetail>> => {
    return this.invoke("get", "get", input, authToken);
  };

  create = async (
    input: any,
    authToken?: string
  ): Promise<IAbpResponseGet<EntityDetail>> => {
    return this.invoke("post", "create", input, authToken);
  };

  update = async (
    input: any,
    authToken?: string
  ): Promise<IAbpResponseGet<EntityDetail>> => {
    return this.invoke("put", "update", input, authToken);
  };

  delete = async (
    input: any,
    authToken?: string
  ): Promise<IAbpResponseDelete<EntityDetail>> => {
    return this.invoke("delete", "delete", input, authToken);
  };
}
