import { Download, RemoveRedEye, Delete } from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { getImageViewerUrl, getPdfViewerUrl } from "helpers/files";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  addCommentAsync,
  completeAsync,
  refuseAsync,
  updateAsync,
  validateAsync,
  updateState,
} from "redux/reducers/order";
import { RootState, useAppDispatch } from "redux/store";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { formatDate, isNullOrUndefined } from "helpers/tools";
import { IOrder, OrderStatusEnum } from "api/order";
import { hasRole } from "helpers/user";
import { RoleNames } from "api/role";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useConfig from "hooks/useConfig";
import ViewModal, { IViewModal } from "routes/catalog/merchs/ViewModal";
import { ModalContext } from "contexts/ModalContext";
import moment from "moment";

const Validation = () => {
  const { t } = useTranslation(["common"]);
  const { addModal } = useContext(ModalContext);
  const classes = useStyles();
  const orderStore = useSelector((state: RootState) => state.order);
  const authStore = useSelector((state: RootState) => state.auth);
  const { refusedReasons } = useConfig();
  const appDispatch = useAppDispatch();
  let navigate = useNavigate();
  let [completed, setCompleted] = useState(false);
  const { width, height } = useWindowSize();

  const handleOpenFile = useCallback((url?: string) => {
    if (!url) {
      return;
    }
    window.open(url, "blank");
  }, []);

  const handleValidate = useCallback(async () => {
    const retapp = await appDispatch(validateAsync(orderStore));
    if (!(retapp.payload as IOrder).isGoodDeliveryDate) {
      alert(
        "Trop d'éléments sur la semaine de livraison. La Date de livraison à été modifiée : " +
          moment((retapp.payload as IOrder).deliveryDate).format(
            "DD/MMYYYY HH:mm"
          )
      );
    }
  }, [appDispatch, orderStore]);

  const handleRefuseWithComment = useCallback(
    async (event: SelectChangeEvent<string>, child: React.ReactNode) => {
      if (event.target.value !== "empty") {
        await appDispatch(
          addCommentAsync({
            orderId: orderStore.id as string,
            comment: event.target.value,
            sendNotification: true,
          })
        );
      }
    },
    [appDispatch, orderStore.id]
  );

  const handleRefuse = useCallback(async () => {
    await appDispatch(refuseAsync(orderStore));
  }, [appDispatch, orderStore]);

  const handleComplete = useCallback(async () => {
    await appDispatch(completeAsync(orderStore));
    setCompleted(true);
  }, [appDispatch, orderStore]);

  const orderFormUrl = useMemo(() => {
    return getPdfViewerUrl(orderStore?.orderFormId);
  }, [orderStore?.orderFormId]);

  const orderFormUrlSigned = useMemo(() => {
    return orderStore?.signature && orderStore?.signature !== ""
      ? getPdfViewerUrl(orderStore?.orderFormSignedId)
      : getImageViewerUrl(orderStore?.orderFormSignedId ?? "");
  }, [orderStore?.orderFormSignedId, orderStore?.signature]);

  const deliveryFormUrl = useMemo(() => {
    return getPdfViewerUrl(orderStore?.deliveryFormId);
  }, [orderStore?.deliveryFormId]);

  const markupUrl = useMemo(() => {
    return getPdfViewerUrl(orderStore?.markupFileId);
  }, [orderStore?.markupFileId]);

  const isFormInvalid = useMemo(() => {
    return !isNullOrUndefined(orderStore.completionDate) || orderStore.busy;
  }, [orderStore.completionDate, orderStore.busy]);

  const isRRSValidation = useMemo(
    () =>
      orderStore.status === OrderStatusEnum.RRSValidation &&
      hasRole(authStore.session.user, RoleNames.RRS),
    [authStore.session.user, orderStore.status]
  );

  const isCVRValidation = useMemo(
    () =>
      orderStore.status === OrderStatusEnum.CVRValidation &&
      hasRole(authStore.session.user, RoleNames.CVR),
    [authStore.session.user, orderStore.status]
  );

  const isDZValidation = useMemo(
    () =>
      orderStore.status === OrderStatusEnum.DZValidation &&
      hasRole(authStore.session.user, RoleNames.DZ),
    [authStore.session.user, orderStore.status]
  );

  const isMerchValidation = useMemo(
    () =>
      (orderStore.status === OrderStatusEnum.MerchValidation ||
        orderStore.status === OrderStatusEnum.CVRValidation ||
        orderStore.status === OrderStatusEnum.RRSValidation ||
        orderStore.status === OrderStatusEnum.DZValidation) &&
      hasRole(authStore.session.user, RoleNames.Merch),
    [authStore.session.user, orderStore.status]
  );

  const userActions = useCallback(() => {
    return (
      <Grid container spacing={2} flexDirection={"row"}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            sx={{ height: "55px" }}
            onClick={handleValidate}
          >
            {t("common|Validate")}
          </Button>
        </Grid>
        {/* <Grid item>{t("common|or")}</Grid> */}
        <Grid item>
          <Select defaultValue={"empty"} onChange={handleRefuseWithComment}>
            <MenuItem value={"empty"}>
              <Typography>{t("common|Refuse (pick a reason)")}</Typography>
            </MenuItem>
            {refusedReasons?.map((reason, index) => (
              <MenuItem key={`reason-${index}`} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
          {/* <br />
          <small>
            Après sélection d'un motif de refus, un commentaire sera ajouté dans
            l'historique et une notification sera envoyée à la personne devant
            valider après vous
          </small> */}
        </Grid>
      </Grid>
    );
  }, [handleRefuseWithComment, handleValidate, refusedReasons, t]);

  const GLValidationDate = useMemo(() => {
    const statusLine = orderStore.statusLines?.find(
      (o) => o.orderStatus === OrderStatusEnum.RRSValidation
    );
    if (statusLine) {
      return t("common|Validated on {{date}}", {
        date: formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm"),
      });
    } else {
      return "-";
    }
  }, [orderStore.statusLines, t]);

  const RRSValidationDate = useMemo(() => {
    const statusLine = orderStore.statusLines?.find(
      (o) => o.orderStatus === OrderStatusEnum.CVRValidation
    );
    if (statusLine) {
      return t("common|Validated on {{date}}", {
        date: formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm"),
      });
    } else {
      return "-";
    }
  }, [orderStore.statusLines, t]);

  const CVRValidationDate = useMemo(() => {
    const statusLine = orderStore.statusLines?.find(
      (o) => o.orderStatus === OrderStatusEnum.MerchValidation
    );
    if (statusLine) {
      return t("common|Validated on {{date}}", {
        date: formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm"),
      });
    } else {
      return "-";
    }
  }, [orderStore.statusLines, t]);

  const DZValidationDate = useMemo(() => {
    const statusLine = orderStore.statusLines?.find(
      (o) => o.orderStatus === OrderStatusEnum.MerchValidation
    );
    if (statusLine) {
      return t("common|Validated on {{date}}", {
        date: formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm"),
      });
    } else {
      return "-";
    }
  }, [orderStore.statusLines, t]);

  const adminSectionVisible = useMemo(
    () => hasRole(authStore.session.user, "Admin"),
    [authStore.session.user]
  );
  const merchSectionVisible = useMemo(
    () =>
      hasRole(authStore.session.user, "Admin") ||
      hasRole(authStore.session.user, "AdminPLV") ||
      hasRole(authStore.session.user, "Merch"),
    [authStore.session.user]
  );
  const factoryRequestUrl = useMemo(
    () =>
      orderStore.factoryRequestId && orderStore.factoryRequestId !== ""
        ? `/factoryRequests/${orderStore.factoryRequestId}`
        : undefined,
    [orderStore.factoryRequestId]
  );

  const transportRequestUrl = useMemo(
    () =>
      orderStore.transportRequestId && orderStore.transportRequestId !== ""
        ? `/transportRequests/${orderStore.transportRequestId}`
        : undefined,
    [orderStore.transportRequestId]
  );
  const handleUpdate = useCallback(async () => {
    await appDispatch(updateAsync(orderStore));
  }, [appDispatch, orderStore]);

  const handleInputChange = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    appDispatch(updateState({ [event.target.name]: event.target.value }));
  };

  const handleMerchDetails = useCallback(() => {
    if (orderStore.merch) {
      const modal: IViewModal = {
        component: ViewModal,
        props: {
          title: orderStore.merch.label,
          merch: orderStore.merch,
        },
      };
      addModal(modal);
    }
  }, [addModal, orderStore.merch]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Confetti width={width} height={height} recycle={false} run={completed} />
      <Grid item sx={{ flex: 1 }}>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Validation")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item sx={{ padding: "20px 0px", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrl)}
                  label={t("See order form")}
                  variant="outlined"
                  disabled={orderFormUrl ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrlSigned)}
                  label={t("See signed order form")}
                  variant="outlined"
                  disabled={orderFormUrlSigned ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(deliveryFormUrl)}
                  label={t("See delivery form")}
                  variant="outlined"
                  disabled={deliveryFormUrl ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(markupUrl)}
                  label={t("See markup")}
                  variant="outlined"
                  disabled={markupUrl ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={handleMerchDetails}
                  label={t("See merch plan details")}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction={"column"}
              spacing={2}
              sx={{
                marginTop: "20px",
                alignContent: "flex-start",
                width: "100%",
              }}
            >
              <Grid item className={classes.validationLineItem}>
                <Grid
                  container
                  className={classes.validationLineItemContainer}
                  spacing={2}
                >
                  <Grid item>
                    <Typography>
                      <span className={classes.validationLineItemLabel}>
                        Validation GL
                      </span>
                      <br />
                      <small>{GLValidationDate}</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.validationLineItem}>
                <Grid
                  container
                  className={classes.validationLineItemContainer}
                  spacing={2}
                >
                  <Grid item>
                    <Typography>
                      <span className={classes.validationLineItemLabel}>
                        Validation RRS
                      </span>
                      <br />
                      <small>{RRSValidationDate}</small>
                    </Typography>
                  </Grid>
                  <Grid item>{isRRSValidation && userActions()}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.validationLineItem}>
                <Grid
                  container
                  className={classes.validationLineItemContainer}
                  spacing={2}
                >
                  <Grid item>
                    <Typography>
                      <span className={classes.validationLineItemLabel}>
                        Validation CVR
                      </span>
                      <br />
                      <small>{CVRValidationDate}</small>
                    </Typography>
                  </Grid>
                  <Grid item>{isCVRValidation && userActions()}</Grid>
                </Grid>
              </Grid>
              {/* <Grid item className={classes.validationLineItem}>
                <Grid
                  container
                  className={classes.validationLineItemContainer}
                  spacing={2}
                >
                  <Grid item>
                    <Typography>
                      <span className={classes.validationLineItemLabel}>
                        Validation DZ
                      </span>
                      <br />
                      <small>{DZValidationDate}</small>
                    </Typography>
                  </Grid>
                  <Grid item>{isDZValidation && userActions()}</Grid>
                </Grid>
              </Grid> */}
            </Grid>
            {merchSectionVisible && (
              <Grid
                item
                md={12}
                lg={6}
                sx={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                <TextField
                  id="orderLinearGain"
                  name="linearGain"
                  label={t("Linear Gain")}
                  inputProps={{}}
                  value={orderStore.linearGain || ""}
                  onChange={handleInputChange}
                  required={true}
                  size={"medium"}
                  style={{ width: "100px" }}
                  //className={classes.formControl}
                  select
                >
                  {[
                    "-2",
                    "-1",
                    ,
                    "-0,5",
                    "-0,3",
                    "0",
                    "0,3",
                    "0,5",
                    "1",
                    "1,5",
                    "2",
                    "2,5",
                    "3",
                  ].map((linearG, index) => (
                    <MenuItem key={linearG} value={linearG}>
                      {linearG}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleUpdate}
                  style={{ marginLeft: "20px", marginTop: "10px" }}
                >
                  {t("common|button.update")}
                </Button>
              </Grid>
            )}
            {adminSectionVisible && (
              <Grid
                container
                direction={"column"}
                spacing={2}
                sx={{
                  marginTop: "10px",
                  alignContent: "flex-start",
                  width: "100%",
                }}
              >
                <Grid item>
                  <Typography variant="h6">{t("Admin section")}</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Chip
                        icon={<RemoveRedEye />}
                        onClick={() =>
                          factoryRequestUrl
                            ? navigate(factoryRequestUrl)
                            : console.log("nope")
                        }
                        label={t("See factory request")}
                        variant="outlined"
                        disabled={factoryRequestUrl ? false : true}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        icon={<RemoveRedEye />}
                        onClick={() =>
                          transportRequestUrl
                            ? navigate(transportRequestUrl)
                            : console.log("nope")
                        }
                        label={t("See transport request")}
                        variant="outlined"
                        disabled={transportRequestUrl ? false : true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item></Grid>
          <Grid item sx={{ textAlign: "left" }}>
            {isMerchValidation && (
              <Grid container sx={{ alignItems: "center" }} spacing={2}>
                <Grid item sx={{ width: "200px" }}>
                  <Typography>
                    <span style={{ fontWeight: "bold" }}>Validation Merch</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isFormInvalid}
                    onClick={handleComplete}
                  >
                    {t("Finalize order")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isFormInvalid}
                    onClick={handleRefuse}
                  >
                    {t("Refuse order")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    validationLineItem: {
      width: "100%",
    },
    validationLineItemContainer: {
      alignItems: "center",
      "& > div:first-child": {
        width: "220px",
      },
    },
    validationLineItemLabel: {
      fontWeight: "bold",
    },
  })
);

export default Validation;
