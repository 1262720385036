import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { getResponseExceptionMessage } from "api/apiSettings";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProductsSelector from "routes/retailOrders/ProductsSelector";
import TabPanel from "../../../components/TabPanel";
import {
  service,
  IMerchProduct,
  IMerchFile,
  defaultEditEntity,
} from "api/merch";
import { ICompany, service as companyService } from "api/company";
import {
  IConfiguration,
  service as configurationService,
} from "api/configuration";
import { IDepth, service as depthService } from "api/depth";
import { IFurniture, service as furnitureService } from "api/furniture";
import { IWidth, service as widthService } from "api/width";
import { toast } from "react-toastify";
import { MAX_PRICE, MIN_PRICE } from "./Create";
import { useParams } from "react-router-dom";
import { FileWithPath } from "react-dropzone";
import {
  getFileUrlFromObject,
  getPdfViewerUrlFromObject,
  IMAGE_EXTENSIONS,
  ITempFile,
  MAX_SIZE,
  PDF_EXTENSIONS,
  uploadFiles,
} from "helpers/files";
import Dropzone from "components/Dropzone";
import { RemoveRedEye } from "@mui/icons-material";
import { ModalContext } from "contexts/ModalContext";
import InfoModal, { IInfoModal } from "components/InfoModal";
import Duplicate from "./Duplicate";
import { hasPermission } from "helpers/user";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const Edit = () => {
  const { session } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let [tabIndex, setTabIndex] = useState(0);
  const [entity, setEntity] = useState(defaultEditEntity);
  const { merchId } = useParams();
  let [entities, setEntities] = useState({
    companies: [] as ICompany[],
    furnitures: [] as IFurniture[],
    depths: [] as IDepth[],
    configurations: [] as IConfiguration[],
    widths: [] as IWidth[],
  });
  let [tempFiles, setTempFiles] = useState<ITempFile[]>([]);
  const [dropzoneKey, setDropzoneKey] = useState(Date.now());
  const { addModal } = useContext(ModalContext);

  const canEditMerch = useMemo(() => {
    return hasPermission(session.user, "Merch.Update");
  }, [session.user]);

  const loadingToastId = useRef<any>(null);
  useEffect(() => {
    if (loadingToastId.current === null && !entity.id) {
      loadingToastId.current = toast(t("loading"), {
        type: toast.TYPE.INFO,
        autoClose: false,
      });
    }
    (async function () {
      try {
        const companies = await companyService.getAll({ isActive: true });
        const configurations = await configurationService.getAll({
          isActive: true,
        });
        const furnitures = await furnitureService.getAll({ isActive: true });
        const depths = await depthService.getAll({ isActive: true });
        const widths = await widthService.getAll({ isActive: true });
        if (
          companies?.result?.items &&
          configurations?.result?.items &&
          furnitures?.result?.items &&
          depths?.result?.items &&
          widths?.result?.items
        ) {
          setEntities({
            companies: companies.result.items,
            configurations: configurations.result.items,
            furnitures: furnitures.result.items,
            depths: depths.result.items,
            widths: widths.result.items,
          });
        }
      } catch (error) {
        toast.update(loadingToastId.current, {
          type: toast.TYPE.ERROR,
          autoClose: false,
          render: t("loading.error"),
        });
      }
    })();
  }, [entity.id, t]);

  useEffect(() => {
    if (
      entities.companies.length &&
      entities.furnitures.length &&
      entities.depths.length &&
      entities.configurations.length &&
      entities.widths.length &&
      merchId
    ) {
      (async function () {
        try {
          const apiResult = await service.get({ id: merchId });
          // props whose can be "undefined" must be updated before state update
          // because of "controlled/uncontrolled input" react error
          setEntity({
            ...apiResult.result,
            description: apiResult.result.description ?? "",
            cip: apiResult.result.cip ?? "",
          });
          toast.dismiss(loadingToastId.current);
        } catch (error) {
          toast.update(loadingToastId.current, {
            type: toast.TYPE.ERROR,
            autoClose: false,
            render: t("loading.error"),
          });
        }
      })();
    }
  }, [
    entities.companies.length,
    entities.configurations.length,
    entities.depths.length,
    entities.furnitures.length,
    entities.widths.length,
    merchId,
    t,
  ]);

  const validationSchema = yup.object({
    reference: yup
      .string()
      .required(t("field_name.required", { field_name: t("Reference") })),
    label: yup
      .string()
      .required(t("field_name.required", { field_name: t("Label") })),
    price: yup
      .number()
      .required(t("field_name.required", { field_name: t("Price") }))
      .min(
        MIN_PRICE,
        t("field_name.min", { field_name: t("Price"), min: MIN_PRICE })
      )
      .max(
        MAX_PRICE,
        t("field_name.max", { field_name: t("Price"), max: MAX_PRICE })
      ),
    companyId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Company") })),
    furnitureId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Furniture") })),
    depthId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Depth") })),
    configurationId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Configuration") })),
    widthId: yup
      .string()
      .required(t("field_name.required", { field_name: t("Width") })),
  });

  const formik = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        let uploadedFiles: ITempFile[] = [];
        if (tempFiles?.length) {
          uploadedFiles = await toast.promise(uploadFiles(tempFiles), {
            pending: t("processing.files"),
            success: t("processing.files.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          });
          setDropzoneKey(Date.now());
        }

        const apiResult = await toast.promise(
          service.update({ ...values, tempFiles: uploadedFiles }),
          {
            pending: t("processing"),
            success: t("processing.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          }
        );
        if (apiResult?.result?.id) {
          setEntity({
            ...apiResult?.result,
            description: apiResult.result.description ?? "",
          });
        }
      } catch (error) {}
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
  } = formik;

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ) => {
    setTabIndex(newTabIndex);
  };

  const handleProductSelectorChange = useCallback(
    (products: IMerchProduct[]) => {
      setFieldValue("merchProducts", products);
    },
    [setFieldValue]
  );

  const handleFileChange = useCallback((fileList: FileWithPath[]) => {
    const result: ITempFile[] = fileList.map((item) => {
      return { file: item, originalFileName: item.name, path: item.path ?? "" };
    });
    setTempFiles(result);
  }, []);

  const handleOnDeleteFile = useCallback(
    (file: IMerchFile) => {
      setFieldValue(
        "files",
        values.files?.filter((o) => o.fileId !== file.fileId)
      );
    },
    [setFieldValue, values.files]
  );

  const handleViewFile = useCallback(
    (file: IMerchFile) => {
      const fileNameSplit = file.file.originalFileName.split(".");
      const fileExtension = fileNameSplit[fileNameSplit.length - 1];
      if (IMAGE_EXTENSIONS.findIndex((o) => o === `.${fileExtension}`) !== -1) {
        const imgUrl = getFileUrlFromObject(file.file);
        const modal: IInfoModal = {
          component: InfoModal,
          props: {
            fullScreen: true,
            children: (
              <div>
                <img src={imgUrl} alt="" />
              </div>
            ),
          },
        };
        addModal(modal);
      } else if (
        PDF_EXTENSIONS.findIndex((o) => o === `.${fileExtension}`) !== -1
      ) {
        const pdfUrl = getPdfViewerUrlFromObject(file.file);
        window?.open?.(pdfUrl, "_blank")?.focus();
      } else {
      }
    },
    [addModal]
  );

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  disabled={!canEditMerch}
                  name="isActive"
                  onChange={handleChange}
                  checked={values.isActive}
                  color="primary"
                />
              }
              label={t("Enabled")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Edit merch")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            {canEditMerch && (
              <Button
                color="primary"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                type="submit"
              >
                {t("button.validate")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
        >
          <Tabs value={tabIndex} onChange={handleChangeTab}>
            <Tab label={t("General")} />
            <Tab label={t("Products")} />
            {canEditMerch && <Tab label={t("Duplicate")} />}
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <Box>
            {/* spacing adds marginTop, avoid this by adding mt: 0 (mt=marginTop) */}
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  disabled={!canEditMerch}
                  fullWidth
                  label={t("Reference")}
                  name="reference"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reference}
                  error={touched.reference && Boolean(errors.reference)}
                  helperText={touched.reference && errors.reference}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  disabled={!canEditMerch}
                  fullWidth
                  label={t("Label")}
                  name="label"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.label}
                  error={touched.label && Boolean(errors.label)}
                  helperText={touched.label && errors.label}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled={!canEditMerch}
                  label={t("Description")}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled={!canEditMerch}
                  label={t("CIP code")}
                  name="cip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cip}
                  error={touched.cip && Boolean(errors.cip)}
                  helperText={touched.cip && errors.cip}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  disabled={!canEditMerch}
                  id="company"
                  options={entities.companies}
                  onChange={(e, value) => {
                    setFieldValue("company", value);
                    setFieldValue("companyId", value?.id ?? "");
                  }}
                  value={values.company}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!canEditMerch}
                      required
                      label={t("Company")}
                      variant="filled"
                      error={touched.companyId && Boolean(errors.companyId)}
                      helperText={touched.companyId && errors.companyId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  disabled={!canEditMerch}
                  id="furniture"
                  options={entities.furnitures}
                  onChange={(e, value) => {
                    setFieldValue("furniture", value);
                    setFieldValue("furnitureId", value?.id ?? "");
                  }}
                  value={values.furniture}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!canEditMerch}
                      required
                      label={t("Furniture")}
                      variant="filled"
                      error={touched.furnitureId && Boolean(errors.furnitureId)}
                      helperText={touched.furnitureId && errors.furnitureId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  disabled={!canEditMerch}
                  id="depth"
                  options={entities.depths}
                  onChange={(e, value) => {
                    setFieldValue("depth", value);
                    setFieldValue("depthId", value?.id ?? "");
                  }}
                  value={values.depth}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!canEditMerch}
                      required
                      label={t("Depth")}
                      variant="filled"
                      error={touched.depthId && Boolean(errors.depthId)}
                      helperText={touched.depthId && errors.depthId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="width"
                  disabled={!canEditMerch}
                  options={entities.widths}
                  onChange={(e, value) => {
                    setFieldValue("width", value);
                    setFieldValue("widthId", value?.id ?? "");
                  }}
                  value={values.width}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      disabled={!canEditMerch}
                      label={t("Width")}
                      variant="filled"
                      error={touched.widthId && Boolean(errors.widthId)}
                      helperText={touched.widthId && errors.widthId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  disabled={!canEditMerch}
                  id="configuration"
                  options={entities.configurations}
                  onChange={(e, value) => {
                    setFieldValue("configuration", value);
                    setFieldValue("configurationId", value?.id ?? "");
                  }}
                  value={values.configuration}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      disabled={!canEditMerch}
                      label={t("Configuration")}
                      variant="filled"
                      error={
                        touched.configurationId &&
                        Boolean(errors.configurationId)
                      }
                      helperText={
                        touched.configurationId && errors.configurationId
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  disabled={!canEditMerch}
                  fullWidth
                  label={t("Price")}
                  name="price"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.price}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                  variant="filled"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {canEditMerch && (
                <Grid item xs={12} md={6}>
                  <Typography variant={"h5"} paragraph>
                    {t("File upload")}
                  </Typography>
                  <Dropzone
                    // define a key that will re-render the component after files update
                    key={dropzoneKey}
                    accept={{
                      "image/*": IMAGE_EXTENSIONS,
                      "application/pdf": PDF_EXTENSIONS,
                    }}
                    maxSize={MAX_SIZE}
                    onChange={handleFileChange}
                    multiple={true}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Typography variant={"h5"} paragraph>
                  {t("Uploaded files")}{" "}
                </Typography>
                <Grid container spacing={1}>
                  {values.files?.map((file) => (
                    <Grid item key={file.fileId}>
                      <Chip
                        icon={<RemoveRedEye />}
                        label={file.file.originalFileName}
                        onClick={() => handleViewFile(file)}
                        onDelete={() => handleOnDeleteFile(file)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <ProductsSelector
            onChange={handleProductSelectorChange}
            selectedProductList={values.merchProducts}
            disabled={!canEditMerch}
          />
        </TabPanel>
        {canEditMerch && (
          <TabPanel value={tabIndex} index={2}>
            <Duplicate merch={entity} onSubmit={() => setTabIndex(0)} />
          </TabPanel>
        )}
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "20px",
      height: "100%",
    },
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);
export default Edit;
