import AbpAppService from "api/AbpAppService";
import { IAbpResponse } from "types/IApi";
import { IRegistrationInput } from "types/IRegistration";

const appService = new AbpAppService<any, any>("Account");

export const register = async (
  input: IRegistrationInput
): Promise<IAbpResponse<{ canLogin: boolean }>> => {
  return await appService.invoke("post", "Register", input);
};

export const forgotPassword = async (
  emailAddress: string
): Promise<IAbpResponse<{ mailSent: boolean }>> => {
  return await appService.invoke("post", "ForgotPassword", { emailAddress });
};

export const resetPassword = async (
  userId: string,
  token: string,
  newPassword: string
): Promise<IAbpResponse<{ passwordReset: boolean }>> => {
  return await appService.invoke("post", "ResetPassword", {
    userId,
    token,
    newPassword,
  });
};

export const update = async (
  userId: number,
  params: { password?: string; languageName?: string }
): Promise<IAbpResponse<{ updated: boolean }>> => {
  return await appService.invoke("put", "Update", {
    userId,
    ...params,
  });
};
