import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  Box,
  Tab,
  Tabs,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IImportExcelFileResult, importUsers } from "api/user";
import MuiReactTable from "components/MuiReactTable";
import { IModal, IModalProps } from "contexts/ModalContext";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "components/TabPanel";
import { toast } from "react-toastify";
import LoadingScreen from "components/LoadingScreen";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IRole } from "types/IRole";
import { IUser } from "types/IUser";
import { getAllAsync as getAllRolesAsync } from "api/role";

interface IModalImportProps extends IModalProps {
  result: IImportExcelFileResult;
  filePath: string;
}

export interface IModalImport extends IModal {
  props: IModalImportProps;
}

const ModalImport = (props: IModalImportProps) => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const [tabValue, setTabValue] = useState(0);
  let [busy, setBusy] = useState(false);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setTabValue(newValue);
  };

  const { result, filePath } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    props.dialogProps?.onClose?.();
  }, [props.dialogProps]);

  const [roles, setRoles] = useState<IRole[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const apiResult = await toast.promise(getAllRolesAsync(), {
          pending: {
            render() {
              return t("Loading");
            },
          },
          // success: t("loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.success && apiResult.result) {
          setRoles(apiResult.result.items);
        }
      } catch (error) {}
    })();
  }, [t]);

  const userColumns = useMemo(
    () => [
      { Header: t("Firstname"), accessor: "name" },
      { Header: t("Lastname"), accessor: "surname" },
      { Header: t("Email"), accessor: "emailAddress" },
      {
        Header: "Roles",
        accessor: "roleNames",
        Cell: ({ value }: { value: string[] }) => {
          return value.join(", ");
        },
        // disableFilters: true,
        Filter: ({ column: { filterValue = "", setFilter } }: any) => {
          return (
            <Select
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value || "");
              }}
              value={filterValue}
              sx={{
                height: "40px",
                "& .MuiPopover-root": {
                  zIndex: 2000,
                },
              }}
            >
              <MenuItem value={""}>Tous</MenuItem>
              {roles.map((role) => {
                return (
                  <MenuItem
                    key={role.normalizedName}
                    value={role.normalizedName}
                  >
                    {role.normalizedName}
                  </MenuItem>
                );
              })}
            </Select>
          );
        },
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            const rowUser: IUser = row.original;
            if (filterValue) {
              return rowUser.roleNames.indexOf(filterValue) !== -1;
            }
            return true;
          });
        },
      },
    ],
    [roles, t]
  );

  const errorColumns = useMemo(
    () => [
      {
        Header: t("Error"),
        accessor: "value",
        Cell: ({ row: { original } }: { row: { original: string } }) => {
          return original;
        },
      },
    ],
    [t]
  );

  const muiReactTableLabels = useMemo(() => {
    return {
      exportButton: t("components/muireacttable|exportButton"),
      resultCount: t("components/muireacttable|resultCount"),
      rowCount: t("components/muireacttable|rowCount"),
      reinitiliazeFilters: t("components/muireacttable|reinitiliazeFilters"),
    };
  }, [t]);

  const handleValidate = useCallback(async () => {
    try {
      setBusy(true);
      await importUsers({ filePath, isPreview: false });
      toast(t("processing.success"), { type: "success" });
      handleClose();
    } catch (error) {
      toast(t("processing.error"), { type: "error" });
    } finally {
      setBusy(false);
    }
  }, [filePath, handleClose, t]);

  return (
    <div className={classes.container}>
      <Dialog
        {...props.dialogProps}
        fullScreen={true}
        onClose={handleClose}
        transitionDuration={500}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "20px",
            }}
          >
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab
                label={`${t("Added users")} (${result.addedUsers.length})`}
              />
              <Tab
                label={`${t("Updated users")} (${result.updatedUsers.length})`}
              />
              <Tab label={`${t("Errors")} (${result.errors.length})`} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <MuiReactTable
              title={t("Added users")}
              columns={userColumns}
              data={result.addedUsers}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MuiReactTable
              title={t("Updated users")}
              columns={userColumns}
              data={result.updatedUsers}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <MuiReactTable
              title={t("Errors")}
              columns={errorColumns}
              data={result.errors}
              labels={muiReactTableLabels}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValidate} color="primary" variant="contained">
            {t("Validate")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {busy && <LoadingScreen />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);

export default ModalImport;
