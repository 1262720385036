import { Button, Grid, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { IRole } from "types/IRole";
import { deleteEntityAsync, getAsync, updateAsync } from "api/role";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";
import { Delete } from "@mui/icons-material";

const Edit = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  let { roleId } = useParams();
  const [role, setRole] = useState<IRole>({
    name: "",
    displayName: "",
    description: "",
  } as IRole);

  useEffect(() => {
    if (roleId !== undefined) {
      (async function () {
        try {
          const apiResult = await toast.promise(
            getAsync(Number.parseInt(roleId)),
            {
              pending: {
                render() {
                  return t("common|Loading");
                },
              }, // success: t("common|loading.success"),
              error: {
                render({ data }: any) {
                  return getResponseExceptionMessage(data);
                },
                autoClose: false,
              },
            }
          );
          if (apiResult.success && apiResult.result) {
            setRole(apiResult.result);
          }
        } catch (error) {}
      })();
    }
  }, [t, roleId]);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t("common|field_name.required", { field_name: t("name") })),
    displayName: yup
      .string()
      .required(
        t("common|field_name.required", { field_name: t("displayName") })
      ),
  });

  const handleDelete = useCallback(async () => {
    if (roleId !== undefined) {
      (async function () {
        let apiResult = null;
        try {
          apiResult = await toast.promise(deleteEntityAsync(roleId), {
            pending: t("common|processing"),
            success: t("common|processing.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          });
        } catch (error) {}

        if (apiResult?.success) {
          navigate("/roles");
        }
      })();
    }
  }, [roleId, t, navigate]);

  const formik = useFormik({
    initialValues: role,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        await toast.promise(updateAsync(values), {
          pending: t("common|processing"),
          success: t("common|processing.success"),
          error: t("common|processing.error"),
        });
      } catch (error) {}
    },
  });

  return (
    <div className={classes.container}>
      <form
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          ></Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Role")} "{role?.name}"
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: { xs: "center", md: "right" },
              }}
              spacing={2}
            >
              <Grid item>
                {role?.id && (
                  <Delete onClick={handleDelete} sx={{ cursor: "pointer" }}>
                    {t("common|button.delete")}
                  </Delete>
                )}
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  {t("common|button.validate")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("column.name")}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name || ""}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("column.displayName")}
              name="displayName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.displayName || ""}
              error={
                formik.touched.displayName && Boolean(formik.errors.displayName)
              }
              helperText={
                formik.touched.displayName && formik.errors.displayName
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={t("column.description")}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description || ""}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              variant="filled"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      flex: 1,
    },
    submitButtonContainer: {
      marginTop: 20,
    },
  })
);
export default Edit;
