import { upload } from "api/files";
import { apiBaseUrl } from "../api/apiSettings";
import { getCookie } from "./cookie";

export const IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
export const PDF_EXTENSIONS = [".pdf"];
// const fileExtensions = [...imageExtensions, ...pdfExtensions];
export const MAX_SIZE = 8000000;

export interface IFile {
  id: string;
  path: string;
  originalFileName: string;
}

export interface ITempFile {
  file: File;
  path: string;
  originalFileName: string;
}

export const getFileUrl = (id: string, options = {} as any) => {
  const { fileName, isPublic } = options;
  let url = `${apiBaseUrl}/api/file/download?id=${encodeURIComponent(
    id
  )}&${encodeURIComponent("enc_auth_token")}=${encodeURIComponent(
    getCookie("encryptedAccessToken") as string
  )}`;
  if (fileName) {
    url = `${url}&${encodeURIComponent("fileName")}=${encodeURIComponent(
      fileName
    )}`;
  }
  if (isPublic) {
    url = `${url}&${encodeURIComponent("isPublic")}=true`;
  }
  return url;
};

export const getFileUrlFromObject = (file?: IFile) => {
  return file?.id
    ? getFileUrl(file?.id || "", {
        fileName: file?.originalFileName,
      })
    : "";
};

export const getImageViewerUrl = (id: string, options = {} as any) => {
  const fileUrl = getFileUrl(id, options);
  return "/#/image?" + fileUrl;
};

export const getImageViewerUrlFromObject = (file?: IFile) => {
  const fileUrl = getFileUrlFromObject(file);
  return fileUrl !== "" ? "/#/image?" + fileUrl : "";
};

export const getPdfViewerUrl = (id?: string, options = {} as any) => {
  if (!id) {
    return undefined;
  }
  const fileUrl = getFileUrl(id, options);
  return (
    apiBaseUrl + "/pdfjs/web/viewer.html?file=" + encodeURIComponent(fileUrl)
  );
};

export const getPdfViewerUrlFromObject = (file: IFile, options = {} as any) => {
  return getPdfViewerUrl(file.id, options);
};

export const uploadFile = async (file: File): Promise<ITempFile> => {
  const formData = new FormData();
  formData.append(file.name, file, file.name);
  const uploadApi = await upload(formData);
  const fileTempResult: ITempFile = {
    file: file,
    originalFileName: file.name,
    path: uploadApi.data.result.path,
  };
  return fileTempResult;
};

export const uploadFiles = async (files: any) => {
  // https://zellwk.com/blog/async-await-in-loops/
  let newFiles: ITempFile[] = [];
  if (files?.length) {
    const promises: Promise<ITempFile>[] = files.map(
      async (tempFile: ITempFile, index: number) => {
        if (tempFile.file) {
          return await uploadFile(tempFile.file);
        }
      }
    );
    const responses = await Promise.all(promises);
    responses.forEach((response) => {
      if (response !== undefined) {
        newFiles.push(response);
      }
    });
  }
  return newFiles;
};
