import AbpAppService from "api/AbpAppService";
import { ISimpleEntity } from "types/ISimpleEntity";

export interface IDepth extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
}

export interface ICreateDepth extends Omit<IDepth, "id"> {}

export const defaultEntity: ICreateDepth = {
  reference: "",
  label: "",
  isActive: true,
};

export const service = new AbpAppService<IDepth, IDepth>("Depth");
