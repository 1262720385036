import { RemoveRedEye } from "@mui/icons-material";
import { Grid, Chip } from "@mui/material";
import { ModalContext } from "contexts/ModalContext";
import {
  getFileUrlFromObject,
  getPdfViewerUrlFromObject,
  IFile,
  IMAGE_EXTENSIONS,
  PDF_EXTENSIONS,
} from "helpers/files";
import React, { PropsWithChildren, useCallback, useContext } from "react";
import InfoModal, { IInfoModal } from "./InfoModal";

interface IProps extends PropsWithChildren<any> {
  files: IFile[];
}

export default function ChipFileList(props: React.PropsWithChildren<IProps>) {
  const { files } = props;
  const { addModal } = useContext(ModalContext);

  const handleViewFile = useCallback(
    (file: IFile) => {
      const fileNameSplit = file.originalFileName.split(".");
      const fileExtension = fileNameSplit[fileNameSplit.length - 1];
      if (IMAGE_EXTENSIONS.findIndex((o) => o === `.${fileExtension}`) !== -1) {
        const imgUrl = getFileUrlFromObject(file);
        const modal: IInfoModal = {
          component: InfoModal,
          props: {
            fullScreen: true,
            children: (
              <div>
                <img src={imgUrl} alt="" />
              </div>
            ),
          },
        };
        addModal(modal);
      } else if (
        PDF_EXTENSIONS.findIndex((o) => o === `.${fileExtension}`) !== -1
      ) {
        const pdfUrl = getPdfViewerUrlFromObject(file);
        window?.open?.(pdfUrl, "_blank")?.focus();
      } else {
      }
    },
    [addModal]
  );

  return (
    <Grid container spacing={1}>
      {files?.map((file) => (
        <Grid item key={file.id}>
          <Chip
            icon={<RemoveRedEye />}
            label={file.originalFileName}
            onClick={() => handleViewFile(file)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
