import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IAbpResponse } from "types/IApi";
import { ISimpleEntity } from "types/ISimpleEntity";
import * as ApiSettings from "./apiSettings";

export interface IProduct extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
  price: number;
  weight:number;
  isOrderOption: boolean;
  isPallet?: boolean;
  retailPrice?: number;
  usedForTransportPrice: boolean;
}

export interface ICreateProduct extends Omit<IProduct, "id"> {}

export const defaultEntity: ICreateProduct = {
  reference: "",
  label: "",
  isActive: true,
  price: 0,
  weight:0,
  isOrderOption: false,
  isPallet: false,
  retailPrice: "" as unknown as number,
  usedForTransportPrice: false,
};

export const service = new AbpAppService<IProduct, IProduct>("Product");

export interface IImportEntities<T> {
  invalidEntities: T[];
  addedEntities: T[];
  deletedEntities: T[];
  updatedEntities: T[];
  errors: string[];
}

export const importProducts = (params: {
  filePath: string;
  isPreview?: boolean;
  isDebug?: boolean;
  maxRowsToHandle?: number;
}): Promise<AxiosResponse<IAbpResponse<IImportEntities<IProduct>>>> => {
  return axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Import`,
    params,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );
};
