import { Download } from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  Paper,
  // Theme,
  Typography,
} from "@mui/material";
import { RetailOrderStatusEnum } from "api/retailOrder";
import { getImageViewerUrl, getPdfViewerUrl } from "helpers/files";
import { hasOneOfRoles } from "helpers/user";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { completeAsync, refuseAsync } from "redux/reducers/retailOrder";
import { RootState, useAppDispatch } from "redux/store";
// import { createStyles, makeStyles } from "@mui/styles";

const Summary = () => {
  const { t } = useTranslation(["common"]);
  // const classes = useStyles();
  const authStore = useSelector((state: RootState) => state.auth);
  const retailOrderStore = useSelector((state: RootState) => state.retailOrder);
  const appDispatch = useAppDispatch();

  const handleOpenFile = useCallback((url?: string) => {
    if (!url) {
      return;
    }
    window.open(url, "blank");
  }, []);

  const orderFormUrl = useMemo(() => {
    return getPdfViewerUrl(retailOrderStore?.retailOrder.orderFormId);
  }, [retailOrderStore?.retailOrder.orderFormId]);

  const orderFormUrlSigned = useMemo(() => {
    if (retailOrderStore?.retailOrder.orderFormSignedId) {
      return retailOrderStore?.retailOrder.signature &&
        retailOrderStore?.retailOrder.signature !== ""
        ? getPdfViewerUrl(retailOrderStore?.retailOrder.orderFormSignedId)
        : getImageViewerUrl(
            retailOrderStore?.retailOrder.orderFormSignedId ?? ""
          );
    }
    return undefined;
  }, [
    retailOrderStore?.retailOrder.orderFormSignedId,
    retailOrderStore?.retailOrder.signature,
  ]);

  const deliveryFormUrl = useMemo(() => {
    return getPdfViewerUrl(retailOrderStore?.retailOrder.deliveryFormId);
  }, [retailOrderStore?.retailOrder.deliveryFormId]);

  const markupUrl = useMemo(() => {
    return getPdfViewerUrl(retailOrderStore?.retailOrder.markupFileId);
  }, [retailOrderStore?.retailOrder.markupFileId]);

  const canValidate = useMemo(
    () =>
      hasOneOfRoles(authStore.session.user, ["Admin", "AdminPLV"]) &&
      retailOrderStore.retailOrder.status !== RetailOrderStatusEnum.Canceled &&
      retailOrderStore.retailOrder.status !== RetailOrderStatusEnum.Done,
    [authStore.session.user, retailOrderStore.retailOrder.status]
  );

  const handleRefuse = useCallback(async () => {
    await appDispatch(refuseAsync(retailOrderStore));
  }, [appDispatch, retailOrderStore]);

  const handleValidate = useCallback(async () => {
    await appDispatch(completeAsync({ ...retailOrderStore }));
  }, [appDispatch, retailOrderStore]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item sx={{ flex: 1 }}>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Summary")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item sx={{ padding: "20px 0px", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrl)}
                  label={t("See order form")}
                  variant="outlined"
                  disabled={orderFormUrl ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(orderFormUrlSigned)}
                  label={t("See signed order form")}
                  variant="outlined"
                  disabled={orderFormUrlSigned ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(deliveryFormUrl)}
                  label={t("See delivery form")}
                  variant="outlined"
                  disabled={deliveryFormUrl ? false : true}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<Download />}
                  onClick={() => handleOpenFile(markupUrl)}
                  label={t("See markup")}
                  variant="outlined"
                  disabled={markupUrl ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {canValidate && (
        <Grid item>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item></Grid>
            <Grid item sx={{ textAlign: "right" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleRefuse}
                  >
                    {t("Refuse/cancel order")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    // disabled={isFormInvalid}
                    onClick={handleValidate}
                  >
                    {t("common|button.validate")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({

//   })
// );

export default Summary;
