import {
  Cancel,
  Check,
  Delete,
  Info,
  QuestionMark,
  Router,
  Send,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderStatusEnum, OrderStatusLabels, service } from "api/order";
import { formatAmount, formatDate, isNullOrUndefined } from "helpers/tools";
import { hasOneOfRoles } from "helpers/user";
import linq from "linq";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  MouseEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addCommentAsync,
  updateState,
  updateStatusAsync,
} from "redux/reducers/order";
import { RootState, useAppDispatch } from "redux/store";
import AdditionalProducts from "./Steps/AdditionalProducts";
import ExpectedDeliveryDate from "./Steps/ExpectedDeliveryDate";
import Merch from "./Steps/Merch";
import Signature from "./Steps/Signature";
import Store from "./Steps/Store";
import Validation from "./Steps/Validation";
import { ModalContext } from "contexts/ModalContext";
import ConfirmModal, { IConfirmModal } from "components/ConfirmModal";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useNavigate } from "react-router-dom";

const ProcessManager = () => {
  const classes = useStyles();
  const { addModal } = useContext(ModalContext);
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { session } = useSelector((state: RootState) => state.auth);
  const {
    id,
    busy,
    error,
    reference,
    creationTime,
    creatorUser,
    step,
    merch,
    store,
    signatureName,
    statusLines,
    status,
    orderFormSignedId,
    orderLines,
  } = useSelector((state: RootState) => state.order);
  const appDispatch = useAppDispatch();
  let [comment, setComment] = useState("");

  const canDelete = useMemo(() => {
    return (
      id &&
      status &&
      status <= 6 &&
      (hasOneOfRoles(session.user, ["Merch", "Admin", "AdminPLV"]) ||
        creatorUser?.id === session.user.id)
    );
  }, [id, status, session.user.roles, session.user.id, creatorUser?.id]);

  const steps = useMemo(
    () =>
      [
        {
          label: t("Store selection"),
          component: <Store />,
          stepOptionalText: store ? (
            `${store?.label} - ${store?.city}`
          ) : (
            <QuestionMark />
          ),
        },
        {
          label: t("Merch selection"),
          component: <Merch />,
          stepOptionalText: merch ? merch.label : <QuestionMark />,
        },
        {
          label: t("Additional products"),
          component: <AdditionalProducts />,
        },
        {
          label: t("Expected Delivery Date"),
          component: <ExpectedDeliveryDate />,
        },
        {
          label: t("Signature"),
          component: <Signature />,
          stepOptionalText: signatureName ? <Check /> : <QuestionMark />,
        },
        {
          label: t("Validation"),
          component: <Validation />,
          stepOptionalText: (
            <>
              {orderFormSignedId && orderFormSignedId !== "" ? <Check /> : ""}
              {status === OrderStatusEnum.Done ? (
                <Check />
              ) : status === OrderStatusEnum.Canceled ||
                // status === OrderStatusEnum.Delayed ||
                status === OrderStatusEnum.Refused ? (
                <Cancel />
              ) : (
                <QuestionMark />
              )}
            </>
          ),
        },
      ] as { label: string; component: JSX.Element; stepOptionalText: any }[],
    [merch, orderFormSignedId, signatureName, status, store, t]
  );

  const sidebarTitle = useMemo(
    () => (reference ? `N°${reference}` : t("New order")),
    [reference, t]
  );

  const creationDate = useMemo(
    () =>
      creationTime
        ? formatDate(creationTime, "DD/MM/YYYY HH:mm")
        : formatDate(new Date().toISOString(), "DD/MM/YYYY HH:mm"),
    [creationTime]
  );

  const creatorName = useMemo(
    () =>
      creatorUser
        ? `${creatorUser.name} ${creatorUser.surname}`
        : `${session.user.name} ${session.user.surname}`,
    [creatorUser, session.user.name, session.user.surname]
  );

  const total = useMemo(() => {
    let result = 0;
    if (merch) {
      result += linq
        .from(merch.merchProducts)
        .sum((o) => o.quantity * o.product.price);
    }
    if (orderLines) {
      result += linq
        .from(orderLines)
        .where((o) => !o.merchId)
        .sum((o) => o.total);
    }
    return formatAmount(result);
  }, [merch, orderLines]);

  const canAddComment = useMemo(() => {
    return (
      status === OrderStatusEnum.Signature ||
      status === OrderStatusEnum.RRSValidation ||
      status === OrderStatusEnum.CVRValidation ||
      status === OrderStatusEnum.DZValidation ||
      status === OrderStatusEnum.MerchValidation ||
      status === OrderStatusEnum.Delivering ||
      status === OrderStatusEnum.Preparation
      // || status === OrderStatusEnum.Delayed
    );
  }, [status]);

  const canAdminUpdateStatus = useMemo(() => {
    return (
      hasOneOfRoles(session.user, ["Admin", "AdminPLV"]) &&
      (status === OrderStatusEnum.Signature ||
        status === OrderStatusEnum.RRSValidation ||
        status === OrderStatusEnum.CVRValidation ||
        status === OrderStatusEnum.DZValidation ||
        status === OrderStatusEnum.MerchValidation ||
        status === OrderStatusEnum.Delivering ||
        status === OrderStatusEnum.Preparation)
    );
  }, [session.user, status]);

  const handleChangeStatus = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      const { status } = e.currentTarget.dataset;
      const modal: IConfirmModal = {
        component: ConfirmModal,
        props: {
          title: t("Update order status ?"),
          children: <></>,
          onConfirm: async () => {
            if (id) {
              appDispatch(
                updateStatusAsync({
                  orderId: id,
                  status: Number(status),
                  sendNotification: true,
                })
              );
            }
          },
        },
      };
      addModal(modal);
    },
    [addModal, appDispatch, id, t]
  );

  const statuslineContent = useMemo(() => {
    return statusLines
      ? statusLines.map((statusLine) => {
          return (
            <Grid item key={statusLine.id} className={classes.statusLine}>
              <Typography variant={"caption"}>
                <strong>
                  {formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm")}
                </strong>{" "}
                ({statusLine.creatorUser.emailAddress}) -{" "}
                {OrderStatusLabels[statusLine.orderStatus]}
              </Typography>
              {statusLine.comment && statusLine.comment !== "" ? (
                <Typography variant={"caption"} className={classes.multilines}>
                  {" - "}
                  {statusLine.comment}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          );
        })
      : [];
  }, [classes.multilines, classes.statusLine, statusLines]);

  const handleDeleteClick = () => {
    const modal: IConfirmModal = {
      component: ConfirmModal,
      props: {
        title: t("Delete order ?"),
        children: <></>,
        onConfirm: async () => {
          if (id) {
            const apiResult = await toast.promise(
              service.delete({
                id: id,
              }),
              {
                pending: {
                  render() {
                    return t("deleting");
                  },
                },
                // success: t("loading.success"),
                error: {
                  render({ data }: any) {
                    return getResponseExceptionMessage(data);
                  },
                  autoClose: false,
                },
              }
            );
            if (apiResult?.success) {
              navigate("/orders");
            }
          }
        },
      },
    };
    addModal(modal);
  };

  const handleAddComment = useCallback(() => {
    if (id && id !== "" && comment && comment !== "") {
      appDispatch(addCommentAsync({ orderId: id, comment: comment }));
      setComment("");
    }
  }, [appDispatch, comment, id]);

  // handle busy toast
  const loadingToastId = useRef<any>(null);
  useEffect(() => {
    if (busy === true && !loadingToastId.current) {
      loadingToastId.current = toast(t("common|loading"), {
        isLoading: true,
        type: toast.TYPE.INFO,
        autoClose: false,
      });
    }
    if (busy === false && loadingToastId.current) {
      toast.dismiss(loadingToastId.current);
      loadingToastId.current = null;
    }
  }, [busy, t]);

  // handle error toast
  useEffect(() => {
    if (!isNullOrUndefined(error)) {
      toast(error, {
        type: toast.TYPE.ERROR,
        autoClose: false,
      });
      appDispatch(updateState({ error: undefined }));
    }
  }, [appDispatch, error, t]);

  return (
    <div style={{ height: "calc(100% - 64px)", overflow: "hidden" }}>
      <Grid
        container
        sx={{
          flexDirection: "row",
          // padding: "15px",
          // flex: 1,
          // height: "calc(100% - 64px)",
          height: "100%",
          overflow: "auto",
        }}
        // spacing={3}
      >
        <Grid item xs={12} lg={9} sx={{ padding: "10px" }}>
          <Grid
            container
            sx={{
              flexDirection: "column",
              height: "100%",
              flexWrap: "nowrap",
            }}
          >
            <Grid item>
              <Stepper alternativeLabel activeStep={step}>
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      sx={{ textAlign: "center" }}
                      optional={
                        <Typography variant="caption">
                          {step.stepOptionalText}
                        </Typography>
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              {steps[step].component}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          sx={{
            padding: "10px",
            // paddingLeft: {
            // xs: 0,
            // lg: "20px",
            // },
            // marginTop: {
            //   xs: "20px",
            //   lg: 0,
            // },
          }}
        >
          <Paper elevation={2}>
            <Grid
              container
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}
            >
              <Grid item>
                <Grid container sx={{ flexDirection: "column" }}>
                  <Grid item sx={{ textAlign: "center", padding: "5px" }}>
                    <Typography variant="h6">{sidebarTitle}</Typography>
                  </Grid>
                  <Divider />
                  <Grid item>
                    <List>
                      <ListItem dense key={t("Creation date")}>
                        <ListItemIcon>
                          <Info />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("Creation date")}
                          secondary={creationDate}
                        />
                      </ListItem>
                      <ListItem dense key={t("Created by")}>
                        <ListItemIcon>
                          <Info />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("Created by")}
                          secondary={creatorName}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  {canAdminUpdateStatus && (
                    <Grid item sx={{ padding: "10px" }}>
                      <FormControl fullWidth>
                        <InputLabel>{t("Order status")}</InputLabel>
                        <Select
                          labelId="orderStatus"
                          id="orderStatus"
                          name="orderStatus"
                          label={t("Order status")}
                          defaultValue={""}
                        >
                          {[
                            OrderStatusEnum.Refused,
                            OrderStatusEnum.Canceled,
                            OrderStatusEnum.Done,
                          ]
                            .filter((o) => o !== status)
                            .map((statusItem) => (
                              <MenuItem
                                key={statusItem}
                                data-status={statusItem}
                                value={statusItem}
                                onClick={handleChangeStatus}
                              >
                                {OrderStatusLabels[statusItem]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {canDelete && (
                    <Grid item sx={{ textAlign: "center", padding: "10px" }}>
                      <Button
                        startIcon={<Delete />}
                        color="error"
                        variant="outlined"
                        onClick={handleDeleteClick}
                      >
                        {t("button.delete")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item sx={{ padding: "10px" }}>
                    <Typography sx={{ fontStyle: "italic" }}>
                      {t("History")}
                    </Typography>
                    {canAddComment && (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("Comment")}
                        name="comment"
                        type="text"
                        inputProps={{
                          autoComplete: "new-comment",
                        }}
                        variant="outlined"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={handleAddComment}>
                              <Send />
                            </IconButton>
                          ),
                        }}
                        sx={{ marginTop: "10px" }}
                      />
                    )}
                    <Grid
                      container
                      sx={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        height: "250px",
                        width: "100%",
                        overflowY: "scroll",
                        marginTop: "10px",
                        flexWrap: "nowrap",
                      }}
                    >
                      {statuslineContent}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: "20px" }} />
              <Grid item sx={{ textAlign: "right", padding: "10px" }}>
                <Typography variant="h5">
                  {t("Total HT")}: {total}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusLine: {
      width: "95%",
      borderBottom: "1px solid lightgrey",
    },
    multilines: {
      whiteSpace: "break-spaces",
    },
  })
);
export default ProcessManager;
