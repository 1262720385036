import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import { IDeliverer } from "./deliverer";
import { IOrder } from "./order";
import * as ApiSettings from "./apiSettings";
import { IStatistics } from "routes/app/charts/Orders";

export interface ITransportRequest {
  id: string;
  reference: string;
  status: TransportRequestStatusEnum;
  statusLines: ITransportRequestStatusLine[];
  loadingDate: string;
  deliveryDate: string;
  creationTime: string;
  order: IOrder;
  orderId: string;
  deliverer: IDeliverer;
  delivererId: string;
  storeLabel: string;
  storeCity: string;
}

export interface ITransportRequestStatusLine {
  id: string;
  creationTime: string;
  status: TransportRequestStatusEnum;
  creatorUser: IUser;
  comment?: string;
}

export enum TransportRequestStatusEnum {
  Draft = 1, // En cours
  Pending, // En cours de livraison
  Done, // Terminée
  Canceled, // Annulée
}

export const TransportRequestStatusLabels = {
  [TransportRequestStatusEnum.Draft]: "En attente de prise en charge",
  [TransportRequestStatusEnum.Pending]: "En cours de livraison",
  [TransportRequestStatusEnum.Done]: "Terminée",
  [TransportRequestStatusEnum.Canceled]: "Annulée",
};

export const TransportRequestStatusColors = {
  [TransportRequestStatusEnum.Draft]: "#A9ADAA",
  [TransportRequestStatusEnum.Pending]: "#58B8DC",
  [TransportRequestStatusEnum.Done]: "#4EE683",
  [TransportRequestStatusEnum.Canceled]: "#DC5858",
};
export interface ICreateTransportRequest
  extends Omit<ITransportRequest, "id" | "order" | "deliverer"> {}

export const defaultEntity: ITransportRequest = {} as ITransportRequest;

export const defaultCreateEntity: ICreateTransportRequest = {
  loadingDate: "",
  deliveryDate: "",
  status: TransportRequestStatusEnum.Draft,
  statusLines: [],
  reference: "",
  creationTime: "",
  orderId: "",
  delivererId: "",
  storeLabel: "",
  storeCity: "",
};

export const service = new AbpAppService<ITransportRequest, ITransportRequest>(
  "TransportRequest"
);

export const validateAsync = (
  transportRequest: ITransportRequest
): Promise<AxiosResponse<IAbpResponseGet<ITransportRequest>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Validate`,
    transportRequest,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const refuseAsync = (
  transportRequest: ITransportRequest
): Promise<AxiosResponse<IAbpResponseGet<ITransportRequest>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Refuse`,
    transportRequest,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const addCommentAsync = (params: {
  transportRequestId: string;
  comment: string;
}): Promise<AxiosResponse<IAbpResponseGet<ITransportRequestStatusLine>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/AddComment`,
    params,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const getStatistics = async (params: {}): Promise<
  IAbpResponseGet<IStatistics<TransportRequestStatusEnum>>
> => {
  let result = {} as IAbpResponseGet<IStatistics<TransportRequestStatusEnum>>;
  try {
    result = await service.invoke("get", "GetStatistics", params);
  } catch (error) {
    console.log(ApiSettings.getResponseExceptionMessage(error));
  }
  return result;
};
