import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { IUserToUser, service } from "api/userToUser";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResponseExceptionMessage } from "api/apiSettings";
import EditComponent from "./EditChild";

const EditHierarchy = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [entity, setEntity] = useState<IUserToUser>();
  const { userToUserId } = useParams();

  const loadEntity = useCallback(
    async (id: string) => {
      const apiResult = await toast.promise(service.get({ id }), {
        pending: {
          render() {
            return t("loading");
          },
        },
        // success: t("common|loading.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      if (apiResult?.result?.id) {
        setEntity(apiResult.result);
      }
    },
    [t]
  );

  useEffect(() => {
    (async function () {
      if (userToUserId && !entity?.id) {
        // await loadUsers();
        await loadEntity(userToUserId);
      }
    })();
  }, [userToUserId, t, loadEntity, entity?.id]);

  return (
    <div className={classes.container}>
      <Grid
        container
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Grid
          item
          xs={12}
          md={2}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        ></Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            {t("Edit userToUser hierarchy")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{ textAlign: { xs: "center", md: "right" } }}
        ></Grid>
      </Grid>
      <Grid container flexDirection="column" spacing={2}>
        {entity && (
          <Grid item>
            <EditComponent entity={entity} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "20px",
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default EditHierarchy;
