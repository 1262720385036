import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAsync, reset } from "redux/reducers/retailOrder";
import { RootState, useAppDispatch } from "redux/store";
import ProcessManager from "./ProcessManager";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoadingScreen from "components/LoadingScreen";

export default function Edit() {
  const { t } = useTranslation(["common"]);
  const appDispatch = useAppDispatch();
  const retailOrderStore = useSelector((state: RootState) => state.retailOrder);
  const { retailOrderId } = useParams();
  let [isReady, setIsReady] = useState(false);

  useEffect(() => {
    appDispatch(reset());
    (async function () {
      if (retailOrderId) {
        // TODO: handle loading error, add toast ?
        appDispatch(getAsync(retailOrderId));
        setIsReady(true);
      }
    })();
  }, [appDispatch, retailOrderId, t]);

  return retailOrderStore.id && isReady ? (
    <ProcessManager />
  ) : (
    <LoadingScreen />
  );
}
