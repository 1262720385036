import {
  Box,
  Grid,
  MenuItem,
  Popper,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MuiReactTable, { IFilter } from "components/MuiReactTable";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { selectFormById, setForm } from "redux/reducers/forms";
import {
  //  checkDate,
  formatDate,
} from "helpers/tools";
import linq from "linq";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  IRetailOrder,
  RetailOrderStatusColors,
  RetailOrderStatusEnum,
  RetailOrderStatusLabels,
  service,
} from "api/retailOrder";
import { getResponseExceptionMessage } from "api/apiSettings";
import ConfirmModal, { IConfirmModal } from "components/ConfirmModal";
import { ModalContext } from "contexts/ModalContext";
import { hasOneOfRoles } from "helpers/user";

const FORM_ID = "retailOrders"; // used to save filter values

const List = () => {
  const classes = useStyles();
  const { addModal } = useContext(ModalContext);
  const { t } = useTranslation(["common", "components/muireacttable"]);
  let navigate = useNavigate();
  const [retailOrders, setRetailOrders] = useState<IRetailOrder[]>([]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);
  const authStore = useSelector((state: RootState) => state.auth);

  // #region Popper handlers start
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperContent, setPopperContent] = useState<any>(null);
  // const canDelete = useMemo(() => {
  //   return hasOneOfRoles(authStore.session.user, ["Admin", "AdminPLV"]);
  // }, [authStore.session.user]);

  const handleMouseEnter = useCallback(
    (event: any, retailOrder: IRetailOrder) => {
      setAnchorEl(event.currentTarget);
      let content = linq
        .from(retailOrder.statusLines)
        .orderByDescending((o) => o.creationTime)
        .toArray()
        .map((statusLine) => (
          <li key={statusLine.id}>
            <Typography>
              {formatDate(statusLine.creationTime, "DD/MM/YYYY HH:mm")} -{" "}
              {RetailOrderStatusLabels[statusLine.status]}
            </Typography>
          </li>
        ));
      setPopperContent(
        <div>
          {/* <Typography variant="h5">Historique</Typography> */}
          <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
            {content}
          </ul>
        </div>
      );
    },
    []
  );
  const handleMouseLeave = useCallback((event: any) => {
    setAnchorEl(null);
    setPopperContent(null);
  }, []);
  const open = Boolean(anchorEl);
  const id = open
    ? (anchorEl as any)?.getAttribute("aria-describedby")
    : undefined;
  // #endregion Popper handlers end

  const loadOrders = useCallback(async () => {
    const apiResult = await toast.promise(service.getAll(), {
      pending: {
        render() {
          return t("loading");
        },
      },
      // success: t("loading.success"),
      error: {
        render({ data }: any) {
          return getResponseExceptionMessage(data);
        },
        autoClose: false,
      },
    });
    if (apiResult?.success && apiResult.result) {
      setRetailOrders(apiResult.result.items);
    }
  }, [t]);

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  // const handleDeleteClick = (data: IRetailOrder) => {
  //   const modal: IConfirmModal = {
  //     component: ConfirmModal,
  //     props: {
  //       title: t("Delete order ?"),
  //       children: <></>,
  //       onConfirm: async () => {
  //         if (data.id) {
  //           const apiResult = await toast.promise(
  //             service.delete({
  //               id: data.id,
  //             }),
  //             {
  //               pending: {
  //                 render() {
  //                   return t("deleting");
  //                 },
  //               },
  //               // success: t("loading.success"),
  //               error: {
  //                 render({ data }: any) {
  //                   return getResponseExceptionMessage(data);
  //                 },
  //                 autoClose: false,
  //               },
  //             }
  //           );
  //           if (apiResult?.success) {
  //             loadOrders();
  //           }
  //         }
  //       },
  //     },
  //   };
  //   addModal(modal);
  // };
  const tableColumns = useMemo(
    () => [
      // { Header: t("column.week"), accessor: "week" },
      { Header: t("Reference"), accessor: "reference" },
      {
        Header: t("Status"),
        accessor: "status",
        style: {
          minWidth: "200px",
          maxWidth: "200px", // this is not working properly but at least the column is larger than default
        },
        //filter: "equals",
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            const rowRetailOrder: any = row.original;
            if ((filterValue as number) > 0) {
              return rowRetailOrder.status === filterValue;
            }
            return true;
          });
        },
        Cell: ({ row: { original } }: { row: { original: IRetailOrder } }) => {
          return (
            <Grid
              container
              sx={{
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
              spacing={1}
              aria-describedby={original.reference}
              onMouseEnter={(event) => handleMouseEnter(event, original)}
              onMouseLeave={handleMouseLeave}
            >
              <Grid item>
                <RemoveRedEye />
              </Grid>
              <Grid item>{RetailOrderStatusLabels[original.status]}</Grid>
            </Grid>
          );
        },
        getCellExportValue: (row: any, column: any) => {
          return (
            RetailOrderStatusLabels[
              row.original.status as RetailOrderStatusEnum
            ] || ""
          );
        },
        Filter: ({ column: { filterValue = 0, setFilter } }: any) => {
          let menuItems: any = Object.keys(RetailOrderStatusLabels).map(
            (value, index) => {
              if (!isNaN(Number(value))) {
                return (
                  <MenuItem key={value} value={Number(value)}>
                    {(RetailOrderStatusLabels as any)[value]}
                  </MenuItem>
                );
              }
              return null;
            }
          );
          return (
            <Select
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value || 0);
              }}
              value={filterValue}
              sx={{ height: "40px" }}
            >
              <MenuItem value={0}>Tous</MenuItem>
              {menuItems}
            </Select>
          );
        },
      },
      {
        Header: t("column.name"),
        accessor: "creatorUser.fullName",
      },

      { Header: t("Store reference"), accessor: "storeReference" },
      { Header: t("Store label"), accessor: "storeLabel" },
      { Header: t("Address"), accessor: "address" },
      { Header: t("City"), accessor: "city" },
      { Header: t("Postal code"), accessor: "postalCode" },
      // {
      //   Header: t("Loading date"),
      //   accessor: "loadingDate",
      //   Cell: ({ value }: { value: string }) => {
      //     return value ? formatDate(value, "DD/MM/YYYY HH:mm") : "";
      //   },
      //   getCellExportValue: (row: any, column: any) => {
      //     return row.original.loadingDate
      //       ? formatDate(row.original.loadingDate, "DD/MM/YYYY HH:mm")
      //       : "";
      //   },
      //   filter: (rows: any, id: any, filterValue: string) => {
      //     return rows.filter((row: any) => {
      //       const rowRetailOrder: IRetailOrder = row.original;
      //       return checkDate(filterValue, rowRetailOrder.loadingDate);
      //     });
      //   },
      // },
      // {
      //   Header: t("Delivery date"),
      //   accessor: "deliveryDate",
      //   Cell: ({ value }: { value: string }) => {
      //     return value ? formatDate(value, "DD/MM/YYYY HH:mm") : "";
      //   },
      //   getCellExportValue: (row: any, column: any) => {
      //     return row.original.loadingDate
      //       ? formatDate(row.original.loadingDate, "DD/MM/YYYY HH:mm")
      //       : "";
      //   },
      //   filter: (rows: any, id: any, filterValue: string) => {
      //     return rows.filter((row: any) => {
      //       const rowRetailOrder: IRetailOrder = row.original;
      //       return checkDate(filterValue, rowRetailOrder.loadingDate);
      //     });
      //   },
      // },
      // ...(canDelete
      //   ? [
      //       {
      //         Header: t("delete"),
      //         accessor: "delete",
      //         Cell: ({
      //           row: { original },
      //         }: {
      //           row: { original: IRetailOrder };
      //         }) => {
      //           return (
      //             original.status <= 2 && (
      //               <Delete
      //                 onClick={(e) => {
      //                   handleDeleteClick(original);
      //                   e.stopPropagation();
      //                 }}
      //               />
      //             )
      //           );
      //         },
      //       },
      //     ]
      //   : []),
    ],
    [handleMouseEnter, handleMouseLeave, t]
  );

  const handleRowClick = (row: any, data: IRetailOrder, event: any) => {
    navigate(`/retailOrders/${data.id}`);
  };

  const handleAddClick = () => {
    navigate(`/retailOrders/create`);
  };

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  const getRowStyle = (row: any, data: IRetailOrder) => {
    let styles = {};
    if (data.status) {
      styles = {
        bretailOrderLeft: `10px solid ${RetailOrderStatusColors[data.status]}`,
      };
    }
    return styles;
  };

  return (
    <div className={classes.container}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right"
        style={{
          background: "#F8F8F8",
          padding: "10px",
          border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        <Box>{popperContent}</Box>
      </Popper>
      <MuiReactTable
        // autoResetPage={autoResetPage}
        columns={tableColumns}
        data={retailOrders}
        onAddClick={handleAddClick}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        title={t("Retail order list")}
        csvExport={true}
        fileName={t("exportFileName")}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
        rowStyle={getRowStyle}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);

export default List;
