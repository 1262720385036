import AbpAppService from "api/AbpAppService";
import { ISimpleEntity } from "types/ISimpleEntity";

export interface IWidth extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
}

export interface ICreateWidth extends Omit<IWidth, "id"> {}

export const defaultEntity: ICreateWidth = {
  reference: "",
  label: "",
  isActive: true,
};

export const service = new AbpAppService<IWidth, IWidth>("Width");
