import AbpAppService from "api/AbpAppService";
import { IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import { getResponseExceptionMessage } from "./apiSettings";

export interface IUserToUser {
  id: string;
  fromUser?: IUser;
  fromUserId: string;
  toUser?: IUser;
  toUserId: string;
  fromRole: string;
  toRole: string;
  fromEmail: string;
  toEmail: string;
}

export interface ICreateUserToUser extends Omit<IUserToUser, "id"> {}

export const defaultEntity: IUserToUser = {
  id: "",
  fromUser: undefined,
  fromUserId: "",
  toUser: undefined,
  toUserId: "",
  fromRole: "",
  toRole: "",
  fromEmail: "",
  toEmail: "",
} as unknown as IUserToUser;

export const service = new AbpAppService<IUserToUser, IUserToUser>(
  "UserToUser"
);

export const getByFromUserIdAndRoleAsync = async (
  fromUserId: string,
  fromRole: string
): Promise<IAbpResponseGet<IUserToUser>> => {
  let result = {} as IAbpResponseGet<IUserToUser>;
  try {
    result = await service.invoke("get", "GetByFromUserIdAndRole", {
      fromUserId: fromUserId,
      fromRole: fromRole,
    });
  } catch (error) {
    console.log(getResponseExceptionMessage(error));
  }
  return result;
};
