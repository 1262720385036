import { Button, Grid, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IRole } from "types/IRole";
import { createAsync } from "api/role";
import { toast } from "react-toastify";
import { getResponseExceptionMessage } from "api/apiSettings";

const Create = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  let navigate = useNavigate();
  const role = {
    name: "",
    displayName: "",
    description: "",
  } as IRole;

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t("common|field_name.required", { field_name: t("name") })),
    displayName: yup
      .string()
      .required(
        t("common|field_name.required", { field_name: t("displayName") })
      ),
  });

  const formik = useFormik({
    initialValues: role,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      try {
        const apiResult = await toast.promise(createAsync(values), {
          pending: t("common|processing"),
          success: t("created"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.result?.id) {
          navigate(`/roles/${apiResult.result.id}`);
        }
      } catch (error) {}
    },
  });

  return (
    <div className={classes.container}>
      <form
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          ></Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("roles.create.title")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              type="submit"
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("name")}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("displayName")}
              name="displayName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.displayName}
              error={
                formik.touched.displayName && Boolean(formik.errors.displayName)
              }
              helperText={
                formik.touched.displayName && formik.errors.displayName
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t("description")}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              variant="filled"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      flex: 1,
    },
    submitButtonContainer: {
      marginTop: 20,
      textAlign: "right",
    },
  })
);
export default Create;
