import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AfterSaleRequestStatusEnum,
  AfterSaleRequestStatusLabels,
  service,
} from "api/afterSaleRequest";
import { useFormik } from "formik";
import { formatDate } from "helpers/tools";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { getReferencesAsync as getOrderReferencesAsync } from "redux/reducers/order";
import { getReferencesAsync as getRetailOrderReferencesAsync } from "redux/reducers/retailOrder";
import { useNavigate } from "react-router-dom";
import { getResponseExceptionMessage } from "api/apiSettings";
import useConfig from "hooks/useConfig";
import AfterSaleRequestFileUpload from "./AfterSaleRequestFileUpload";
import { ITempFile, uploadFiles } from "helpers/files";
import { FileWithPath } from "react-dropzone";

type OrderType = "order" | "retailOrder";

interface IFormProps {
  orderId?: string;
  retailOrderId?: string;
  type: OrderType;
  reason?: string;
  files?: ITempFile[];
}

const defaultFormProps: IFormProps = {
  type: "order",
  // orderId: "",
  // retailOrderId: "",
  // reason: undefined,
  // files: undefined
};

const Create = () => {
  const { t } = useTranslation(["common"]);
  let navigate = useNavigate();
  const { busy, error, orderReferences, retailOrderReferences } = useSelector(
    (state: RootState) => state.afterSaleRequest
  );
  const appDispatch = useAppDispatch();
  const loadingToastId = useRef<any>(null);
  const { savReasons } = useConfig();
  const [dropzoneKey, setDropzoneKey] = useState(Date.now());

  useEffect(() => {
    (async function () {
      await appDispatch(getOrderReferencesAsync());
      await appDispatch(getRetailOrderReferencesAsync());
    })();
  }, [appDispatch, t]);

  useEffect(() => {
    if (error) {
      loadingToastId.current = toast(t("Processing failed"), {
        type: toast.TYPE.ERROR,
        data: error,
        autoClose: false,
      });
    } else {
      if (busy) {
        loadingToastId.current = toast(t("Processing"), {
          type: toast.TYPE.INFO,
          autoClose: false,
        });
      } else {
        toast.dismiss(loadingToastId.current);
      }
    }
  }, [busy, error, t]);

  const validationSchema = yup.object({
    type: yup.string().required(
      t("common|field_name.required", {
        field_name: t("Type"),
      })
    ),
    orderId: yup.string().when("type", {
      is: (type: OrderType) => type === "order",
      then: yup.string().required(
        t("common|field_name.required", {
          field_name: t("Order"),
        })
      ),
    }),
    retailOrderId: yup.string().when("type", {
      is: (type: OrderType) => type === "retailOrder",
      then: yup.string().required(
        t("common|field_name.required", {
          field_name: t("Retail order"),
        })
      ),
    }),
    reason: yup.string().required(
      t("common|field_name.required", {
        field_name: t("Reason"),
      })
    ),
    customReason: yup.string().when("reason", {
      is: (reason: string) => reason === "Autre",
      then: yup.string().required(
        t("common|field_name.required", {
          field_name: t("Custom reason"),
        })
      ),
    }),
    files: yup
      .array()
      .required(
        t("common|field_name.required", {
          field_name: t("Files"),
        })
      )
      .min(1, t("field_name.min", { field_name: t("Files"), min: 1 })),
  });

  const formik = useFormik({
    initialValues: defaultFormProps,
    enableReinitialize: false,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        let uploadedFiles: ITempFile[] = await getUploadedFiles();
        const apiResult = await toast.promise(
          service.create({ ...values, tempFiles: uploadedFiles }),
          {
            pending: t("processing"),
            success: t("processing.success"),
            error: {
              render({ data }: any) {
                return getResponseExceptionMessage(data);
              },
              autoClose: false,
            },
          }
        );
        if (apiResult?.result?.id) {
          navigate(`/afterSaleRequests/${apiResult.result.id}`);
        }
      } catch (error) {}
    },
  });

  const {
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    isSubmitting,
    isValid,
    setFieldTouched,
  } = formik;

  const getUploadedFiles = useCallback(async () => {
    let uploadedFiles: ITempFile[] = [];
    if (values.files?.length) {
      uploadedFiles = await toast.promise(uploadFiles(values.files), {
        pending: t("processing.files"),
        success: t("processing.files.success"),
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      });
      setDropzoneKey(Date.now());
    }
    return uploadedFiles;
  }, [t, values.files]);

  const sidebarTitle = useMemo(() => t("New after sale request"), [t]);

  const creationDate = useMemo(
    () => formatDate(new Date().toISOString(), "DD/MM/YYYY HH:mm"),
    []
  );

  const handleFileChange = useCallback(
    (fileList: FileWithPath[]) => {
      const result: ITempFile[] = fileList.map((item) => {
        return {
          file: item,
          originalFileName: item.name,
          path: item.path ?? "",
        };
      });
      setFieldValue("files", result);
    },
    [setFieldValue]
  );

  return (
    <Grid
      container
      sx={{
        flexDirection: "row",
        padding: "15px",
        flex: 1,
      }}
      spacing={3}
    >
      <Grid item xs={12} lg={9}>
        <Grid
          container
          sx={{
            flexDirection: "column",
            height: "100%",
            flexWrap: "nowrap",
          }}
        >
          <Grid item sx={{ flex: 1 }}>
            <Grid
              container
              sx={{
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Grid container sx={{ flexDirection: "column" }}>
                  <Grid
                    item
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Paper elevation={2} sx={{ padding: "20px" }}>
                      <Typography variant="h2" sx={{ fontSize: "36px" }}>
                        {t("After sale request")}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item sx={{ marginTop: "30px", textAlign: "center" }}>
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        color={
                          values.type === "order" ? "primary" : "secondary"
                        }
                        onClick={async () => {
                          await setFieldValue("type", "order");
                        }}
                      >
                        {t("Order")}
                      </Button>
                      <Button
                        color={
                          values.type === "retailOrder"
                            ? "primary"
                            : "secondary"
                        }
                        onClick={async () => {
                          await setFieldValue("type", "retailOrder");
                        }}
                      >
                        {t("Retail order")}
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  {values.type === "order" && (
                    <Grid item sx={{ marginTop: "30px", textAlign: "center" }}>
                      <Autocomplete
                        disablePortal
                        id="order"
                        options={orderReferences || []}
                        onChange={async (e, value) => {
                          await setFieldValue("orderId", value?.id ?? "");
                          await setFieldTouched("orderId", true);
                        }}
                        onReset={async (e) => {
                          await setFieldValue("orderId", null);
                          await setFieldTouched("orderId", true);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) =>
                          `${option.reference} - ${option.label}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={t("Select an order")}
                            variant="filled"
                            error={touched.orderId && Boolean(errors.orderId)}
                            helperText={touched.orderId && errors.orderId}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {values.type === "retailOrder" && (
                    <Grid item sx={{ marginTop: "30px", textAlign: "center" }}>
                      <Autocomplete
                        disablePortal
                        id="retailOrder"
                        options={retailOrderReferences || []}
                        onChange={async (e, value) => {
                          await setFieldValue("retailOrderId", value?.id ?? "");
                          await setFieldTouched("retailOrderId", true);
                        }}
                        onReset={async (e) => {
                          await setFieldValue("retailOrderId", null);
                          await setFieldTouched("retailOrderId", true);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) =>
                          `${option.reference} - ${option.label}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={t("Select a retail order")}
                            variant="filled"
                            error={
                              touched.retailOrderId &&
                              Boolean(errors.retailOrderId)
                            }
                            helperText={
                              touched.retailOrderId && errors.retailOrderId
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item sx={{ marginTop: "30px" }}>
                    <FormControl fullWidth required>
                      <InputLabel id="reason-label">
                        {t("Select a reason")}
                      </InputLabel>
                      <Select
                        labelId="reason-label"
                        id="reason-label"
                        label={t("Select a reason")}
                        name="reason"
                        onChange={handleChange}
                        value={values.reason || ""}
                        required
                        error={touched.reason && Boolean(errors.reason)}
                      >
                        {savReasons?.map((reason, index) => (
                          <MenuItem key={reason} value={reason}>
                            {reason}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.reason && errors.reason}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ marginTop: "30px" }}>
                    {values.reason === "Autre" && (
                      <TextField
                        required
                        fullWidth
                        label={t("Custom reason")}
                        name="customReason"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        // value={formik.values.confirmPassword}
                        // error={
                        //   formik.touched.confirmPassword &&
                        //   Boolean(formik.errors.confirmPassword)
                        // }
                        // helperText={
                        //   formik.touched.confirmPassword && formik.errors.confirmPassword
                        // }
                        variant="filled"
                      />
                    )}
                  </Grid>
                  <Grid item sx={{ marginTop: "30px" }}>
                    <AfterSaleRequestFileUpload
                      dropzoneKey={dropzoneKey}
                      isEditable={true}
                      onChange={handleFileChange}
                    />
                    {errors.files && errors.files !== "" && (
                      <Typography sx={{ color: "error.main" }}>
                        {errors.files} *
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Grid item sx={{ textAlign: "right" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting || !isValid}
                      onClick={() => handleSubmit()}
                      fullWidth
                    >
                      {t("Validate")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper elevation={2}>
          <Grid
            container
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Grid item>
              <Grid container sx={{ flexDirection: "column" }}>
                <Grid item sx={{ textAlign: "center", padding: "5px" }}>
                  <Typography variant="h6">{sidebarTitle}</Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <List>
                    <ListItem dense key={t("Creation date")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Creation date")}
                        secondary={creationDate}
                      />
                    </ListItem>
                    <ListItem dense key={t("Status")}>
                      <ListItemIcon>
                        <Info />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Status")}
                        secondary={
                          AfterSaleRequestStatusLabels[
                            AfterSaleRequestStatusEnum.Draft
                          ]
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Create;
