import { getResponseExceptionMessage } from "api/apiSettings";
import { IProduct } from "api/product";
import { ITempFile, uploadFiles } from "helpers/files";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function useHelper() {
  const { t } = useTranslation(["common"]);

  const getUploadedFiles = useCallback(
    async (tempFiles: ITempFile[]) => {
      let uploadedFiles: ITempFile[] = [];
      if (tempFiles?.length) {
        uploadedFiles = await toast.promise(uploadFiles(tempFiles), {
          pending: t("processing.files"),
          success: t("processing.files.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
      }
      return uploadedFiles;
    },
    [t]
  );

  const getProductTotalWithRetailPrices = useCallback(
    (productWithQuantity: { product: IProduct; quantity: number }) => {
      let result = 0;
      if (
        productWithQuantity.product.retailPrice !== null &&
        productWithQuantity.product.retailPrice !== undefined &&
        productWithQuantity.product.retailPrice >= 0
      ) {
        result = productWithQuantity.product.retailPrice;
      } else {
        result = productWithQuantity.product.price;
      }

      return result * productWithQuantity.quantity;
    },
    []
  );

  const getProductTotal = useCallback(
    (
      productWithQuantity: { product: IProduct; quantity: number },
      useRetailPrice?: boolean
    ) => {
      if (useRetailPrice === true) {
        return getProductTotalWithRetailPrices(productWithQuantity);
      } else {
        return productWithQuantity.product.price * productWithQuantity.quantity;
      }
    },
    [getProductTotalWithRetailPrices]
  );
  const getProductTotalWeight = useCallback(
    (
      productWithQuantity: { product: IProduct; quantity: number }
    ) => {
        return productWithQuantity.product.weight * productWithQuantity.quantity;
    },
    []
  );

  const getTotal = useCallback(
    (
      productList: { product: IProduct; quantity: number }[],
      useRetailPrice?: boolean
    ) => {
      let sum = 0;
      productList.forEach((item) => {
        sum += getProductTotal(item, useRetailPrice);
      });
      return sum;
    },
    [getProductTotal]
  );

  return { getUploadedFiles, getProductTotal,getProductTotalWeight, getTotal };
}
