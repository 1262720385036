import AbpAppService from "api/AbpAppService";
import { ISimpleEntity } from "types/ISimpleEntity";
import { IUser } from "types/IUser";

export interface IFactory extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
  weight: number;
  users?: IFactoryUser[];
}

export interface IFactoryUser {
  user: IUser;
  userId: string;
  factoryId: string;
}

export interface ICreateFactory extends Omit<IFactory, "id"> {}

export const defaultEntity: ICreateFactory = {
  reference: "",
  label: "",
  isActive: false,
  weight: -1,
};

export const service = new AbpAppService<IFactory, IFactory>("Factory");
