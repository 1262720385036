import {
  Button,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  getByFromUserIdAndRoleAsync,
  ICreateUserToUser,
  service,
} from "api/userToUser";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { IUser } from "types/IUser";
import UserSelector from "components/UserSelector";
import { getResponseExceptionMessage } from "api/apiSettings";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [entity] = useState<ICreateUserToUser>({} as ICreateUserToUser);
  const [showToFields, setShowToFields] = useState<boolean>(false);
  let navigate = useNavigate();

  const validationSchema = yup.object({
    fromUserId: yup
      .string()
      .required(t("field_name.required", { field_name: t("From userId") })),
    fromRole: yup
      .string()
      .required(t("field_name.required", { field_name: t("From role") })),
    toUserId: yup
      .string()
      .required(t("field_name.required", { field_name: t("To userId") })),
    toRole: yup
      .string()
      .required(t("field_name.required", { field_name: t("To role") })),
  });

  const formik = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const apiResult = await toast.promise(service.create(values), {
        pending: t("processing"),
        success: t("processing.success"),
        error: t("processing.error"),
      });
      console.log(apiResult);
      if (apiResult.success) {
        navigate(`/userToUsers/hierarchy/${apiResult.result.id}`);
      }
    },
  });

  const { setValues, setFieldValue, handleSubmit, values, isSubmitting } =
    formik;

  const handleFromUserChange = useCallback(
    async (users: IUser[]) => {
      if (users?.[0] && users?.[0]?.id !== values.fromUser?.id) {
        setValues({
          ...values,
          fromUser: users[0],
          fromUserId: users[0].id.toString(),
          fromEmail: users[0].emailAddress,
          fromRole: "",
        });
      }
    },
    [setValues, values]
  );

  const handleToUserChange = useCallback(
    async (users: IUser[]) => {
      if (users?.[0] && users?.[0]?.id !== values.toUser?.id) {
        setValues({
          ...values,
          toUser: users[0],
          toUserId: users[0].id.toString(),
          toEmail: users[0].emailAddress,
          toRole: "",
        });
      }
    },
    [setValues, values]
  );

  const loadEntity = useCallback(async () => {
    const apiResult = await toast.promise(
      getByFromUserIdAndRoleAsync(values.fromUserId, values.fromRole),
      {
        error: {
          render({ data }: any) {
            return getResponseExceptionMessage(data);
          },
          autoClose: false,
        },
      }
    );
    if (apiResult?.result?.id) {
      navigate(`/userToUsers/hierarchy/${apiResult.result.id}`);
    } else {
      setShowToFields(true);
    }
  }, [navigate, values.fromRole, values.fromUserId]);

  useEffect(() => {
    if (values.fromRole && values.fromRole !== "" && values.fromUserId) {
      (async function () {
        await loadEntity();
      })();
    }
  }, [values.fromRole, values.fromUserId, loadEntity]);

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit}
        className={classes.formContainer}
        noValidate
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          ></Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              {t("Create userToUser")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            {/* {hasPermission(session.user, "userToUser.Update") === true && ( */}
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              variant="contained"
              type="submit"
            >
              {t("common|button.validate")}
            </Button>
            {/* )} */}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <Grid container flexDirection="column" spacing={2}>
              <Grid item>
                <Typography>From</Typography>
              </Grid>
              <Grid item>
                <UserSelector
                  key="fromUserSelector"
                  onChange={handleFromUserChange}
                  initialValues={values.fromUser ? [values.fromUser] : []}
                  maxCount={1}
                />
              </Grid>
              <Grid item>
                <Select
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("fromRole", e.target.value);
                  }}
                  value={values.fromRole || ""}
                  sx={{ height: "40px" }}
                >
                  {values.fromUser?.roleNames.map((roleName) => (
                    <MenuItem key={roleName} value={roleName}>
                      {roleName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {showToFields && (
            <Grid item xs={12} md={6}>
              <Grid container flexDirection="column" spacing={2}>
                <Grid item>
                  <Typography>To</Typography>
                </Grid>
                <Grid item>
                  <UserSelector
                    key="toUserSelector"
                    onChange={handleToUserChange}
                    initialValues={values.toUser ? [values.toUser] : []}
                    maxCount={1}
                  />
                </Grid>
                <Grid item>
                  <Select
                    fullWidth
                    onChange={(e) => {
                      setFieldValue("toRole", e.target.value);
                    }}
                    value={values.toRole || ""}
                    sx={{ height: "40px" }}
                  >
                    {values.toUser?.roleNames.map((roleName) => (
                      <MenuItem key={roleName} value={roleName}>
                        {roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    formContainer: {
      flex: 1,
    },
  })
);
export default Create;
