import React, { useEffect, useMemo, useState } from "react";
import { IModal, IModalProps } from "contexts/ModalContext";
import AbpAppService from "api/AbpAppService";
import moment from "moment";
import linq from "linq";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  InputAdornment,
  IconButton,
  TextField,
  DialogActions,
  Button,
  Theme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Today } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { IProduct } from "api/product";

const stockMoveAppService = new AbpAppService("stockMove") as any;
const MIN_QTY = -10000;
const MAX_QTY = 10000;

interface IReplenishmentModalProps extends IModalProps {
  products: any;
  stockMoveId?: any;
}

export interface IReplenishmentModal extends IModal {
  props: IReplenishmentModalProps;
}

export default function ReplenishmentModal({
  products,
  stockMoveId,
  dialogProps,
}: IReplenishmentModalProps) {
  const [availabilityDate, setAvailabilityDate] = useState<any>(
    moment().endOf("day")
  );
  const [busy, setBusy] = useState<any>();
  const [productStockMoveLines, setProductStockMoveLines] = useState<any>({});

  const isFormInvalid = useMemo(
    () => false,
    // !Object.values(productStockMoveLines).find((o: any) => o.quantity > 0) &&
    // !Object.values(optionStockMoveLines).find((o: any) => o.quantity > 0) &&
    // !Object.values(palletStockMoveLines).find((o: any) => o.quantity > 0),
    [
      // productStockMoveLines,
      // optionStockMoveLines,
      // palletStockMoveLines
    ]
  );

  const cssClasses = useStyles();

  useEffect(() => {
    if (stockMoveId) {
      (async function () {
        try {
          setBusy(true);

          const getStockMoveLineOutput = await stockMoveAppService.getAll({
            stockMoveId,
            includeStockMoveLines: true,
          });

          setProductStockMoveLines(
            linq
              .from(getStockMoveLineOutput.result.items[0].stockMoveLines)
              .where((o: any) => o.productId)
              .toObject(
                (o: any) => o.productId,
                (o) => o
              )
          );

          setAvailabilityDate(
            moment(getStockMoveLineOutput.result.items[0].availabilityDate)
          );
        } catch (ex) {
          console.error(ex);
        }

        setBusy(false);
      })();
    }
  }, [stockMoveId]);

  async function save() {
    try {
      setBusy(true);

      const stockMoveLines = [
        // ...Object.values(productStockMoveLines).filter(
        //   (o: any) => o.quantity > 0
        // ),
        // ...Object.values(optionStockMoveLines).filter(
        //   (o: any) => o.quantity > 0
        // ),
        // ...Object.values(palletStockMoveLines).filter(
        //   (o: any) => o.quantity > 0
        // ),
        ...Object.values(productStockMoveLines),
      ];

      if (!stockMoveId) {
        await stockMoveAppService.create({
          availabilityDate: availabilityDate.toISOString(true),
          stockMoveLines,
        });
      } else {
        await stockMoveAppService.update({
          id: stockMoveId,
          availabilityDate: availabilityDate.toISOString(true),
          stockMoveLines,
        });
      }

      dialogProps?.onClose();
    } catch (ex) {
      console.error(ex);
    }

    setBusy(false);
  }

  async function deleteStockMove() {
    try {
      setBusy(true);

      await stockMoveAppService.delete({
        id: stockMoveId,
      });
      dialogProps?.onClose();
    } catch (ex) {
      console.error(ex);
    }

    setBusy(false);
  }

  return (
    <Dialog {...dialogProps} maxWidth="md" fullWidth>
      <DialogTitle>
        {!stockMoveId
          ? "Nouveau réapprovisionnement"
          : "Modifier le réapprovisionnement"}
      </DialogTitle>
      <DialogContent>
        {busy ? (
          <CircularProgress />
        ) : (
          <>
            <DatePicker
              label="Date du réapprovisionnement"
              renderInput={(params) => <TextField {...params} />}
              minDate={new Date()}
              value={availabilityDate.toDate()}
              onChange={(e) => setAvailabilityDate(moment(e).endOf("day"))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Today />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {products?.length > 0 && (
              <>
                <div className={cssClasses.sectionTitle}>Produits</div>

                <div className={cssClasses.row} style={{ fontWeight: "bold" }}>
                  <div className={cssClasses.column}>Ref. / Désignation</div>
                  <div className={cssClasses.qtyColumn}>Quantité</div>
                </div>

                {products?.map((product: IProduct) => (
                  <div key={product.id} className={cssClasses.row}>
                    <div className={cssClasses.column}>
                      <strong>{product.reference}</strong> - {product.label}
                    </div>
                    <TextField
                      type="number"
                      inputProps={{
                        min: MIN_QTY,
                        max: MAX_QTY,
                        style: { textAlign: "right" },
                      }}
                      value={productStockMoveLines[product.id]?.quantity ?? 0}
                      onChange={(e) =>
                        setProductStockMoveLines((prev: any) => ({
                          ...prev,
                          [product.id]: {
                            ...prev[product.id],
                            productId: product.id,
                            quantity: e.target.value,
                          },
                        }))
                      }
                      className={cssClasses.qtyColumn}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {stockMoveId && (
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={deleteStockMove}
            disabled={busy}
          >
            Supprimer
          </Button>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => dialogProps?.onClose()}
          disabled={busy}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
          disabled={busy || isFormInvalid}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      fontSize: "20px",
      margin: "30px 0 20px",
      background: "#F0F0F0",
      padding: "5px 10px",
      borderRadius: "6px",
      fontWeight: "bold",
    },
    row: {
      display: "flex",
      overflow: "hidden",
      borderBottom: "1px solid #F0F0F0",
      alignItems: "center",
      padding: "5px 0",
    },
    column: {
      flex: "1",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    qtyColumn: {
      width: "80px",
      overflow: "hidden",
      textAlign: "right",
    },
  })
);
