import AbpAppService from "api/AbpAppService";
import { ISimpleEntity } from "types/ISimpleEntity";

export interface IConfiguration extends ISimpleEntity {
  id: string;
  reference: string;
  label: string;
  isActive: boolean;
}

export interface ICreateConfiguration extends Omit<IConfiguration, "id"> {}

export const defaultEntity: ICreateConfiguration = {
  reference: "",
  label: "",
  isActive: true,
};

export const service = new AbpAppService<IConfiguration, IConfiguration>(
  "Configuration"
);
