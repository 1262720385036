import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IImportExcelFileResult, importStores } from "api/store";
import MuiReactTable from "components/MuiReactTable";
import { IModal, IModalProps } from "contexts/ModalContext";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "components/TabPanel";
import { toast } from "react-toastify";
import LoadingScreen from "components/LoadingScreen";

interface IModalImportProps extends IModalProps {
  result: IImportExcelFileResult;
  filePath: string;
}

export interface IModalImport extends IModal {
  props: IModalImportProps;
}

const ModalImport = (props: IModalImportProps) => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const [tabValue, setTabValue] = useState(0);
  let [busy, setBusy] = useState(false);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setTabValue(newValue);
  };

  const { filePath, result } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    props.dialogProps?.onClose?.();
  }, [props.dialogProps]);

  const storeColumns = useMemo(
    () => [
      { Header: t("Reference"), accessor: "reference" },
      { Header: t("Label"), accessor: "label" },
      { Header: t("Address"), accessor: "address" },
      { Header: t("City"), accessor: "city" },
      { Header: t("Postal code"), accessor: "postalCode" },
      { Header: t("Contact email"), accessor: "contactEmail" },
      { Header: t("Contact name"), accessor: "contactName" },
      {
        Header: t("Contact phone number"),
        accessor: "contactPhoneNumber",
      },
    ],
    [t]
  );

  const muiReactTableLabels = useMemo(() => {
    return {
      exportButton: t("components/muireacttable|exportButton"),
      resultCount: t("components/muireacttable|resultCount"),
      rowCount: t("components/muireacttable|rowCount"),
      reinitiliazeFilters: t("components/muireacttable|reinitiliazeFilters"),
    };
  }, [t]);

  const handleValidate = useCallback(async () => {
    try {
      setBusy(true);
      await importStores({ filePath: filePath, isPreview: false });
      toast(t("processing.success"), { type: "success" });
      handleClose();
    } catch (error) {
      toast(t("processing.error"), { type: "error" });
    } finally {
      setBusy(false);
    }
  }, [filePath, handleClose, t]);

  return (
    <div className={classes.container}>
      <Dialog
        {...props.dialogProps}
        fullScreen={true}
        onClose={handleClose}
        transitionDuration={500}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "20px",
            }}
          >
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab
                label={`${t("Added stores")} (${result.addedStores.length})`}
              />
              <Tab
                label={`${t("Deleted stores")} (${
                  result.deletedStores.length
                })`}
              />
              <Tab
                label={`${t("Updated stores")} (${
                  result.updatedStores.length
                })`}
              />
              <Tab
                label={`${t("Invalid stores")} (${
                  result.invalidStores.length
                })`}
              />
              <Tab
                label={`${t("Identical stores")} (${
                  result.identicalStores.length
                })`}
              />
              {/* <Tab
                label={`${t("Added companies")} (${
                  result.addedCompanies.length
                })`}
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <MuiReactTable
              title={t("Added stores")}
              columns={storeColumns}
              data={result.addedStores}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MuiReactTable
              title={t("Deleted stores")}
              columns={storeColumns}
              data={result.deletedStores}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <MuiReactTable
              title={t("Updated stores")}
              columns={storeColumns}
              data={result.updatedStores}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <MuiReactTable
              title={t("Invalid stores")}
              columns={storeColumns}
              data={result.invalidStores}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <MuiReactTable
              title={t("Identical stores")}
              columns={storeColumns}
              data={result.identicalStores}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <MuiReactTable
              title={t("Added companies")}
              columns={[
                { Header: t("Reference"), accessor: "reference" },
                { Header: t("Label"), accessor: "label" },
              ]}
              data={result.addedCompanies}
              labels={muiReactTableLabels}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValidate} color="primary" variant="contained">
            {t("Validate")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {busy && <LoadingScreen />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);

export default ModalImport;
