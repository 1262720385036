import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IAbpGetList, IAbpResponse, IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import * as ApiSettings from "./apiSettings";

export const service = new AbpAppService<IUser, IUser>("User");

export const getAsync = async (id: number): Promise<IAbpResponseGet<IUser>> => {
  return await service.get({ id: id });
};

export const getAllAsync = async (
  params = {}
): Promise<IAbpResponse<IAbpGetList<IUser>>> => {
  return await service.getAll(params);
};

export const createAsync = async (
  params: IUser
): Promise<IAbpResponseGet<IUser>> => {
  return await service.create(params);
};

export const updateAsync = async (
  params: IUser
): Promise<IAbpResponseGet<IUser>> => {
  return await service.update(params);
};

export const deleteEntityAsync = async (
  id: string
): Promise<IAbpResponse<any>> => {
  return await service.delete({ id: id });
};

export interface IImportExcelFileResult {
  errors: string[];
  addedUsers: IUser[];
  deletedUsers: IUser[];
  updatedUsers: IUser[];
}

export const importUsers = (params: {
  filePath: string;
  isPreview?: boolean;
  isDebug?: boolean;
  maxRowsToHandle?: number;
}): Promise<AxiosResponse<IAbpResponse<IImportExcelFileResult>>> => {
  return axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Import`,
    params,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );
};
