import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
// When using TypeScript 4.x and above
import type {} from "@mui/x-date-pickers/themeAugmentation";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#25327B", // ducros: E61F26 or E64E54
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#e5e5e5",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
