import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MuiReactTable, { IFilter } from "components/MuiReactTable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IRole } from "types/IRole";
import { getAllAsync } from "api/role";
import { getResponseExceptionMessage } from "api/apiSettings";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setForm, selectFormById } from "redux/reducers/forms";
import { useAppDispatch, RootState } from "redux/store";

const FORM_ID = "roles"; // used to save filter values

const List = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "components/muireacttable"]);
  let navigate = useNavigate();
  const [roles, setRoles] = useState<IRole[]>([]);
  const appDispatch = useAppDispatch();
  const { forms } = useSelector((state: RootState) => state.forms);

  useEffect(() => {
    (async function () {
      try {
        const apiResult = await toast.promise(getAllAsync(), {
          pending: {
            render() {
              return t("Loading");
            },
          },
          // success: t("loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        });
        if (apiResult?.success && apiResult.result) {
          setRoles(apiResult.result.items);
        }
      } catch (error) {}
    })();
  }, [t]);

  const tableColumns = useMemo(
    () => [
      { Header: t("roles.id"), accessor: "id" },
      { Header: t("roles.name"), accessor: "name" },
      { Header: t("roles.displayName"), accessor: "displayName" },
      { Header: t("roles.description"), accessor: "description" },
    ],
    [t]
  );

  const handleRowClick = (row: any, data: IRole, event: any) => {
    navigate(`/roles/${data.id}`);
  };

  const handleAddClick = () => {
    navigate(`/roles/create`);
  };

  const handleFiltersChange = useCallback(
    (filters: IFilter[]) => {
      appDispatch(setForm({ id: FORM_ID, filters: filters }));
    },
    [appDispatch]
  );

  const initialized = useRef(false);
  const myFilters: any = useMemo(() => {
    if (initialized.current === false) {
      initialized.current = true;
      const form = selectFormById(forms, FORM_ID);
      return form?.filters || [];
    }
  }, [forms]);

  return (
    <div className={classes.container}>
      <MuiReactTable
        // autoResetPage={autoResetPage}
        columns={tableColumns}
        data={roles}
        onAddClick={handleAddClick}
        onRowClick={handleRowClick}
        onFiltersChange={handleFiltersChange}
        title={t("roles.title")}
        // csvExport={true}
        // fileName={t("exportFileName")}
        labels={{
          exportButton: t("components/muireacttable|exportButton"),
          resultCount: t("components/muireacttable|resultCount"),
          rowCount: t("components/muireacttable|rowCount"),
          reinitiliazeFilters: t(
            "components/muireacttable|reinitiliazeFilters"
          ),
        }}
        defaultState={{
          filters: myFilters,
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: "20px" },
  })
);

export default List;
