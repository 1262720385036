import {
  Button,
  Grid,
  Paper,
  // Theme,
  Typography,
  // useTheme,
} from "@mui/material";
// import { createStyles, makeStyles } from "@mui/styles";
import {
  useCallback,
  // useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import {
  IOrderLine,
  StepEnum,
  updateAsync,
  updateState,
  updateStep,
} from "redux/reducers/order";
// import { ModalContext } from "contexts/ModalContext";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IProduct, service } from "api/product";
import { toast } from "react-toastify";
import ProductsSelector from "routes/retailOrders/ProductsSelector";
import { IMerchProduct } from "api/merch";

const AdditionalProducts = () => {
  const { t } = useTranslation(["common"]);
  // const classes = useStyles();
  // const theme = useTheme();
  const appDispatch = useAppDispatch();
  // const { addModal } = useContext(ModalContext);
  const orderStore = useSelector((state: RootState) => state.order);
  const { orderLines } = useSelector((state: RootState) => state.order);
  let [productList, setProductList] = useState<IProduct[]>([]);

  useEffect(() => {
    (async function () {
      const apiResult = await toast.promise(
        service.getAll({ isActive: true, isOrderOption: true }),
        {
          pending: {
            render() {
              return t("common|Loading");
            },
          },
          // success: t("common|loading.success"),
          error: {
            render({ data }: any) {
              return getResponseExceptionMessage(data);
            },
            autoClose: false,
          },
        }
      );
      if (apiResult?.success && apiResult.result) {
        setProductList(apiResult.result.items);
      }
    })();
  }, [t]);

  const handleValidate = useCallback(async () => {
    await appDispatch(updateAsync(orderStore));
  }, [appDispatch, orderStore]);

  const isFormInvalid = useMemo(() => {
    return orderStore.busy;
  }, [orderStore.busy]);

  const handleAdditionalProducts = useCallback(
    (items: IMerchProduct[]) => {
      const newOrderLines = items.map((item) => {
        return {
          productId: item.product.id,
          quantity: item.quantity,
          unitPrice: item.product.price,
          total: item.product.price * item.quantity,
        } as IOrderLine;
      });
      appDispatch(updateState({ orderLines: newOrderLines }));
    },
    [appDispatch]
  );

  const selectedProductList = useMemo(() => {
    var result = orderLines
      ?.filter((orderLine) => {
        return !orderLine.merchId;
      })
      .map((orderLine) => {
        return {
          product: orderLine.product,
          quantity: orderLine.quantity,
        } as IMerchProduct;
      });
    return result || [];
  }, [orderLines]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Grid item>
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid
            item
            sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}
          >
            <Paper elevation={2} sx={{ padding: "20px" }}>
              <Typography variant="h2" sx={{ fontSize: "36px" }}>
                {t("Additional products")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item>
            <ProductsSelector
              productList={productList}
              onChange={handleAdditionalProducts}
              selectedProductList={selectedProductList}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => appDispatch(updateStep(StepEnum.Merch))}
            >
              {t("Back to merch selection")}
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={isFormInvalid}
              onClick={handleValidate}
            >
              {t("common|button.validate")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     container: {},
//   })
// );
export default AdditionalProducts;
