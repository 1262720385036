import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import MuiReactTable, {
  BooleanColumnFilter,
  BooleanColumnFilterCell,
  BooleanColumnFilterMethod,
  NumberColumnFilterCell,
} from "components/MuiReactTable";
import { IModal, IModalProps } from "contexts/ModalContext";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "components/TabPanel";
import { toast } from "react-toastify";
import LoadingScreen from "components/LoadingScreen";
import { IImportEntities, importProducts, IProduct } from "api/product";

interface IModalImportProps extends IModalProps {
  result: IImportEntities<IProduct>;
  filePath: string;
}

export interface IModalImport extends IModal {
  props: IModalImportProps;
}

const ModalImport = (props: IModalImportProps) => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const [tabValue, setTabValue] = useState(0);
  let [busy, setBusy] = useState(false);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setTabValue(newValue);
  };

  const { result, filePath } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    props.dialogProps?.onClose?.();
  }, [props.dialogProps]);

  const columns = useMemo(
    () => [
      { Header: t("Reference"), accessor: "reference" },
      { Header: t("Label"), accessor: "label" },
      {
        Header: t("Price"),
        accessor: "price",
        Cell: NumberColumnFilterCell,
      },
      {
        Header: t("Retail price"),
        accessor: "retailPrice",
        Cell: NumberColumnFilterCell,
      },
      {
        Header: t("column.isOrderOption"),
        accessor: "isOrderOption",
        Filter: BooleanColumnFilter,
        filter: BooleanColumnFilterMethod,
        Cell: BooleanColumnFilterCell,
      },
      {
        Header: t("column.isPallet"),
        accessor: "isPallet",
        Filter: BooleanColumnFilter,
        filter: BooleanColumnFilterMethod,
        Cell: BooleanColumnFilterCell,
      },
      {
        Header: t("column.usedForTransportPrice"),
        accessor: "usedForTransportPrice",
        Filter: BooleanColumnFilter,
        filter: BooleanColumnFilterMethod,
        Cell: BooleanColumnFilterCell,
      },
      {
        Header: t("column.isActive"),
        accessor: "isActive",
        Filter: BooleanColumnFilter,
        filter: BooleanColumnFilterMethod,
        Cell: BooleanColumnFilterCell,
      },
      {
        Header: t("Weight"),
        accessor: "weight",
        Cell: NumberColumnFilterCell,
      },
    ],
    [t]
  );

  const errorColumns = useMemo(
    () => [
      {
        Header: t("Error"),
        accessor: "value",
        Cell: ({ row: { original } }: { row: { original: string } }) => {
          return original;
        },
      },
    ],
    [t]
  );

  const muiReactTableLabels = useMemo(() => {
    return {
      exportButton: t("components/muireacttable|exportButton"),
      resultCount: t("components/muireacttable|resultCount"),
      rowCount: t("components/muireacttable|rowCount"),
      reinitiliazeFilters: t("components/muireacttable|reinitiliazeFilters"),
    };
  }, [t]);

  const handleValidate = useCallback(async () => {
    try {
      setBusy(true);
      await importProducts({ filePath, isPreview: false });
      toast(t("processing.success"), { type: "success" });
      handleClose();
    } catch (error: any) {
      toast(t("processing.error"), { type: "error" });
    } finally {
      setBusy(false);
    }
  }, [filePath, handleClose, t]);

  return (
    <div className={classes.container}>
      <Dialog
        {...props.dialogProps}
        fullScreen={true}
        onClose={handleClose}
        transitionDuration={500}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "20px",
            }}
          >
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label={`${t("Added")} (${result.addedEntities.length})`} />
              <Tab
                label={`${t("Updated")} (${result.updatedEntities.length})`}
              />
              <Tab label={`${t("Errors")} (${result.errors.length})`} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <MuiReactTable
              title={t("Added")}
              columns={columns}
              data={result.addedEntities}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MuiReactTable
              title={t("Updated")}
              columns={columns}
              data={result.updatedEntities}
              labels={muiReactTableLabels}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <MuiReactTable
              title={t("Errors")}
              columns={errorColumns}
              data={result.errors}
              labels={muiReactTableLabels}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValidate} color="primary" variant="contained">
            {t("Validate")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {busy && <LoadingScreen />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  })
);

export default ModalImport;
